import React, { useState } from "react";
import squadImg from "../../images/squad.svg";
import paystackImg from "../../images/paystack.png";
import flutterwaveImg from "../../images/flutter.jpg";
import { Fragment } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BiCopy } from "react-icons/bi";
import { AiFillBank } from "react-icons/ai";
import Virtual1 from "./Virtual11";
import Virtual2 from "./Virtual22";
import { useEffect } from "react";
import { getToken } from "../../util/util";
import { getDashboard } from "../../util/http";
import LoadingBar from "react-top-loading-bar";



export default function FundwalletComponent({
  setService,
  setSquad,
  setPaystack,
  squad,
  paystack,
  flutterwave,
  setFlutterwave,
}) {
  const [copied, setCopied] = useState(false);
  const [progress, setProgress] = useState(0);
  const token = getToken("token");
  const [dashboard, setDashboard] = useState("");

  useEffect(() => {
    async function fetchData() {
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const dashboardData = await getDashboard(token);
      setProgress(80);
      setProgress(100);
      setDashboard(dashboardData.data);
      // setWallet(response);
    }
    fetchData();
  }, []);

  const accDetails = dashboard?.account;
  return (
    <Fragment>
      <LoadingBar
        color="#23A455"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="w-full lg:w-[72%] lg:h-[17rem] h-full drop-shadow-xl rounded-lg bg-white flex flex-col">
        {false ?<label
          htmlFor="check"
          className="w-full bg-white rounded-t-lg cursor-pointer  p-4 flex gap-4 items-center"
        >
          <input
            onChange={(e) => {
              setSquad(e.target.checked);
              setPaystack(false);
              setFlutterwave(false);
              setService("SQUAD");
            }}
            checked={squad}
            id="check"
            type="radio"
          />
          <span className="w-[29px]">
            <img className="img" alt="wallet" src={squadImg} />
          </span>
          <div className="flex flex-col space-y-2">
            <h1 className="text-[#1E1D1B] font-bold text-start text-base lg:text-lg ">
              Fund wallet with Squad
            </h1>
            <p className="text-xs lg:text-sm font-normal  text-start">
              Make payment using your debit card, bank transfer and USSD
            </p>
          </div>
        </label> : null}

        {/* <label
          htmlFor="check1"
          className="w-full bg-white cursor-pointer  p-4 flex gap-4 items-center"
        >
          <input
            onChange={(e) => {
              setPaystack(e.target.checked);
              setSquad(false);
              setFlutterwave(false);
              setService("PAYSTACK");
            }}
            checked={paystack}
            id="check1"
            type="radio"
          />
          <span className="w-[29px]">
            <img className="img" alt="wallet" src={paystackImg} />
          </span>
          <div className="flex flex-col space-y-2">
            <h1 className="text-[#1E1D1B] font-bold text-start text-base lg:text-lg">
              Fund wallet with Paystack
            </h1>
            <p className="text-xs lg:text-sm font-normal  text-start">
              Make payment using your debit card, bank transfer and USSD
            </p>
          </div>
        </label> */}
       {false ? <label
          htmlFor="check2"
          className="w-full bg-white cursor-pointer  p-4 flex gap-4 items-center"
        >
          <input
            onChange={(e) => {
              setFlutterwave(e.target.checked);
              setPaystack(false);
              setSquad(false);
              setService("FLUTTERWAVE");
            }}
            checked={flutterwave}
            id="check2"
            type="radio"
          />
          <span className="w-[29px]">
            <img className="img" alt="wallet" src={flutterwaveImg} />
          </span>
          <div className="flex flex-col space-y-2">
            <h1 className="text-[#1E1D1B] font-bold text-start text-base lg:text-lg ">
              Fund wallet with Flutterwave
            </h1>
            <p className="text-xs lg:text-sm font-normal text-start">
              Make payment using your debit card, bank transfer and USSD
            </p>
          </div>
        </label> : null}
        <div className="flex justify-center py-10">
      {accDetails ? <Virtual1 detail={accDetails}   /> :  <Virtual2   />}
        </div>
        <hr />
        <label
          htmlFor="check4"
          className="w-full bg-white justify-center py-8 p-4 flex gap-4 items-center"
        >
          <AiFillBank fontSize="24px" />
          <div className="flex relative flex-col space-y-2">
            <h1 className="text-[#1E1D1B] font-bold text-center text-base lg:text-lg ">
              Fund wallet with bank transfer
            </h1>
            <p className="text-xs lg:text-sm font-normal text-center">
              Make transfer to the account details below and contact admin for
              confirmation
            </p>
            <hr />
            <div className="w-[100%] flex items-center justify-between">
              <p className="text-xs w-[25%] lg:text-sm font-normal text-start">
                Bank
              </p>
              <p className="text-xs lg:text-sm font-normal text-center">
                Fidelity Bank
              </p>
              <CopyToClipboard
                text="Fidelity Bank"
                onCopy={() => {
                  setCopied(true);
                  setTimeout(() => setCopied(false), 1000);
                }}
              >
                <BiCopy cursor="pointer" />
              </CopyToClipboard>
            </div>
            <div className="w-[100%] flex items-center justify-between">
              <p className="text-xs w-[25%] lg:text-sm font-normal text-start">
                Account Name
              </p>
              <p className="text-xs  lg:text-sm font-normal text-center">
                Sosocom Global Resources Ltd
              </p>
              <CopyToClipboard
                text="Sosocom Global Resources Ltd"
                onCopy={() => {
                  setCopied(true);
                  setTimeout(() => setCopied(false), 1000);
                }}
              >
                <BiCopy cursor="pointer" />
              </CopyToClipboard>
            </div>
            <div className="w-[100%] flex items-center justify-between">
              <p className="text-xs w-[25%] lg:text-sm font-normal text-start">
                Account Number
              </p>
              <p className="text-xs lg:text-sm font-normal text-center">
                5610024371
              </p>
              <CopyToClipboard
                text="5610024371"
                onCopy={() => {
                  setCopied(true);
                  setTimeout(() => setCopied(false), 1000);
                }}
              >
                <BiCopy cursor="pointer" />
              </CopyToClipboard>
            </div>
            <hr />
            <p className="text-xs lg:text-sm font-normal text-center">
              Note: This method takes at least 15 minutes to 1 hour to confirm
              payment
            </p>
            {copied && (
              <p className="absolute top-0 bg-white p-1 right-0">Copied!</p>
            )}
          </div>
        </label>
      </div>
    </Fragment>
  );
}
