import React, { Fragment, useEffect, useState } from "react";
import DashboardSide from "../dashboard/DashboardSide";
import ProfileCenter from "./ProfileCenter";
import MobileMenu from "../dashboard/MobileMenu";

import LoadingBar from "react-top-loading-bar";
import { useContext } from "react";
import { CartContext } from "../../util/context";
import { getToken } from "../../util/util";
import { getProfile } from "../../util/http";

export default function Profile({ show, setShow }) {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState("");
  // const [user, setUser] = useState("");
  const [progress, setProgress] = useState(0);

  const ctx = useContext(CartContext);
  const user = ctx?.user;

  useEffect(() => {
    async function fetchData() {
      const token = getToken("token");
      try {
        const response = await getProfile(token);
        setData(response?.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    <Fragment>
      <LoadingBar
        color="#23A455"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="h-full bg-[#f6f6f6] flex items-center justify-center">
        <DashboardSide />
        {modal && <MobileMenu setModal={setModal} />}
        <ProfileCenter
          show={show}
          setShow={setShow}
          user={data}
          setModal={setModal}
        />
      </div>
    </Fragment>
  );
}
