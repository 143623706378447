import React, { useState } from "react";

import calender from "../../images/Group 531.png";

import DashboardChart from "./Chart";

export default function Purchases({ chartData }) {
  const [filter, setFilter] = useState("daily");

  return (
    <div className="flex items-center bg-white rounded-2xl py-6 justify-center w-full lg:w-[56%] h-[400px] drop-shadow-xl">
      <div className="flex w-full flex-col space-y-4">
        <div className="w-full px-6 flex items-center justify-between">
          <h1 className="text-[1.125rem] font-bold capitalize text-[#3F434A]">
            purchases
          </h1>
          <div className="flex items-center border-2 p-1 rounded-2xl">
            <span className="w-[1rem] lg:w-[1.5rem] text-base text-black">
              <img className="img" src={calender} alt="calender" />
            </span>
            <select
              onChange={(e) => setFilter(e.target.value)}
              className="py-1 text-sm lg:text-sm flex-1 lg:px-4  rounded-xl bg-transparent outline-none"
            >
              <option value="daily">Daily</option>
              <option value="weekly">weekly</option>
              <option value="yearly">Yearly</option>
            </select>
          </div>
        </div>
        <div className="w-full flex items-center justify-center">
          <DashboardChart filter={filter} chartData={chartData} />
        </div>
      </div>
    </div>
  );
}
