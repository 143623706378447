import React, { Fragment, useEffect, useState } from "react";
import Header from "../dashboard/Header";
import Filter from "./Filter";
import ProductList from "./ProductList";
import { getProduct } from "../../util/http";
import Popup from "../UI/Popup";
import LoadingBar from "react-top-loading-bar";
import { getToken } from "../../util/util";
import Pagination from "./Pagination";

export default function ProductCenter({ setModal }) {
  const [showPopup, setShowPopup] = useState(false);
  const [productList, setProductList] = useState([]);
  const token = getToken("token");
  const [progress, setProgress] = useState(0);
  const [meta, setMeta] = useState();
  const [page, setPage] = useState(1);

  useEffect(() => {
    async function fetchData() {
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const response = await getProduct(token, page);
      setMeta(response?.meta);
      setProgress(80);
      setProgress(100);
      setProductList(
        response.data?.filter((el) => +el.id !== 4 && +el.id !== 3)
      );
      window.scrollTo(0, 0);
    }
    fetchData();
  }, [page, token]);

  return (
    <Fragment>
      <div>
        <LoadingBar
          color="#23A455"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>
      <div className="w-full bg-white pb-10 lg:flex-1 h-full lg:h-[160vh] relative">
        <Header setModal={setModal} title="product" />
        <Filter setProduct={setProductList} />
        <ProductList setShowPopup={setShowPopup} product={productList} />
        <Pagination meta={meta} setPage={setPage} page={page} />
      </div>
      {showPopup && <Popup setShow={setShowPopup} />}
    </Fragment>
  );
}
