import React from "react";

export default function IntegrationTitle() {
  return (
    <div className="w-full mt-12">
      <div className="w-full flex items-center justify-center">
        <h1 className="order-item-text w-[20%] text-start">Channel</h1>
        <h1 className="order-item-text w-[20%] text-start">Status</h1>
        <h1 className="order-item-text flex-1 text-start">Description</h1>
      </div>
    </div>
  );
}
