import React, { Fragment, useContext, useEffect } from "react";
import FundingSuccess from "../components/dashboard/FundingSuccess";
import FundWalletPage from "./FundWalletPage";
import { CartContext } from "../util/context";
import { useNavigate } from "react-router-dom";
import { inactiveState } from "../util/inactivity";

export default function FundingSuccessPage() {
  const transactionID = localStorage.getItem("transactionID");

  const ctx = useContext(CartContext);
  const navigate = useNavigate();

  window.onload = function () {
    inactiveState(ctx, navigate);
  };

  useEffect(() => {
    if (!ctx.isLoggedIn) {
      navigate("/login");
    }
  }, [ctx]);

  return (
    <Fragment>
      {transactionID ? <FundingSuccess /> : <FundWalletPage />}
    </Fragment>
  );
}
