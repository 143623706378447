import React from "react";
import userImg from "../../images/unknown.jpeg";

export default function User({ user }) {
  return (
    <div className="w-full lg:w-1/2 flex lg:flex-row flex-col gap-8 lg:border-r-2 items-center">
      <div className="w-full lg:w-[162px] border-8 border-white shadow-lg rounded-3xl">
        <img className="img h-[162px] rounded-3xl" src={userImg} alt="user" />
      </div>
      <div className="w-full lg:flex-1 flex flex-col space-y-2">
        <h1 className="text-xl capitalize font-bold text-center lg:text-start">
          {user.first_name?.slice(0, 15)} {user.last_name?.slice(0, 15)}
        </h1>
        <p className="text-base font-normal text-center lg:text-start">
          Merchant ID: {user.merchant_number}
        </p>
      </div>
    </div>
  );
}
