import React from "react";

export default function CartTitle() {
  return (
    <div className="w-full mb-4 lg:mb-8">
      <div className="w-full py-2 flex items-center justify-center gap-4">
        <h1 className="w-[40%] order-text uppercase text-start lg:text-center">
          PRODUCT
        </h1>
        <h1 className="w-[15%] order-text uppercase text-start lg:text-center">
          PRICE
        </h1>
        <h1 className="w-[15%] order-text uppercase text-start lg:text-center">
          QUANTITY
        </h1>
        <h1 className="hidden lg:block lg:w-[15%] order-text uppercase text-start lg:text-center">
          SUBTOTAL
        </h1>
        <h1 className="w-[15%] order-text uppercase text-start lg:text-center">
          ACTION
        </h1>
      </div>
    </div>
  );
}
