import React, { Fragment, useContext, useEffect } from "react";
import Login from "../components/auth/Login";
import Profile from "../components/profile/Profile";
import { CartContext } from "../util/context";
import { useNavigate } from "react-router-dom";
import { inactiveState } from "../util/inactivity";

export default function ProfilePage({ show, setShow }) {
  const ctx = useContext(CartContext);
  const navigate = useNavigate();

  window.onload = function () {
    inactiveState(ctx, navigate);
  };

  useEffect(() => {
    if (!ctx.isLoggedIn) {
      navigate("/login");
    }
  }, [ctx]);
  return <Profile show={show} setShow={setShow} />;
}
