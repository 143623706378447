import React, { useState, useEffect, useContext, Fragment } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import LoadingBar from "react-top-loading-bar";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Card from "../UI/Card";
import Notification from "../UI/Notification";
import WelcomeBack from "../UI/WelcomeBack";
import Background from "../UI/Background";
import { login } from "../../util/http";
import { CartContext } from "../../util/context";

import { AiFillEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";

import { setWithExpiry } from "../../util/util";

export default function Login() {
  const loginSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
  });

  ///////////////////////
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    status: "",
    text: "",
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const ctx = useContext(CartContext);

  useEffect(() => {
    if (errors.email || errors.password) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }
  }, [errors.email, errors.password]);

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(true);
  function toggleHandler() {
    if (type === "password") {
      setType("text");
      setIcon(false);
    } else {
      setType("password");
      setIcon(true);
    }
  }

  async function loginHandler(data) {
    try {
      setProgress(20);
      setProgress(40);
      setProgress(60);
      setLoading(true);
      const response = await login(data);
      navigate("/");
      setProgress(80);
      setProgress(100);
      setLoading(false);
      ctx.login(response.data.token);
      setWithExpiry("token", response.data.token, 86400000);

      setMessage({
        status: "success",
        text: "login successful",
      });
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 5000);
    } catch (error) {
      if (error?.response?.status === 409) {
        setMessage({
          status: "error",
          text: error?.response?.data.message || "something went wrong",
        });
        setShow(true);
        setTimeout(() => setShow(false), 5000);
        navigate("/almost-there");
      }
      setProgress(100);
      setLoading(false);
      setMessage({
        status: "error",
        text: error?.response?.data.message || "something went wrong",
      });
      setShow(true);
      setTimeout(() => setShow(false), 5000);
    }
  }

  return (
    <Fragment>
      <LoadingBar
        color="#17343D"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Background>
        <Card>
          <WelcomeBack />
          <div className="auth-container">
            <div className="flex w-full flex-col space-y-6">
              <h1 className="h1  text-[#57585A]">Sign In to your Account</h1>
              <form onSubmit={handleSubmit(loginHandler)} className="form">
                <div className="input-container">
                  <label className="label  text-[#57585A]">email address</label>
                  <input
                    {...register("email")}
                    type="email"
                    placeholder="Johndoe@gmail.com"
                    className="input"
                  />
                  <p className="error-text">
                    {errors.email ? errors.email.message : ""}
                  </p>
                </div>
                <div className="input-container">
                  <label className="label text-[#57585A]">password</label>
                  <div className="flex w-full bg-white items-center justify-center rounded-xl border-2 pr-2">
                    <input
                      {...register("password")}
                      type={type}
                      placeholder="Enter password"
                      className="py-2 flex-1 px-4 focus:bg-white  outline-none rounded-xl"
                    />
                    <span
                      onClick={toggleHandler}
                      className="text-base cursor-pointer"
                    >
                      {icon ? <AiFillEye /> : <AiOutlineEyeInvisible />}
                    </span>
                  </div>
                  <div className="w-full  flex items-end justify-between">
                    <p className="error-text">
                      {errors.password ? errors.password.message : ""}
                    </p>
                    <p className="p text-xs">
                      forgot password?{" "}
                      <Link
                        className="font-bold text-[#23A455]"
                        to="/forgot-password"
                      >
                        click here
                      </Link>
                    </p>
                  </div>
                </div>

                <div className="action">
                  <button disabled={!formIsValid} className="button">
                    {loading ? "Loading..." : "login"}
                  </button>
                </div>
                <div className="w-full flex items-start justify-start">
                  <div className="flex items-center gap-2">
                    <input id="remember" type="checkbox" />
                    <label htmlFor="remember" className="label">
                      remember password
                    </label>
                  </div>
                </div>
              </form>
              <p className="p text-sm">
                don't have an account?{" "}
                <Link className="font-bold text-[#23A455]" to="/register">
                  create account
                </Link>
              </p>
            </div>
          </div>
        </Card>
        {show && <Notification text={message.text} status={message.status} />}
      </Background>
    </Fragment>
  );
}
