import React, { Fragment } from "react";

import empty from "../../images/Business deal-cuate (1) 1.png";
import Empty from "../UI/Empty";
import { useNavigate } from "react-router-dom";

export default function PurchaseItem({ data }) {
  const navigate = useNavigate();

  return (
    <Fragment>
      {data?.length > 0 ? (
        <div className="w-full mt-10 h-full overflow-scroll flex flex-col space-y-2">
          {data.map((item, i) => {
            const createdDate = new Date(item.created_at);
            const day = createdDate.getDate();
            const month = createdDate.getMonth();
            const year = createdDate.getFullYear();
            const hour = createdDate.getHours();
            const minute = createdDate.getMinutes();
            const seconds = createdDate.getSeconds();
            return (
              <Fragment key={i}>
                <div className="flex w-full space-x-4 items-center justify-center">
                  <h1 className="capitalize text-[0.5rem] lg:text-[1rem] text-[#3F434A] font-medium w-[20%]">
                    {item.order_no}
                  </h1>
                  <h1 className="capitalize text-[0.5rem] lg:text-[0.94rem]    text-[#8A9099] w-[20%] font-medium">
                    {`${day}/${month}/${year} ${hour}:${minute}:${seconds}`}
                  </h1>
                  <h1 className="capitalize text-[#333] text-[0.5rem] lg:text-[1rem] w-[20%] font-bold text-center">
                    ₦{new Intl.NumberFormat().format(item.total_amount)}.00
                  </h1>
                  <div className="w-[20%]">
                    <div
                      className={`order-item-text w-[75%] mx-auto  ${
                        item.status === "COMPLETED"
                          ? "bg-[#00AC45]"
                          : "bg-[#FF3436]"
                      }  py-2 rounded-2xl`}
                    >
                      <h1 className="text-center capitalize text-white font-bold">
                        {item.status.toLowerCase()}
                      </h1>
                    </div>
                  </div>
                  <div
                    onClick={() => navigate(`/orders/${item.id}`)}
                    className="order-item-text w-[20%] cursor-pointer  py-2 rounded-md"
                  >
                    <h1 className="text-center text-[#00AC45] font-bold">
                      see details
                    </h1>
                  </div>
                </div>
                <hr />
              </Fragment>
            );
          })}
        </div>
      ) : (
        <div className="w-full h-[50vh] flex items-center justify-center">
          <Empty image={empty} text="No purchase to display" />
        </div>
      )}
    </Fragment>
  );
}
