import React, { Fragment, useState } from "react";
import CompanyDetails from "./CompanyDetails";
import Security from "./Security";

export default function ToggleProfile({ user }) {
  const [screen, setScreen] = useState(1);

  let displayScreen;

  if (screen === 1) {
    displayScreen = <CompanyDetails user={user} />;
  } else {
    displayScreen = <Security />;
  }

  return (
    <Fragment>
      <div className="w-full  lg:w-[50%] px-8 lg:px-16 flex items-center gap-8">
        <button
          onClick={() => setScreen(1)}
          className={screen === 1 ? "active-button" : "inactive-button"}
        >
          company profile
        </button>
        <button
          onClick={() => setScreen(2)}
          className={screen === 2 ? "active-button" : "inactive-button"}
        >
          security
        </button>
      </div>
      {displayScreen}
    </Fragment>
  );
}
