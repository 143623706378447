import React, { useEffect } from "react";
import Toggle from "../UI/Toggle";
import { useState } from "react";
import { updateIntegration } from "../../util/http";
import { getToken } from "../../util/util";

export default function IntegrationContent({
  setShow,
  setShow1,
  api,
  wordPress,
}) {
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");
  const [checkedApi, setCheckedApi] = useState(() => api?.enabled);
  const [checkedWordpress, setCheckedWordpress] = useState(
    () => wordPress?.enabled
  );

  useEffect(() => {
    async function updateData() {
      const token = getToken("token");

      const data = {
        integration_id: api?.id,
        enabled: checkedApi ? "1" : "0",
        password,
      };

      if (!password) {
        return;
      }

      try {
        const response = await updateIntegration(token, data);
      } catch (error) {}
    }
    updateData();
  }, [checkedApi, password]);

  useEffect(() => {
    async function updateData() {
      const token = getToken("token");

      const data = {
        integration_id: wordPress?.id,
        enabled: checkedWordpress ? "1" : "0",
        password: password1,
      };
      if (!password1) {
        return;
      }

      try {
        const response = await updateIntegration(token, data);
      } catch (error) {}
    }
    updateData();
  }, [checkedWordpress, password1]);

  return (
    <div className=" w-full">
      <div className="w-[90%] flex pr-10 flex-col space-y-4">
        <div className="w-full flex items-center justify-center">
          <h1 className="capitalize text-[0.5rem] lg:text-base font-bold  text-[#3F434A] w-[20%] text-start">
            API
          </h1>
          <Toggle
            isChecked={api?.enabled}
            setEnableApi={(e) => {
              setPassword(prompt("Enter Your Password"));
              setCheckedApi(e.target.checked);
            }}
            text="OFF"
            text1="ON"
          />
          <div className="flex-1 flex items-center justify-between">
            <p className="text-[0.5rem] lg:text-base w-[60%] font-normal text-start">
              Connect your custom websites or your mobile apps to start selling
              your gift cards.
              <br />
              <a
                style={{ color: "blue", fontWeight: "bold" }}
                href="https://sosogamescodes.com/developer/"
                target="blank"
              >
                (click here for API documentation)
              </a>
            </p>
            <button
              disabled={!api?.enabled}
              onClick={() => setShow(true)}
              className="btn1"
            >
              setup
            </button>
          </div>
        </div>

        <div className="w-full flex items-center justify-center">
          <h1 className="capitalize text-[0.5rem] lg:text-base font-bold  text-[#3F434A] w-[20%] text-start">
            WordPress
          </h1>
          <Toggle
            isChecked={wordPress?.enabled}
            setEnableApi={(e) => {
              setPassword1(prompt("Enter Your Password"));
              setCheckedWordpress(e.target.checked);
            }}
            text="OFF"
            text1="ON"
          />
          <div className="flex-1 flex items-center justify-between">
            <p className="text-[0.5rem] lg:text-base w-[60%] font-normal text-start">
              Connect your WooCommerce site and our pluggin to start selling
              your gift cards. <br />{" "}
              <b>(ensure guest purchase is disabled).</b>
            </p>
            <button
              disabled={!wordPress?.enabled}
              onClick={() => setShow1(true)}
              className="btn1"
            >
              setup
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
