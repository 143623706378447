import React from "react";

import logo from "../../images/Sosogames_LOGO-2.png";
import DashboardMenu from "./DashboardMenu";
import { useNavigate } from "react-router-dom";

export default function DashboardSide() {
  const navigate = useNavigate();
  return (
    <div className="hidden border-[#e8e9eb] border-r-[1px] lg:block h-[160vh] bg-white  w-[17%]">
      <div className="mt-10 flex items-center justify-center">
        <div onClick={() => navigate("/")} className="cursor-pointer w-[60%] ">
          <img className="img" alt="logo" src={logo} />
        </div>
      </div>
      <DashboardMenu />
    </div>
  );
}
