import React, { useContext, useState, useEffect } from "react";
import { CartContext } from "../../util/context";

export default function CheckoutStat({ paymentHandler, loading }) {
  const ctx = useContext(CartContext);
  const [cost, setCost] = useState(0);

  useEffect(() => {
    async function fetchCost() {
      const totalCost = await ctx.getTotalCost();
      setCost(totalCost);
    }
    fetchCost();
  }, [ctx]);

  const price = new Intl.NumberFormat().format(cost);

  const cartData = ctx.items
    .map((el) => el.recipients)
    .flatMap((el) => el)
    .filter((el) => el.channel === "SMS" || el.channel === "WHATSAPP");

  const total = +cartData.length * 10 + +cost;

  return (
    <div className="flex p-8 rounded-xl shadow-md w-full bg-white lg:w-[40%]  flex-col space-y-6">
      <div className="w-full flex items-center justify-between">
        <p className="text-base uppercase font-bold text-[#5D5F5F]">subtotal</p>
        <p className="text-base lg:text-xl font-bold text-black">
          ₦{cost ? price : 0}.00
        </p>
      </div>
      <div className="w-full flex items-center justify-between">
        <p className="text-base uppercase font-bold text-[#5D5F5F]">total</p>
        <p className="text-base lg:text-xl font-bold text-black">
          ₦{new Intl.NumberFormat().format(total)}.00
        </p>
      </div>
      <button
        onClick={paymentHandler}
        className="button flex items-center justify-center"
      >
        {loading ? "loading..." : "pay now"}
      </button>
    </div>
  );
}
