import image from "../images/apple.png";
import image1 from "../images/image 4.png";

export const product = [
  {
    name: "$10 PlayStation Wallet Gift Card ",
    price: 9000.0,
    image: image1,
    id: 1,
  },
  {
    name: "$10 amazon Wallet Gift Card ",
    price: 9000.0,
    image: image1,
    id: 2,
  },
  {
    name: "$10 steam wallet Wallet Gift Card ",
    price: 9000.0,
    image: image1,
    id: 3,
  },
  {
    name: "$10 google play Wallet Gift Card ",
    price: 9000.0,
    image: image1,
    id: 4,
  },
  {
    name: "$10 PlayStation Wallet Gift Card ",
    price: 9000.0,
    image: image1,
    id: 5,
  },
  {
    name: "$10 PlayStation Wallet Gift Card ",
    price: 9000.0,
    image: image1,
    id: 6,
  },
  {
    name: "$10 PlayStation Wallet Gift Card ",
    price: 9000.0,
    image: image1,
    id: 7,
  },
  {
    name: "$10 PlayStation Wallet Gift Card ",
    price: 9000.0,
    image: image1,
    id: 8,
  },
  {
    name: "$10 PlayStation Wallet Gift Card ",
    price: 9000.0,
    image: image1,
    id: 9,
  },
  {
    name: "$10 PlayStation Wallet Gift Card ",
    price: 9000.0,
    image: image1,
    id: 10,
  },
  {
    name: "$10 PlayStation Wallet Gift Card ",
    price: 9000.0,
    image: image1,
    id: 11,
  },
  {
    name: "$10 PlayStation Wallet Gift Card ",
    price: 9000.0,
    image: image1,
    id: 12,
  },
];

export const carts = [
  {
    name: "$10 PlayStation Wallet Gift Card ",
    image: image1,
    id: 1,
    price: "₦8,200.00",
    subtotal: "₦8,200.00",
  },
  {
    name: "$10 PlayStation Wallet Gift Card ",
    image: image1,
    id: 2,
    price: "₦8,200.00",
    subtotal: "₦8,200.00",
  },
  {
    name: "$10 PlayStation Wallet Gift Card ",
    image: image1,
    id: 3,
    price: "₦8,200.00",
    subtotal: "₦8,200.00",
  },
  {
    name: "$10 PlayStation Wallet Gift Card ",
    image: image1,
    id: 1,
    price: "₦8,200.00",
    subtotal: "₦8,200.00",
  },
  {
    name: "$10 PlayStation Wallet Gift Card ",
    image: image1,
    id: 2,
    price: "₦8,200.00",
    subtotal: "₦8,200.00",
  },
  {
    name: "$10 PlayStation Wallet Gift Card ",
    image: image,
    id: 3,
    price: "₦8,200.00",
    subtotal: "₦8,200.00",
  },
];

export const order = [
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "completed",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "cancelled",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "completed",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "completed",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "completed",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "cancelled",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "completed",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "completed",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "completed",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "cancelled",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "completed",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "completed",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "completed",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "cancelled",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "completed",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "completed",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "completed",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "cancelled",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "completed",
  },
  {
    id: "308965433",
    date: "October 31, 2022 8:22 PM",
    amount: "₦ 100,000.00",
    status: "completed",
  },
];

export const overview = [
  {
    image: image,
    code: "XJ7GJHYFPLDP2HM",
    product: "$10 PlayStation Wallet Gift Card",
    amount: 8000,
    quantity: 2,
    delivery: "oabisoye@yahoo.com",
  },
  {
    image: image,
    code: "XJ7GJHYFPLDP2HM",
    product: "$10 PlayStation Wallet Gift Card",
    amount: 8000,
    quantity: 2,
    delivery: "oabisoye@yahoo.com",
  },
  {
    image: image,
    code: "XJ7GJHYFPLDP2HM",
    product: "$10 PlayStation Wallet Gift Card",
    amount: 8000,
    quantity: 2,
    delivery: "oabisoye@yahoo.com",
  },
  {
    image: image,
    code: "XJ7GJHYFPLDP2HM",
    product: "$10 PlayStation Wallet Gift Card",
    amount: 8000,
    quantity: 2,
    delivery: "oabisoye@yahoo.com",
  },
  {
    image: image,
    code: "XJ7GJHYFPLDP2HM",
    product: "$10 PlayStation Wallet Gift Card",
    amount: 8000,
    quantity: 2,
    delivery: "oabisoye@yahoo.com",
  },
  {
    image: image,
    code: "XJ7GJHYFPLDP2HM",
    product: "$10 PlayStation Wallet Gift Card",
    amount: 8000,
    quantity: 2,
    delivery: "oabisoye@yahoo.com",
  },
  {
    image: image,
    code: "XJ7GJHYFPLDP2HM",
    product: "$10 PlayStation Wallet Gift Card",
    amount: 8000,
    quantity: 2,
    delivery: "oabisoye@yahoo.com",
  },
  {
    image: image,
    code: "XJ7GJHYFPLDP2HM",
    product: "$10 PlayStation Wallet Gift Card",
    amount: 8000,
    quantity: 2,
    delivery: "oabisoye@yahoo.com",
  },
];

export function getProduct(id) {
  return product.find((item) => item.id === id);
}
