import React, { useState } from "react";
import MobileMenu from "./MobileMenu";
import DashboardSide from "./DashboardSide";
import FundWalletCenter from "./FundWalletCenter";

export default function FundWallet() {
  const [modal, setModal] = useState(false);
  return (
    <div className="h-full bg-[#f6f6f6] flex items-center justify-center">
      <DashboardSide />
      {modal && <MobileMenu setModal={setModal} />}
      <FundWalletCenter />
    </div>
  );
}
