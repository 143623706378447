import React, { useContext, useEffect, useState } from "react";

import { CartContext } from "../../util/context";
import CartBtn from "../UI/CartBtn";
import { useNavigate } from "react-router-dom";

export default function ProductButtons({
  price,
  setShow,
  item,
  setType,
  product,
}) {
  const ctx = useContext(CartContext);
  const navigate = useNavigate();
  const quantity = ctx.getProductQuantity(item?.id);

  const [qty, setQty] = useState(0);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    setQty(quantity);
  }, [quantity]);

  const productPrice = new Intl.NumberFormat().format(price);

  useEffect(() => {
    if (qty > product.available_quantity) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [qty, product.available_quantity]);

  function addCart(type) {
    ctx.addOneToCart(item, [], +qty, false);
    setQty((prev) => prev + 1);
    setShow(true);
    setTimeout(() => setShow(false), 3000);
  }
  function removeCart() {
    if (qty === 0) {
      return;
    }
    ctx.removeOneFromCart(item?.id);
    setQty((prev) => prev - 1);
  }

  return (
    <div className="flex flex-col space-y-4 w-full">
      <div className="w-full flex items-center justify-between">
        <div
          className={`w-[130px] border-2 ${
            isError ? "border-red-500" : ""
          } rounded-2xl py-2 px-4 gap-4 flex items-center`}
        >
          <CartBtn
            isError={isError}
            qty={+qty}
            setQty={setQty}
            available={product.available_quantity}
            addFn={addCart}
            removeFn={removeCart}
            quantity={quantity}
          />
        </div>
        <p className="text-[1.25rem] font-bold ">
          ₦{product.price ? productPrice : 0}.00
        </p>
      </div>
      {false && (
        <p className="text-[0.875rem] font-semibold text-[#595F69] text-start capitalize">
          Available Quantity: {product.available_quantity}
        </p>
      )}
      <button
        disabled={
          product.available_quantity === 0 || qty > product.available_quantity
        }
        onClick={() => {
          if (quantity === 0 && quantity < product.available_quantity) {
            ctx.addOneToCart(item);
            setShow(true);
            setTimeout(() => setShow(false), 5000);
          } else {
            navigate("/product/cart");
          }
        }}
        className="button"
      >
        {quantity > 0 ? "proceed to cart" : "Add to Cart"}
      </button>
    </div>
  );
}
