import React, { Fragment, useState } from "react";
import DashboardCenter from "./DashboardCenter";
import DashboardSide from "./DashboardSide";
import MobileMenu from "./MobileMenu";

export default function Dashboard({ setShow, show }) {
  const [modal, setModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  return (
    <Fragment>
      <div className="h-full bg-[#f6f6f6] flex items-center justify-center">
        <DashboardSide />
        {modal && <MobileMenu setShowEdit={setShowEdit} setModal={setModal} />}
        <DashboardCenter
          showEdit={showEdit}
          setShowEdit={setShowEdit}
          setShow={setShow}
          setModal={setModal}
        />
      </div>
    </Fragment>
  );
}
