import React, { Fragment, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import wallet from "../../images/Shape.png";
import grid from "../../images/Grid.png";
import history from "../../images/text.png";
import user from "../../images/user.png";
import cart from "../../images/dash-cart.png";
import integration from "../../images/integrate.png";
import documentation from "../../images/project.png";

import { AiOutlineLogout } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { CartContext } from "../../util/context";

export default function DashboardMenu({ setShowEdit, setModal }) {
  const ctx = useContext(CartContext);
  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="mt-10 w-full flex flex-col">
        <NavLink
          className={(navData) => (navData.isActive ? "active" : "menu")}
          to="/"
        >
          <span className="w-[20px] h-[20px] text-base">
            <img className="img" src={grid} alt="integration" />
          </span>
          dashboard
        </NavLink>
        <NavLink
          className={(navData) => (navData.isActive ? "active" : "menu")}
          to="/wallet"
        >
          <span className="w-[20px] h-[20px] text-base">
            <img className="img" src={wallet} alt="integration" />
          </span>
          wallet
        </NavLink>
        <NavLink
          className={(navData) => (navData.isActive ? "active" : "menu")}
          to="/product"
        >
          <span className="w-[20px] h-[20px] text-base">
            <img className="img" src={cart} alt="integration" />
          </span>
          Shop
        </NavLink>
        <NavLink
          className={(navData) => (navData.isActive ? "active" : "menu")}
          to="/orders"
        >
          <span className="w-[20px] h-[20px] text-base">
            <img className="img" src={history} alt="integration" />
          </span>
          order history
        </NavLink>
        <NavLink
          className={(navData) => (navData.isActive ? "active" : "menu")}
          to="/integration"
        >
          <span className="w-[20px] h-[20px] text-base">
            <img className="img" src={integration} alt="integration" />
          </span>
          integration
        </NavLink>
        <NavLink
          className={(navData) => (navData.isActive ? "active" : "menu")}
          to="/profile"
        >
          {" "}
          <span className="w-[20px] h-[20px] text-base">
            <img className="img" src={user} alt="integration" />
          </span>
          profile
        </NavLink>
        {false && (
          <button
            onClick={() => {
              setModal(false);
              setShowEdit(true);
            }}
            className="lg:hidden menu flex items-center"
          >
            <span className="w-[20px] h-[20px] text-base flex items-center">
              <AiOutlineEdit />
            </span>
            edit profile
          </button>
        )}
        <button
          onClick={() => {
            ctx.logout();
            navigate("/login");
          }}
          className="lg:hidden menu flex items-center"
        >
          <span className="w-[20px] h-[20px] text-base flex items-center">
            <AiOutlineLogout />
          </span>
          logout
        </button>
        <a
          target="_blank"
          className="menu"
          href="https://sosogamescodes.com/developer/"
        >
          <span className="w-[20px] h-[20px] text-base">
            <img className="img" src={documentation} alt="integration" />
          </span>
          API Documentation
        </a>
      </div>
    </Fragment>
  );
}
