import React, { useState } from "react";

import { FaGreaterThan } from "react-icons/fa";
import { FaLessThan } from "react-icons/fa";

export default function WalletFilter({
  totaltransaction,
  transactionPerPage,
  setCurrentPage,
  currentPage,
}) {
  let pages = [];

  for (let i = 1; i <= Math.ceil(totaltransaction / transactionPerPage); i++) {
    pages.push(i);
  }

  const handleIncrement = () => {
    setCurrentPage(currentPage < (totaltransaction / transactionPerPage) ? currentPage + 1 : currentPage);
  };
  const handleDecrement = () => {
    setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);
  };

  return (
    <div className="w-full  flex items-center justify-between">
      <div className="flex items-center space-x-4">
        <div className="focus:border-green-500 transition-all duration-300 py-2 flex-1 px-2 border-2 rounded-xl outline-none">
          {false && (
            <select className="bg-transparent outline-none border-none px-2">
              {pages.map((page, i) => (
                <option key={i} className="pr-2">
                  {page}
                </option>
              ))}
            </select>
          )}
        </div>
        <h1 className="hidden lg:block capitalize text-base text-[#57585A]">
          showing {currentPage} - {transactionPerPage * currentPage} of{" "}
          {totaltransaction}
        </h1>
      </div>
      <div className="flex items-center space-x-4">
        <span className="text-xs cursor-pointer opacity-60 bg-[#eee] rounded-full p-3 text-[#8A9099]" onClick={handleDecrement}>
          <FaLessThan />
        </span>
        <div className="flex items-center gap-2">
          {pages.map((page, i) => (
            <button
              key={i}
              onClick={() => {
                setCurrentPage(page);
              }}
              className={page === currentPage ? "page-active" : "page-inactive"}
            >
              {page}
            </button>
          ))}
        </div>

        <span className="text-xs cursor-pointer opacity-60 bg-[#eee] rounded-full p-3 text-[#23A455]" onClick={handleIncrement}>
          <FaGreaterThan />
        </span>
      </div>
    </div>
  );
}
