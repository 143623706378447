import React, { Fragment, useState } from "react";
import { RxEyeClosed } from "react-icons/rx";

import PasswordValidation from "../UI/PasswordValidation";
import Notification from "../UI/Notification";
import { changePassword } from "../../util/http";
import { getToken } from "../../util/util";

export default function Security() {
  const [password, setPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const [formIsValid, setFormIsValid] = useState(false);

  const [type, setType] = useState("password");

  const [isNumber, setIsNumber] = useState(false);
  const [isUppercase, setIsUppercase] = useState(false);
  const [isSymbol, setIsSymbol] = useState(false);
  const [isEightChar, setIsEightChar] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const token = getToken("token");

  const [message, setMessage] = useState({
    status: "",
    text: "",
  });
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const regex = /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/;

  function passwordHandler(e) {
    setShowValidation(true);
    setPassword(e.target.value);
    // password length
    if (e.target.value.length >= 8) {
      setIsEightChar(true);
    } else {
      setIsEightChar(false);
    }

    if (e.target.value.match(/[0-9]/g)) {
      setIsNumber(true);
    } else {
      setIsNumber(false);
    }

    if (e.target.value.match(/[a-z]/g) && e.target.value.match(/[A-Z]/g)) {
      setIsUppercase(true);
    } else {
      setIsUppercase(false);
    }

    if (e.target.value.match(regex)) {
      setIsSymbol(true);
    } else {
      setIsSymbol(false);
    }
  }

  function toggleHandler() {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  }

  async function submitHandler(e) {
    e.preventDefault();
    const data = {
      old_password: oldPassword,
      new_password: password,
    };

    try {
      setLoading(true);
      await changePassword(token, data);
      setLoading(false);
      setMessage({
        status: "success",
        text: "password updated successfully",
      });
      setShow(true);
      window.location.reload();
      setTimeout(() => {
        setShow(false);
      }, 3000);
    } catch (error) {
      setLoading(false);
      setMessage({
        status: "error",
        text: error.response.data.message || "something went wrong",
      });
      setShow(true);
      setTimeout(() => setShow(false), 5000);
    }
    setOldPassword("");
    setPassword("");
  }
  return (
    <Fragment>
      <div className="w-full lg:w-[90%] py-4 mx-auto mt-10 px-10 lg:px-20">
        <div className="w-full flex flex-col space-y-6">
          <div className="w-full flex flex-col space-y-2">
            <h1 className="text-[#3F434A] font-bold text-lg">
              Security Settings
            </h1>
            <p className="text-[#3F434A] font-normal text-base">
              Change your password using the form below
            </p>
          </div>

          <form
            onSubmit={submitHandler}
            className="w-full lg:w-[40%] flex flex-col space-y-4"
          >
            <div className="input-container w-full">
              <label className="label text-[#57585A]">enter old password</label>
              <div className="flex  justify-centerflex items-center justify-center rounded-xl border-2 pr-2">
                <input
                  type={type}
                  onChange={(e) => setOldPassword(e.target.value)}
                  placeholder="Enter old Password"
                  className=" py-2 flex-1 px-4   outline-none rounded-xl"
                />

                <span
                  onClick={toggleHandler}
                  className="text-lg cursor-pointer text-black"
                >
                  <RxEyeClosed />
                </span>
              </div>
            </div>
            <div className="input-container w-full">
              <label className="label text-[#57585A]">enter new password</label>
              <div className="flex  justify-centerflex items-center justify-center rounded-xl border-2 pr-2">
                <input
                  type={type}
                  onChange={passwordHandler}
                  placeholder="Enter Password"
                  onKeyUp={() => {
                    if (isUppercase && isNumber && isSymbol && isEightChar) {
                      setFormIsValid(true);
                    } else {
                      setFormIsValid(false);
                    }
                  }}
                  className="py-2 flex-1 px-4    outline-none rounded-xl"
                />
                <span
                  onClick={toggleHandler}
                  className="text-lg cursor-pointer text-black "
                >
                  <RxEyeClosed />
                </span>
              </div>

              {showValidation && (
                <PasswordValidation
                  isEightChar={isEightChar}
                  isNumber={isNumber}
                  isSymbol={isSymbol}
                  isUppercase={isUppercase}
                />
              )}
            </div>
            <button disabled={!formIsValid} className="button">
              {loading ? "loading..." : "update password"}
            </button>
          </form>
        </div>
      </div>
      {show && <Notification text={message.text} status={message.status} />}
    </Fragment>
  );
}
