import React, { useEffect, useContext, useState, Fragment } from "react";

import wallet from "../../images/wallet 2.png";
import squadImg from "../../images/squad.svg";
import paystackImg from "../../images/paystack.png";
import flutterwaveImg from "../../images/flutter.jpg";
import { BiCopy } from "react-icons/bi";
import { AiFillBank } from "react-icons/ai";
import CheckoutStat from "./CheckoutStat";
import FundwalletComponent from "../dashboard/Fundwallet-component";
import { CartContext } from "../../util/context";
import Notification from "../UI/Notification";
import { createOrder, getDashboard } from "../../util/http";
import { useNavigate } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";

import { getToken } from "../../util/util";


export default function CheckoutContainer({ balance }) {
  const [walletPayment, setWalletPayment] = useState(true);
  const [cardPayment, setCardPayment] = useState(false);
  const [copied, setCopied] = useState(false);

  const token = getToken("token");
  const navigate = useNavigate();

  const ctx = useContext(CartContext);
  const [cost, setCost] = useState("");

  const [acc, setAcc] = useState("");

  useEffect(() => {
    async function fetchCost() {
      const totalCost = await ctx.getTotalCost();
      setCost(totalCost);
    }
    fetchCost();
  }, [ctx]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getDashboard(token);
        setAcc(response?.data?.account);
      } catch (error) {}
    }
    fetchData();
  }, [token]);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState({
    status: "",
    text: "",
  });

  const [service, setService] = useState("WALLET");
  const [squad, setSquad] = useState(false);
  const [paystack, setPaystack] = useState(false);
  const [flutterwave, setFlutterwave] = useState();

  const walletBalance = new Intl.NumberFormat().format(balance);

  const cartItem = ctx.items.map((el) => {
    return {
      product_id: el?.id,
      quantity: el?.quantity,
      recipients: el?.recipients?.map((item, i) => {
        return { channel: item?.channel, contact: item?.contact };
      }),
    };
  });

  async function paymentHandler() {
    if (+walletBalance <= 0) {
      setMessage({
        status: "error",
        text: "Insufficient wallet balance... Fund account to complete purchase",
      });
      setShow1(true);
      setTimeout(() => {
        setShow1(false);
      }, 3000);
      return;
    }

    if (cost <= 0) {
      alert("cannot pay 0 value");
      return;
    }

    const data = {
      payment_channel: service,
      callback_url: "https://dashboard.sosogamescodes.com/product/success",
      order_items: cartItem,
    };

    try {
      setLoading(true);
      const response = await createOrder(token, data);
      localStorage.removeItem("cart");
      localStorage.setItem("transactionID", response.data.reference);
      if (data.payment_channel.toLowerCase() === "wallet") {
        setMessage({
          status: "success",
          text: "wallet payment successful",
        });
        setShow1(true);
        setTimeout(() => {
          setShow1(false);
        }, 5000);
        setTimeout(() => {
          navigate("/product");
          window.location.reload();
          localStorage.removeItem("cart");
        }, 2000);
        return;
      }
      window.location.href = response.data.authorization_url;
      setLoading(false);

      setMessage({
        status: "success",
        text: "payment successfully initialized, you will be redirected to payment page now...",
      });
      setShow1(true);
      setTimeout(() => {
        setShow1(false);
      }, 5000);
    } catch (error) {
      // console.log(error);
      setLoading(false);
      setMessage({
        status: "error",
        text: error.response.data.message || "something went wrong",
      });
      setShow1(true);
      setTimeout(() => setShow1(false), 5000);
    }
  }

  console.log(acc ,  balance ) 

  return (
    <Fragment>
      <div className="w-full h-full bg-white">
        <div className="w-full p-4 flex flex-col lg:flex-row gap-6 pt-10 lg:items-center lg:justify-between">
          <div className="w-full flex flex-col gap-4">
            <div className="w-full bg-white rounded-lg lg:flex-1 shadow-md flex px-4 flex-col ">
              {true && (
                <label
                  htmlFor="checkout"
                  className="w-full bg-white border-b-2 cursor-pointer  p-4 flex gap-4 items-center"
                >
                  <input
                    onChange={(e) => {
                      setWalletPayment(e.target.checked);
                      setPaystack(false);
                      setFlutterwave(false);
                      setSquad(false);
                      setService("WALLET");
                      setShow(false);
                      setCardPayment(false);
                    }}
                    disabled={balance === 0}
                    checked={walletPayment}
                    id="checkout"
                    type="radio"
                  />

                  <span className="w-[29px]">
                    <img className="img" alt="wallet" src={wallet} />
                  </span>
                  <div className="flex flex-col space-y-2">
                    <h1 className="text-[#1E1D1B] font-bold text-start text-base lg:text-lg">
                      Pay with Wallet Balance
                    </h1>
                    <p className="text-xs lg:text-sm font-normal  text-start">
                      You have N {walletBalance}.00{" "}
                    </p>
                  </div>
                </label>
              )}
              {acc && balance !== 0 && (
                <label
                  htmlFor="check4"
                  className="w-full bg-white justify-center py-8 p-4 flex gap-4 items-center"
                >
                  <AiFillBank fontSize="24px" />
                  <div className="flex relative flex-col space-y-2">
                    <h1 className="text-[#1E1D1B] font-bold text-center text-base lg:text-lg ">
                      Pay With Virtual Account
                    </h1>

                    <hr />
                    <div className="w-[100%] flex items-center justify-between">
                      <p className="text-xs w-[25%] lg:text-sm font-normal text-start">
                        Bank
                      </p>
                      <p className="text-xs lg:text-sm font-normal text-center">
                        {acc?.bank.name}
                      </p>
                      <CopyToClipboard
                        text={acc?.bank.name}
                        onCopy={() => {
                          setCopied(true);
                          setTimeout(() => setCopied(false), 1000);
                        }}
                      >
                        <BiCopy cursor="pointer" />
                      </CopyToClipboard>
                    </div>
                    <div className="w-[100%] flex items-center justify-between">
                      <p className="text-xs w-[25%] lg:text-sm font-normal text-start">
                        Account Name
                      </p>
                      <p className="text-xs  lg:text-sm font-normal text-center">
                        {acc?.account_name}
                      </p>
                      <CopyToClipboard
                        text={acc?.account_name}
                        onCopy={() => {
                          setCopied(true);
                          setTimeout(() => setCopied(false), 1000);
                        }}
                      >
                        <BiCopy cursor="pointer" />
                      </CopyToClipboard>
                    </div>
                    <div className="w-[100%] flex items-center justify-between">
                      <p className="text-xs w-[25%] lg:text-sm font-normal text-start">
                        Account Number
                      </p>
                      <p className="text-xs lg:text-sm font-normal text-center">
                        {acc?.account_number}
                      </p>
                      <CopyToClipboard
                        text={acc?.account_number}
                        onCopy={() => {
                          setCopied(true);
                          setTimeout(() => setCopied(false), 1000);
                        }}
                      >
                        <BiCopy cursor="pointer" />
                      </CopyToClipboard>
                    </div>

                    {copied && (
                      <p className="absolute top-0 bg-white p-1 right-0">
                        Copied!
                      </p>
                    )}
                  </div>
                </label>
              )}
              {false && (
                <label
                  htmlFor="check"
                  className="w-full bg-white rounded-t-lg cursor-pointer  p-4 flex gap-4 items-center"
                >
                  <input
                    onChange={(e) => {
                      setSquad(e.target.checked);
                      setWalletPayment(false);
                      setPaystack(false);
                      setFlutterwave(false);
                      setService("SQUAD");
                    }}
                    checked={squad}
                    id="check"
                    type="radio"
                  />
                  <span className="w-[29px]">
                    <img className="img" alt="wallet" src={squadImg} />
                  </span>
                  <div className="flex flex-col space-y-2">
                    <h1 className="text-[#1E1D1B] font-bold text-start text-base lg:text-lg">
                      Pay with Squad
                    </h1>
                    <p className="text-xs lg:text-sm font-normal text-start">
                      Make payment using your debit card, bank transfer and USSD
                    </p>
                  </div>
                </label>
              )}
              {false && (
                <label
                  htmlFor="check1"
                  className="w-full bg-white cursor-pointer  p-4 flex gap-4 items-center"
                >
                  <input
                    onChange={(e) => {
                      setPaystack(e.target.checked);
                      setWalletPayment(false);
                      setSquad(false);
                      setFlutterwave(false);
                      setService("PAYSTACK");
                    }}
                    checked={paystack}
                    id="check1"
                    type="radio"
                  />
                  <span className="w-[29px]">
                    <img className="img" alt="wallet" src={paystackImg} />
                  </span>
                  <div className="flex flex-col space-y-2">
                    <h1 className="text-[#1E1D1B] font-bold text-start text-base lg:text-lg">
                      Pay with Paystack
                    </h1>
                    <p className="text-xs lg:text-sm font-normal text-start">
                      Make payment using your debit card, bank transfer and USSD
                    </p>
                  </div>
                </label>
              )}
              {false && (
                <label
                  htmlFor="check2"
                  className="w-full bg-white cursor-pointer  p-4 flex gap-4 items-center"
                >
                  <input
                    onChange={(e) => {
                      setFlutterwave(e.target.checked);
                      setWalletPayment(false);
                      setPaystack(false);
                      setSquad(false);
                      setService("FLUTTERWAVE");
                    }}
                    checked={flutterwave}
                    id="check2"
                    type="radio"
                  />
                  <span className="w-[29px]">
                    <img className="img" alt="wallet" src={flutterwaveImg} />
                  </span>
                  <div className="flex flex-col space-y-2">
                    <h1 className="text-[#1E1D1B] font-bold text-start text-base lg:text-lg">
                      Pay with Flutterwave
                    </h1>
                    <p className="text-xs lg:text-sm font-normal text-start">
                      Make payment using your debit card, bank transfer and USSD
                    </p>
                  </div>
                </label>
              )}
            </div>
          </div>
          <CheckoutStat paymentHandler={paymentHandler} loading={loading} />
        </div>

        {show && (
          <div className="w-full p-4">
            <FundwalletComponent
              setFlutterwave={setFlutterwave}
              squad={squad}
              paystack={paystack}
              setService={setService}
              setSquad={setSquad}
              setPaystack={setPaystack}
              flutterwave={flutterwave}
            />
          </div>
        )}
      </div>
      {show1 && <Notification text={message.text} status={message.status} />}
    </Fragment>
  );
}
