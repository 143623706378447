import React, { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../util/context";

export default function ProductItem({ item, i, setShowPopup }) {
  const navigate = useNavigate();
  const ctx = useContext(CartContext);
  const quantity = ctx.getProductQuantity(item.id);
  const price = new Intl.NumberFormat().format(item.price);

  return (
    <Fragment>
      <div
        key={i}
        className="lg:w-[22%] w-[49%] bg-white hover:opacity-80 transition-all  duration-300 cursor-pointer flex flex-col space-y-0 h-[367px] mt-4 pt-4 lg:pt-0 lg:p-4 "
      >
        <div className="flex items-center p-4 w-full justify-between">
          <p
            className={`py-1 px-2 text-center font-normal rounded-xl text-xs lg:text-[0.75rem] capitalize ${
              item.available_quantity === 0 ? "bg-[#9b9b9b]" : "bg-[#23A455]"
            }  bg-opacity-10 ${
              item.available_quantity === 0
                ? "text-[#57585a]"
                : "text-[#23A455]"
            }text-[#23A455]`}
          >
            {+item.available_quantity === 0 ? "unavailable" : "available"}
          </p>

          <input
            disabled={item.available_quantity === 0}
            className="cursor-pointer accent-[#23A455] outline-none"
            onChange={(e) => {
              if (e.target.checked && quantity <= item.available_quantity) {
                ctx.addOneToCart(item);
                setShowPopup(true);
              } else {
                ctx.removeOneFromCart(item.id);
              }
            }}
            type="radio"
          />
        </div>
        <div
          onClick={() => {
            if (+quantity === 0 && +quantity < +item.available_quantity) {
              ctx.addOneToCart(item);
              navigate(`/product/${item.id}`);
            } else {
              navigate(`/product/${item.id}`);
            }
          }}
          className="w-[8.82rem] mx-auto"
        >
          <img
            className="img h-[12.5rem]"
            alt="product"
            src={item.image_path}
          />
        </div>
        <hr />
        <div className="flex  flex-col p-4 space-y-4 w-full">
          <h1
            onClick={() =>
              item.available_quantity > 0 && navigate(`/product/${item.id}`)
            }
            className="text-center h-[2.5rem] w-full capitalize text-[0.5rem] lg:text-[0.875rem] font-normal"
          >
            {item.name}
          </h1>
          <div className="flex w-full px-2 items-center justify-between">
            <p className="text-xs lg:text-base font-normal text-[#8A9099]">
              ₦{price}.00
            </p>
            <button
              disabled={item.available_quantity === 0}
              onClick={() => {
                if (quantity === 0 && quantity < item.available_quantity) {
                  ctx.addOneToCart(item);
                  setShowPopup(true);
                  // navigate(`/product/cart`);
                } else {
                  navigate(`/product/cart`);
                  return;
                }
              }}
              className="bg-transparent text-[#23A455] font-bold text-xs lg:text-[0.875]"
            >
              Add to cart
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
