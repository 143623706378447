import React, { Fragment, useEffect, useState } from "react";
import IntegrationPopup from "./IntegrationPopup";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { MdContentCopy } from "react-icons/md";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { regenerateKey } from "../../util/http";
import { getToken } from "../../util/util";

export default function APIIntegrationModal({
  setShow,
  setShow1,
  api,
  setApi,
}) {
  const [icon, setIcon] = useState("eyes");
  const [icon1, setIcon1] = useState("eyes");
  const [copied, setCopied] = useState(false);
  const token = getToken("token");
  const [loading, setLoading] = useState(false);
  const [click, setClick] = useState(0);

  useEffect(() => {
    const localClick = localStorage.getItem("api_clicks") || 0;
    setClick(+localClick);
  }, []);

  useEffect(() => {
    localStorage.setItem("api_clicks", click);
  }, [click]);

  const [secret, setSecret] = useState(
    "*************************************************************"
  );
  const [publicKey, setPublic] = useState(
    "*************************************************************"
  );

  async function regenerateApiKey() {
    try {
      setLoading(true);
      const response = await regenerateKey(token, api.id);
      localStorage.removeItem("api_clicks");
      setClick(0);
      alert("API Keys regeneration successful");
      setLoading(false);
      setApi(response.data);
      setShow(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  }

  return (
    <IntegrationPopup>
      <div className="w-full flex px-3 lg:px-6 flex-col space-y-12 lg:space-y-6">
        <div className="w-full  flex items-center justify-between">
          <h1 className="text-[#1E1D1B] text-xl font-bold text-start">
            API Integrations
          </h1>
          <span
            onClick={() => setShow(false)}
            className="text-xl text-black font-bold cursor-pointer"
          >
            <AiOutlineClose />
          </span>
        </div>
        <div className="w-full flex items-end justify-end">
          <button onClick={regenerateApiKey} className="btn">
            {loading ? "regenerating" : "regenerate keys"}
          </button>
        </div>
        <form className="w-full flex flex-col space-y-4">
          <div className="w-full gap-8 flex items-center relative justify-between">
            <label className="label">Live Secret Key</label>
            <div className="input relative flex items-center justify-between">
              <input
                className="flex-1 bg-transparent outline-none"
                type="text"
                disabled
                value={secret}
              />

              {click <= 1 && (
                <Fragment>
                  {icon === "eyes" ? (
                    <span
                      onClick={() => {
                        setSecret(api.secret_key);
                        setClick((prev) => prev + 1);

                        setIcon("copy");
                      }}
                      className="text-base text-black cursor-pointer"
                    >
                      <AiOutlineEye />
                    </span>
                  ) : (
                    <CopyToClipboard
                      text={secret}
                      onCopy={() => {
                        setCopied(true);

                        setIcon("eyes");
                        setSecret(
                          '"*************************************************************'
                        );
                        setClick((prev) => prev + 1);

                        setTimeout(() => setCopied(false), 1000);
                      }}
                    >
                      <span className="text-base text-black cursor-pointer">
                        <MdContentCopy />
                      </span>
                    </CopyToClipboard>
                  )}
                </Fragment>
              )}
              {copied && (
                <p className="absolute top-0 bg-white p-1 right-0">Copied!</p>
              )}
            </div>
          </div>
          <div className="w-full gap-8 flex items-center justify-between">
            <label className="label">Live Public Key</label>
            <div className="input flex items-center justify-between">
              <input
                className="flex-1 bg-transparent outline-none"
                type="text"
                disabled
                value={publicKey}
              />
              {icon1 === "eyes" ? (
                <span
                  onClick={() => {
                    setIcon1("copy");
                    setPublic(api.public_key);
                  }}
                  className="text-base text-black cursor-pointer"
                >
                  <AiOutlineEye />
                </span>
              ) : (
                <CopyToClipboard
                  text={publicKey}
                  onCopy={() => {
                    setCopied(true);
                    setIcon1("eyes");
                    setTimeout(() => setCopied(false), 1000);
                  }}
                >
                  <span className="text-base text-black cursor-pointer">
                    <MdContentCopy />
                  </span>
                </CopyToClipboard>
              )}
            </div>
          </div>
          <div className="w-full flex items-end justify-end">
            <button
              onClick={() => setShow(false)}
              type="button"
              className="bg-[#23A455]  hover:opacity-40 transition-all duration-300  hover:shadow-xl capitalize rounded-lg text-white text-base font-semibold cursor-pointer py-3 px-6"
            >
              done
            </button>
          </div>
        </form>
      </div>
    </IntegrationPopup>
  );
}
