import React from "react";
import VirtualAccount from "./VirtualAccount";

export default function DashboardBalance({ dashboard }) {
  return (
    <div className="w-full mt-24 p-4 flex items-center gap-8 justify-between">
      <VirtualAccount dashboard={dashboard} />
    </div>
  );
}
