import React, { Fragment, useState } from "react";
import Card from "../UI/Card";
import VerifySignup from "../UI/VerifySignup";
import WelcomeBack from "../UI/WelcomeBack";
import Background from "../UI/Background";
import Notification from "../UI/Notification";
import ResendCodePopup from "../UI/ResendCodePopup";
import LoadingBar from "react-top-loading-bar";
export default function AlmostThere() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState({
    status: "",
    text: "",
  });
  return (
    <Fragment>
      <Background>
        <LoadingBar
          color="#23A455"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
        <Card>
          <WelcomeBack />
          <div className="auth-container">
            <div className="flex flex-col space-y-10">
              <div className="flex flex-col space-y-4">
                <h1 className="h1 text-[#57585A]">almost there</h1>
                <p className="p">
                  We have successful created your new account. But before you
                  start you will have to activate it. We have sent a
                  verification code to the email you provided during
                  registration. Please input the six digit code to verify your
                  account.
                </p>
              </div>
              <VerifySignup
                loading={loading}
                setLoading={setLoading}
                message={message}
                setMessage={setMessage}
                setShow={setShow}
                setShow1={setShow1}
                progress={progress}
                setProgress={setProgress}
              />
            </div>
          </div>
        </Card>
        {show && <Notification text={message.text} status={message.status} />}

        {show1 && (
          <ResendCodePopup
            loading={loading}
            setLoading={setLoading}
            message={message}
            setMessage={setMessage}
            setShow={setShow}
            setShow1={setShow1}
          />
        )}
      </Background>
    </Fragment>
  );
}
