import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

export default function OrderItem({ item }) {
  const navigate = useNavigate();
  const date = new Date(item.created_at);

  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hour = date.getHours();
  const minute = date.getMinutes();
  return (
    <Fragment>
      <div className="w-full flex items-center justify-center gap-4">
        <h1 className="order-item-text font-medium w-[20%] text-start">
          {item.order_no}
        </h1>
        <h1 className="capitalize text-[0.5rem] lg:text-sm w-[20%] text-start">
          {`${day.toString().padStart(2, 0)}/${month
            .toString()
            .padStart(2, 0)}/${year} ${hour.toString().padStart(2, 0)}:${minute
            .toString()
            .padStart(2, 0)}`}
        </h1>
        <h1 className="order-item-text w-[20%] font-bold text-center">
          ₦{new Intl.NumberFormat().format(item.total_amount)}.00
        </h1>
        <div className={`order-item-text w-[20%]   `}>
          <div
            className={`w-[50px] lg:w-[100px] mx-auto py-2 rounded-md ${
              item.status === "COMPLETED" ? "bg-[#00AC45]" : "bg-[#FF3436]"
            }`}
          >
            <h1 className="text-center capitalize lg:text-base text-[0.5rem] text-white font-bold">
              {item.status.toLowerCase()}
            </h1>
          </div>
        </div>
        <button
          onClick={() => navigate(`/orders/${item.id}`)}
          className="hover:opacity-80  transition-all duration-300 bg-transparent text-[#23A455] font-bold text-[0.6rem] lg:text-base w-[20%]"
        >
          see details
        </button>
      </div>
      <hr />
    </Fragment>
  );
}
