import "./App.css";

import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import Home from "./page/Home";
import LoginPage from "./page/account/LoginPage";
import RegisterPage from "./page/account/RegisterPage";
import AlmostTherePage from "./page/account/AlmostTherePage";
import ForgotPasswordPage from "./page/account/ForgotPasswordPage";
import ResetPasswordTokenPage from "./page/account/ResetPasswordTokenPage";
import OnboardingPage from "./page/account/OnboardingPage";
import DashboardPage from "./page/DashboardPage";
import WalletPage from "./page/WalletPage";
import ProductPage from "./page/product/ProductPage";
import ProductDetailPage from "./page/product/ProductDetailPage";
import CartPage from "./page/product/CartPage";
import CheckoutPage from "./page/product/CheckoutPage";
import SuccessPage from "./page/product/SuccessPage";
import OrderPage from "./page/OrderPage";
import OrderOverviewPage from "./page/product/OrderOverviewPage";
import ProfilePage from "./page/ProfilePage";
import IntegrationPage from "./page/IntegrationPage";
import FundWalletPage from "./page/FundWalletPage";
import FundingSuccessPage from "./page/FundingSuccessPage";
import PasswordResetPage from "./page/PasswordResetPage";
import { useState } from "react";

import NotFound from "./page/NotFound";

function App() {
  const [show, setShow] = useState(false);

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/almost-there" element={<AlmostTherePage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordTokenPage />} />
      <Route path="/password-reset" element={<PasswordResetPage />} />
      <Route path="/onboarding" element={<OnboardingPage />} />
      <Route
        path="/"
        element={<DashboardPage show={show} setShow={setShow} />}
      />
      <Route path="/wallet" element={<WalletPage />} />
      <Route path="/fund-wallet" element={<FundWalletPage />} />
      <Route path="/success" element={<FundingSuccessPage />} />
      <Route path="/product" element={<ProductPage />} />
      <Route path="/product/:slug" element={<ProductDetailPage />} />
      <Route path="/product/cart" element={<CartPage />} />
      <Route path="/product/checkout" element={<CheckoutPage />} />
      <Route path="/product/success" element={<SuccessPage />} />
      <Route path="/orders" element={<OrderPage />} />
      <Route path="/orders/:id" element={<OrderOverviewPage />} />
      <Route
        path="/profile"
        element={<ProfilePage setShow={setShow} show={show} />}
      />
      <Route path="/integration" element={<IntegrationPage />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default App;
