import React, { useState } from "react";
import copy from "../../images/copy.png";

import { CopyToClipboard } from "react-copy-to-clipboard";

export default function Virtual11({ detail }) {
  const [copied, setCopied] = useState(false);
  return (
    <div className="flex drop-shadow-xl   p-4 flex-col space-y-4 rounded-xl bg-white lg:w-[30rem] ">
      <h1 className="text-[1.25rem] font-bold text-center text-[#120A00] capitalize">
        Fund Virtual Account
      </h1>
      <div className="w-full flex flex-col space-y-2">
        <div className="flex items-center justify-between w-full">
          <p className="dash-t">bank</p>
          <p className="dash-t flex items-center space-x-4">
            {detail.bank.name}
            <CopyToClipboard
              text={detail.bank.name}
              onCopy={() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 1000);
              }}
            >
              <span className="cursor-pointer w-[15px] ml-2 text-green-600">
                <img className="img" src={copy} alt="copy" />
              </span>
            </CopyToClipboard>
          </p>
        </div>
        <div className="flex items-center justify-between w-full">
          <p className="dash-t">account no</p>
          <p className="dash-t relative flex items-center space-x-4">
            {detail.account_number}
            <CopyToClipboard
              text={detail.account_number}
              onCopy={() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 1000);
              }}
            >
              <span className="w-[15px]  cursor-pointer ml-2 text-green-600">
                <img className="img" src={copy} alt="copy" />
              </span>
            </CopyToClipboard>
            {copied && (
              <p className="absolute top-0 bg-white p-1 left-0">Copied!</p>
            )}
          </p>
        </div>
        <div className="flex items-center justify-between w-full">
          <p className="dash-t">account name</p>
          <p className="dash-t flex items-center space-x-4">
            {detail.account_name}
            <CopyToClipboard
              text={detail.account_name}
              onCopy={() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 1000);
              }}
            >
              <span className="w-[15px] cursor-pointer ml-2 text-green-600">
                <img className="img" src={copy} alt="copy" />
              </span>
            </CopyToClipboard>
          </p>
        </div>
      </div>
    </div>
  );
}
