import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineQuestion } from "react-icons/ai";
import Recepient from "./Recepient";
import { CartContext } from "../../util/context";
import CartBtn from "../UI/CartBtn";
import RecepientPopup from "../UI/RecepientPopup";
import EditRecepient from "./EditRecepient";
import { getProduct } from "../../util/http";

import { getToken } from "../../util/util";

export default function CartItem({
  item,
  allRecepients,
  setAllRecepients,
  setShowPopup,
  showPopup,
  recepient,
  cart,
  user,
}) {
  const ctx = useContext(CartContext);

  const quantity = ctx.getProductQuantity(item.id);

  const price = new Intl.NumberFormat().format(item.price);

  const [show2, setShow2] = useState(false);
  const [show1, setShow1] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [productDetail, setProductDetail] = useState("");
  const [qty, setQty] = useState(quantity);
  const [isError, setIsError] = useState(false);
  const token = getToken("token");

  useEffect(() => {
    setQty(quantity);
  }, [quantity]);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getProduct(token);
        const product = response.data?.find((el) => el.id === item.id);

        setProductDetail(product);
      } catch (error) {}
    }
    fetchData();
  }, [item.id, token]);

  useEffect(() => {
    if (qty > productDetail?.available_quantity) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [qty, productDetail?.available_quantity]);

  const navigate = useNavigate();
  const cartRecepient = recepient(item.id);
  localStorage.setItem(item.id, cartRecepient);

  function addCart(type) {
    setQty((prev) => prev + 1);
    ctx.addOneToCart(item, allRecepients, +qty, type ? true : false);
  }

  function removeCart() {
    if (qty === 0) {
      return;
    }
    ctx.removeOneFromCart(item.id);
    setQty((prev) => prev - 1);
  }
  return (
    <Fragment>
      <div
        key={item.id}
        className={`w-full ${
          cart.length === 1 ? "border-none" : "border-b-2"
        } pr-2 py-2 flex items-center gap-2`}
      >
        <span
          onClick={() => ctx.deleteFromCart(item.id)}
          className="w-[5%] lg:w-[10%] text-center cursor-pointer text-sm lg:text-lg flex items-center justify-center text-red-600 font-bold"
        >
          <AiOutlineClose />
        </span>
        <div className="w-[30%] flex space-x-1 lg:space-x-4 items-center">
          <div
            onClick={() => navigate(`/product/${item.slug}`)}
            className="w-[35px] cursor-pointer lg:w-[4.25rem]"
          >
            <img
              className="img h-[35px] lg:h-[6rem] rounded-xl"
              src={item.image}
              alt="cart"
            />
          </div>{" "}
          <p
            onClick={() => navigate(`/product/${item.slug}`)}
            className="text-center text-[0.5rem] lg:text-sm font-normal text-[#1D1F1F] capitalize"
          >
            {item.name}
          </p>
        </div>
        <span className="w-[15%] text-center text-[0.6rem] lg:text-xl font-bold">
          ₦{price}.00
        </span>
        <div className=" lg:w-[130px]">
          <div
            className={`w-full px-2 py-1 mx-auto border-2 ${
              isError ? "border-red-500" : ""
            } rounded-xl lg:py-2 lg:px-4 gap-2 lg:gap-4 flex items-center justify-center`}
          >
            <CartBtn
              qty={+qty}
              setQty={setQty}
              addFn={addCart}
              removeFn={removeCart}
              quantity={quantity}
            />
          </div>
        </div>
        <span className="hidden lg:block lg:w-[15%]  text-center text-[0.6rem] lg:text-xl font-bold">
          ₦{price}.00
        </span>
        <div className="w-[15%] relative space-x-3 flex items-center justify-center">
          <Fragment>
            <p
              onClick={() => {
                if (cartRecepient.length === 0) {
                  setShow2(true);
                } else {
                  setShowEdit(true);
                }
              }}
              className="hidden lg:block text-xs text-center lg:text-sm text-[#23A455] font-bold cursor-pointer"
            >
              {cartRecepient.length > 0 ? "Edit" : "Add"} Recipient(s)
            </p>
            <p
              onClick={() => {
                if (cartRecepient.length === 0) {
                  setShow2(true);
                } else {
                  setShowEdit(true);
                }
              }}
              className="text-xs lg:hidden lg:text-sm text-[#23A455] font-bold cursor-pointer capitalize"
            >
              {cartRecepient.length > 0 ? "edit" : "add"}
            </p>
          </Fragment>
          <span
            onMouseOver={() => setShow1(true)}
            onMouseLeave={() => setShow1(false)}
            className="p-[0.1rem] hidden  mr-4 cursor-pointer lg:flex items-center rounded-full bg-[#FDB933]"
          >
            <AiOutlineQuestion />
          </span>
          {show1 && (
            <p className="bg-[#FEF7EC] text-xs top-8 cursor-pointer p-2 text-center hidden lg:block lg:w-[208px] left-[-20%] absolute font-normal">
              If no recipient email is added, the code(s) will be delivered to
              your registered email address.
            </p>
          )}
        </div>
      </div>

      {show2 && (
        <Recepient
          setAllRecepients={setAllRecepients}
          id={item.id}
          item={item}
          setShow={setShow2}
          cartQty={qty}
          user={user}
        />
      )}
      {showEdit && (
        <EditRecepient
          id={item.id}
          setShowEdit={setShowEdit}
          recepient={cartRecepient}
          // setState={setState}
        />
      )}
      {showPopup && <RecepientPopup setShowPopup={setShowPopup} />}
    </Fragment>
  );
}
