import React from "react";
import { Link } from "react-router-dom";
import PurchaseList from "./PurchaseList";

import less from "../../images/less.png";

export default function DashboardPurchases({ data }) {
  return (
    <div className="hidden lg:block w-[96%] bg-white drop-shadow-xl p-6 rounded-xl mx-auto  mt-4">
      <div className="flex  bg-white  w-full items-center justify-between">
        <h1 className="text-[1.125rem] font-bold text-[#3F434A]">
          Recent Purchase(s)
        </h1>
        <Link
          className="text-[0.875rem] capitalize text-[#627E84] flex items-center space-x-8"
          to="/orders"
        >
          view all
          <span className="w-[8px] ml-2 font-bold">
            <img className="img" src={less} alt="" />
          </span>
        </Link>
      </div>
      <PurchaseList data={data} />
    </div>
  );
}
