import React, { useState, useEffect, Fragment } from "react";
import Header from "../dashboard/Header";
import Popup from "../UI/Popup";
import DetailCard from "./DetailCard";
import ProductDetailHeader from "./ProductDetailHeader";
import LoadingBar from "react-top-loading-bar";

export default function ProductDetailCenter({ product, setModal }) {
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!product) {
      setProgress(20);
      setProgress(40);
      setProgress(60);
      setProgress(80);
      setTimeout(() => setProgress(100), 2000);
    }
  }, [product, setProgress]);

  return (
    <Fragment>
      <div>
        <LoadingBar
          color="#23A455"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>
      <div className="w-full lg:flex-1 pb-10 h-full lg:h-[160vh] relative">
        <Header setModal={setModal} />
        <div className="w-full bg-white h-full relative">
          <ProductDetailHeader text={product.name} />
          <DetailCard setType={setType} setShow={setShow} product={product} />
          {show && <Popup text={type} setShow={setShow} />}
        </div>
      </div>
    </Fragment>
  );
}
