import React from "react";

export default function OrderTitle() {
  return (
    <div className="w-full mb-8">
      <div className="w-full flex items-center">
        <h1 className="order-text w-[20%] text-start uppercase">ORDER ID</h1>
        <h1 className="order-text w-[20%] text-start uppercase">DATE</h1>
        <h1 className="order-text w-[20%] text-center uppercase">AMOUNT</h1>
        <h1 className="order-text w-[20%] text-center uppercase">STATUS</h1>
        <h1 className="order-text w-[20%] text-center uppercase">ACTION</h1>
      </div>
    </div>
  );
}
