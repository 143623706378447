import React, { Fragment } from "react";
import Overlay from "./Overlay";
import { Link } from "react-router-dom";

export default function RecepientPopup({ setShowPopup }) {
  return (
    <Fragment>
      <Overlay />
      <div className="w-[90%] flex items-center justify-center lg:w-[40%] p-8  bg-white z-[60] rounded-lg shadow-lg absolute top-[40%] left-5 lg:left-[30%]">
        <div className="w-full flex flex-col gap-12">
          <div className="w-full flex flex-col gap-4">
            <h1 className="text-black font-bold text-xl p-4 border-b-2 text-center">
              Your recepient list is empty
            </h1>

            <p className="text-base text-center font-normal capitalize text-[#333]">
              your gift card codes will be sent to your registered email for
              orders without recepient channels
            </p>
          </div>
          <div className="action">
            <button onClick={() => setShowPopup(false)} className="button">
              back
            </button>
            <Link
              to="/product/checkout"
              className="button flex items-center justify-center"
            >
              proceed
            </Link>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
