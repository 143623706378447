import React from "react";
import Header from "../dashboard/Header";
import IntegrationContainer from "./IntegrationContainer";

export default function IntegrationCenter({ setModal }) {
  return (
    <div className="w-full bg-white pb-10 lg:flex-1 h-full lg:h-[160vh] relative">
      <Header setModal={setModal} title="integration" />
      <div className="w-full relative">
        <IntegrationContainer />
      </div>
    </div>
  );
}
