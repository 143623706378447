import React from "react";

export default function PurchaseOverview({ order }) {
  const date = new Date(order?.created_at);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const product = order?.order_items?.find((item) => item);

  const productName = product?.product?.name;

  return (
    <div className="w-full bg-white h-[15rem] rounded-xl  flex space-y-0 flex-col drop-shadow-lg  p-4">
      <h1 className="text-base lg:text-xl font-bold text-start text-[#1D1F1F]">
        Purchase Overview
      </h1>
      <div className="flex lg:p-8 items-center justify-between gap-4 lg:gap-8">
        <div className="flex h-[10rem] flex-col space-y-6 lg:space-y-4">
          <div className="flex w-full flex-col space-y-4 lg:space-y-0">
            <h1 className="text-base lg:text-xl font-bold text start">
              {productName}
            </h1>
            <p className="text-start font-normal text-xs lg:text-base">
              Order Number: {order.order_no}
            </p>
          </div>

          <div className="flex flex-col space-y-0">
            <h1 className="text-xs lg:text-base font-normal text start">
              Quantity: {product?.quantity ? product?.quantity : 0}
            </h1>
            <p className="text-start font-bold text-base">
              On {`${day}/${month}/${year}`}
            </p>
          </div>
        </div>
        {false && (
          <p className="lg:py-4 py-2 px-6 lg:px-8 text-white font-bold rounded-xl shadow-lg bg-[#00AC45] capitalize">
            closed
          </p>
        )}
      </div>
      <hr />
    </div>
  );
}
