import validator from "validator";

export function validateLength(value) {
  return validator.isLength(value, {
    min: 8,
  });
}

export function validateBvnLength(value) {
  return validator.isLength(value, {
    min: 10,
    max: 11,
  });
}

export function validatePhoneLength(value) {
  return validator.isLength(value, {
    min: 10,
    max: 11,
  });
}

export function validateIncludes(value, key) {
  return validator.contains(value, key);
}

export function validateLetter(value) {
  return validator.isAlphanumeric(value, "en-US", {
    ignore: "@, !, $, # _ - % * ^ ",
  });
}

export function validateStrength(value) {
  return validator.isStrongPassword(value, {
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 1,
    minSymbols: 1,
    returnScore: true,
    pointsPerUnique: 1,
    pointsPerRepeat: 0.5,
    pointsForContainingLower: 10,
    pointsForContainingUpper: 10,
    pointsForContainingNumber: 10,
    pointsForContainingSymbol: 10,
  });
}

export function validateNumber(value) {
  return validator.isInt(value, {
    min: 0,
    max: 9,
  });
}

export function validateNumeric(value) {
  return validator.isNumeric(value, {
    no_symbols: true,
  });
}
