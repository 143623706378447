import React, { Fragment, useEffect, useState } from "react";
import { getWallet } from "../../util/http";
import Header from "../dashboard/Header";

import WalletBalance from "./WalletBalance";
import WalletTransaction from "./WalletTransaction";
import LoadingBar from "react-top-loading-bar";
import { getToken } from "../../util/util";

export default function WalletCenter({ setModal }) {
  const [wallet, setWallet] = useState("");
  const [progress, setProgress] = useState(0);
  const token = getToken("token");
  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getWallet(token);
        setProgress(80);
        setProgress(100);
        setWallet(response);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, []);
  return (
    <Fragment>
      <LoadingBar
        color="#23A455"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="w-full bg-[#f6f6f6] lg:flex-1 h-full lg:h-[160vh] relative">
        <Header setModal={setModal} />
        <div className="w-full mt-[6.5rem]">
          <WalletBalance wallet={wallet} />
          <WalletTransaction wallet={wallet} />
        </div>
      </div>
    </Fragment>
  );
}
