import React from "react";
import ProductDetailHeader from "../ProductDetail/ProductDetailHeader";
import FundWalletContainer from "./FundWalletContainer";
import Header from "./Header";

export default function FundWalletCenter({ setModal }) {
  return (
    <div className="w-full bg-[#f6f6f6] lg:flex-1 h-full lg:h-[160vh] relative">
      <Header title="fund wallet" setModal={setModal} />
      <ProductDetailHeader path="wallet" />
      <FundWalletContainer />
    </div>
  );
}
