import React, { Fragment, useEffect, useState } from "react";
import Header from "../dashboard/Header";

import WalletFilter from "../Wallet/WalletFilter";
import OrderList from "./OrderList";
import OrderTitle from "./OrderTitle";
import LoadingBar from "react-top-loading-bar";

import { getOrders } from "../../util/http";
import { getToken } from "../../util/util";

export default function OrderCenter({ setModal }) {
  const [orders, setOrders] = useState([]);
  const [progress, setProgress] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [allOrderData , setAllOrderData] = useState({})

  const [currentPage, setCurrentPage] = useState(1);
  const [orderPerPage, setOrderPerPage] = useState(12);

  const lasttransactionIndex = currentPage * orderPerPage;
  const firsttransactionIndex = lasttransactionIndex - orderPerPage;

  useEffect(() => {
    setTotalOrders(allOrderData?.meta?.total);
    setCurrentPage(allOrderData?.meta?.current_page)
    setOrderPerPage(allOrderData?.meta?.per_page)
  }, [allOrderData?.meta?.current_page, allOrderData?.meta?.per_page, allOrderData?.meta?.total, orders]);


  

  const token = getToken("token");
  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getOrders(token , currentPage);
        setProgress(80);
        setProgress(100);
        setOrders(response.data);
        setAllOrderData(response)
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, [currentPage, token]);

  const orderData = orders;



  return (
    <Fragment>
      <LoadingBar
        color="#23A455"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="w-full lg:flex-1 pb-10 h-full lg:h-[160vh] relative">
        <Header setModal={setModal} title="orders" />
        <div className="w-full bg-white lg:px-6 lg:py-4">
          <div className="w-full rounded-xl bg-white mt-24 p-8 drop-shadow-xl">
            {orders && (
              <Fragment>
                <OrderTitle />
                <OrderList orders={orderData} />
                <div className="w-full mt-4">
                  <WalletFilter
                    totaltransaction={totalOrders}
                    transactionPerPage={orderPerPage}
                    setCurrentPage={setCurrentPage}
                    currentPage={currentPage}
                    data={orders}
                  />
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
