import axios from "axios";

const API_URL = "https://staging-sosoapi2103.giftscardworld.com/api/v1";

export async function signup(data) {
  const response = await axios.post(`${API_URL}/auth/register`, data);

  return response.data;
}

export async function verifySignup(data) {
  const response = await axios.post(`${API_URL}/auth/verify-token`, data);

  return response.data;
}

export async function resendSignupToken(data) {
  const response = await axios.post(`${API_URL}/auth/resend-token`, data);

  return response.data;
}

export async function login(data) {
  const response = await axios.post(`${API_URL}/auth/login`, data);

  return response.data;
}

export async function forgotPassword(data) {
  const response = await axios.post(`${API_URL}/auth/forgot-password`, data);

  return response.data;
}

export async function getProfile(token) {
  const response = await axios.get(`${API_URL}/profile`, {
    headers: {
      Authorization: `Bearer ${token} `,
    },
  });

  return response.data;
}

export async function updateProfile(token, data) {
  const response = await axios.post(`${API_URL}/profile/update`, data, {
    headers: {
      Authorization: `Bearer ${token} `,
      "Content-Type": "multipart/form-data",
    },
  });

  return response.data;
}

export async function changePassword(token, data) {
  const response = await axios.post(
    `${API_URL}/profile/change-password`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token} `,
      },
    }
  );

  return response.data;
}

export async function virtualAccount(token, data) {
  const response = await axios.post(
    `${API_URL}/profile/initiate-verify-bvn`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token} `,
      },
    }
  );

  return response.data;
}

export async function getWallet(token) {
  const response = await axios.get(
    `${API_URL}/wallet`,

    {
      headers: {
        Authorization: `Bearer ${token} `,
      },
    }
  );

  return response.data;
}

export async function getProduct(token, page) {
  const response = await axios.get(`${API_URL}/products?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token} `,
    },
  });

  return response.data;
}

export async function searchProduct(token, name) {
  const response = await axios.get(`${API_URL}/products?name=${name}`, {
    headers: {
      Authorization: `Bearer ${token} `,
    },
  });

  return response.data;
}

export async function showProduct(token, id) {
  const response = await axios.get(`${API_URL}/products/${id}`, {
    headers: {
      Authorization: `Bearer ${token} `,
    },
  });

  return response.data;
}

export async function initializeFundWallet(token, data) {
  const response = await axios.post(
    `${API_URL}/wallet/initialize-fund-wallet`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token} `,
      },
    }
  );

  return response.data;
}

export async function verifyTransaction(token, transactionID) {
  const response = await axios.post(
    `${API_URL}/transactions/verify/${transactionID}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token} `,
      },
    }
  );

  return response.data;
}

export async function getDashboard(token) {
  const response = await axios.get(`${API_URL}/dashboard`, {
    headers: {
      Authorization: `Bearer ${token} `,
    },
  });
  return response.data;
}

export async function createOrder(token, data) {
  const response = await axios.post(`${API_URL}/orders`, data, {
    headers: {
      Authorization: `Bearer ${token} `,
    },
  });
  return response.data;
}

export async function getOrders(token  , page = 1) {
  const response = await axios.get(`${API_URL}/orders?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token} `,
    },
  });
  return response.data;
}

export async function getOrderDetail(token, id) {
  const response = await axios.get(`${API_URL}/orders/${id}`, {
    headers: {
      Authorization: `Bearer ${token} `,
    },
  });
  return response.data;
}

export async function resendCode(token, id) {
  const response = await axios.post(
    `${API_URL}/orders/${id}/resend-code`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token} `,
      },
    }
  );
  return response.data;
}

export async function resendProductCode(token, id) {
  const response = await axios.post(
    `${API_URL}/product-code-recipients/${id}/resend-code`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token} `,
      },
    }
  );
  return response.data;
}

export async function getYears(min) {
  const response = await axios.get(
    `https://birthday-fy36.onrender.com/api/v1/years?min=${min}`
  );
  return response.data;
}

export async function getMonths() {
  const response = await axios.get(
    `https://birthday-fy36.onrender.com/api/v1/months`
  );
  return response.data;
}
export async function getDay() {
  const response = await axios.get(
    `https://birthday-fy36.onrender.com/api/v1/days`
  );
  return response.data;
}

export async function getIntegration(token) {
  const response = await axios.get(`${API_URL}/integrations`, {
    headers: {
      Authorization: `Bearer ${token} `,
    },
  });
  return response.data;
}

export async function updateIntegration(token, data) {
  const response = await axios.post(
    `${API_URL}/integrations/update-status`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token} `,
      },
    }
  );
  return response.data;
}

export async function regenerateKey(token, id) {
  const response = await axios.post(
    `${API_URL}/integrations/${id}/regenerate-keys`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token} `,
      },
    }
  );
  return response.data;
}
