import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CartContext } from "../../util/context";

export default function CartStats({ setShowPopup, allRecepients }) {
  const ctx = useContext(CartContext);
  const navigate = useNavigate();
  const [cost, setCost] = useState("");

  useEffect(() => {
    async function fetchCost() {
      const totalCost = await ctx.getTotalCost();
      setCost(totalCost);
    }
    fetchCost();
  }, [ctx, ctx.items]);

  const price = new Intl.NumberFormat().format(cost);
  return (
    <div className="w-full lg:w-[92%] mx-auto mt-4  flex items-end justify-end">
      <div className="flex p-8 rounded-xl shadow-xl w-full lg:w-[30%] bg-white flex-col space-y-6">
        <div className="w-full flex items-center justify-between">
          <p className="text-sm uppercase font-bold text-[#5D5F5F]">subtotal</p>
          <p className="text-sm lg:text-xl font-bold text-black">₦{price}.00</p>
        </div>
        <div className="w-full flex items-center justify-between">
          <p className="text-sm uppercase font-bold text-[#5D5F5F]">total</p>
          <p className="text-sm lg:text-xl font-bold text-black">₦{price}.00</p>
        </div>
        <button
          onClick={() => {
            if (allRecepients.length === 0) {
              setShowPopup(true);
            } else {
              navigate("/product/checkout");
            }
          }}
          className="button flex items-center justify-center"
        >
          Proceed to Checkout
        </button>
      </div>
    </div>
  );
}
