import React, { useEffect } from "react";
import success from "../../images/Successful purchase-cuate 1.png";
import EmptyProduct from "../UI/EmptyProduct";
import { verifyTransaction } from "../../util/http";
import { getToken } from "../../util/util";

export default function SuccessContent() {
  const transactionID = localStorage.getItem("transactionID");
  const token = getToken("token");

  useEffect(() => {
    async function fetchData() {
      try {
        await verifyTransaction(token, transactionID);
        localStorage.removeItem("transactionID");
        localStorage.removeItem("cart");
        window.location.reload();
      } catch (error) {
        // console.log(error);
        localStorage.removeItem("transactionID");
      }
    }
    fetchData();
  }, [token, transactionID]);
  return (
    <div className="w-full h-[100vh] lg:h-[70%] flex items-center justify-center">
      <EmptyProduct
        image={success}
        text="Payment Successful"
        btnText="view order"
        path="/orders"
      />
    </div>
  );
}
