import React, { Fragment, useState } from "react";
import MobileVirtualAcc from "./MobileVirtualAcc";
import TotalOrder from "./TotalOrder";
import Virtual1 from "./Virtual1";
import Virtual2 from "./Virtual2";

export default function VirtualAccount({ dashboard }) {
  const [showModal, setShowModal] = useState(false);
  const accDetails = dashboard?.account;
  return (
    <Fragment>
      <div className="hidden lg:flex w-full lg:w-full items-center justify-center lg:h-[168px] lg:px-2">
        <div className="w-full lg:flex items-center gap-8 justify-between">
          <TotalOrder
            showModal={showModal}
            setShowModal={setShowModal}
            dashboard={dashboard}
          />
          {
          accDetails ? <Virtual1 detail={accDetails} /> :
           <Virtual2 />
          }
        </div>
      </div>
      <MobileVirtualAcc setShowModal={setShowModal} dashboard={dashboard} />
    </Fragment>
  );
}
