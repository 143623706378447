import React, { Fragment, useContext } from "react";
import Overlay from "../UI/Overlay";
import { AiOutlineClose } from "react-icons/ai";
import { FiEdit2 } from "react-icons/fi";
import { useState } from "react";
import { useEffect } from "react";
import { CartContext } from "../../util/context";
import { validateNumeric, validatePhoneLength } from "../../util/Validator";
import { getToken, setWithExpiry } from "../../util/util";

export default function EditRecepient({ recepient, setShowEdit, id }) {
  const [showInput, setShowInput] = useState(false);
  const [contact, setContact] = useState("");
  const [recId, setRecId] = useState("");
  const [cartIndex, setCartIndex] = useState(0);
  const [cart, setCart] = useState([]);
  const [type, setType] = useState("email");
  const [channel, setChannel] = useState("email");
  const [qty, setQty] = useState(0);
  const [label, setLabel] = useState("email");
  const [newRec, setNewRec] = useState("");
  const [newQty, setNewQty] = useState(1);
  const [btnTitle, setBtnTitle] = useState("add new");
  const [showAdd, setShowAdd] = useState(false);
  const [newRecArr, setNewRecArr] = useState([]);
  const [length1, setLength1] = useState(false);
  const [num1, setNum1] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [showText1, setShowText1] = useState(false);

  const [valid, setValid] = useState(true);

  const ctx = useContext(CartContext);
  const quantity = ctx.getProductQuantity(id);

  useEffect(() => {
    setCart(getToken("cart") || []);
  }, [cart]);

  useEffect(() => {
    const rec = cart?.flatMap((item) => {
      return [...item.recipients];
    });
    // const newArr = rec.push(data);
    setNewRecArr(rec);
  }, [cart]);
  const rec = cart?.flatMap((item) => {
    return [...item.recipients];
  });

  const cartRec = rec.filter((item) => item.id === id);

  const recArr = cartRec.map((el) => el.num);
  const recQty = recArr.reduce((acc, sum) => acc + sum, 0);

  useEffect(() => {
    if (recQty >= quantity) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [quantity, recQty]);

  useEffect(() => {
    if (channel === "email") {
      setType("email");
    } else if (type === "sms") {
      setType("tel");
    } else if (type === "whatsapp") {
      setType("tel");
    }
  }, [channel, type]);

  function PhoneValidator(e) {
    const val = e.target.value;
    if (validatePhoneLength(val)) {
      setContact(val);
      setLength1(true);
    } else {
      setLength1(false);
    }

    if (validateNumeric(val)) {
      setContact(val);
      setNum1(true);
    } else {
      setContact("");
      setNum1(false);
    }

    if (length1 && num1) {
      setValidPhone(true);
      setShowText1(false);
    } else {
      setValidPhone(false);
      setShowText1(true);
    }
  }

  function editHandler() {
    const rec = cart.flatMap((item) => {
      return [...item.recipients];
    });
    const cartRec = rec.filter((item) => item.id === recId);

    const recArr = cartRec.map((el) => el.num);
    recArr.splice(cartIndex, 1);

    const recQty = recArr.reduce((acc, sum) => acc + sum, 0);

    if (+qty + recQty > quantity) {
      alert("Recepient(s) cannot be more than total quantity in cart");
      return;
    }

    const newRec = cartRec.map((el, i) => {
      return {
        channel: el.channel,
        contact: contact && cartIndex === i ? contact : el.contact,
        id: el.id,
        num: qty && cartIndex === i ? qty : el.num,
      };
    });
    const newRecepient = [];
    function mapData(arrayData) {
      arrayData.forEach((item, index) => {
        for (let i = 1; i <= item.num; i++) {
          newRecepient.push(item);
        }
      });
    }
    mapData(newRec);

    const cartItem = cart.map((item) => {
      return {
        image: item.image,
        name: item.name,
        price: item.price,
        id: item.id,
        quantity: item.quantity,
        slug: item.slug,
        recipients: recId === item.id ? newRecepient : item.recipients,
      };
    });

    setShowInput(false);
    setContact("");
    setWithExpiry("cart", cartItem, 24 * 60 * 60 * 1000);
  }

  function deleteHandler(id, index) {
    const rec = cart?.flatMap((item) => {
      return [...item.recipients];
    });
    const cartRec = rec.filter((item) => item.id === id);

    cartRec.splice(index, 1);

    const cartItem = cart.map((item) => {
      return {
        image: item.image,
        name: item.name,
        price: item.price,
        id: item.id,
        quantity: item.quantity,
        slug: item.slug,
        recipients: id === item.id ? cartRec : item.recipients,
      };
    });
    setShowInput(false);
    setWithExpiry("cart", cartItem, 24 * 60 * 60 * 1000);
  }

  function addHandler() {
    const data = {
      channel: label.toUpperCase(),
      contact: newRec,
      id,
      num: newQty,
    };

    if (btnTitle === "add new") {
      setShowAdd(true);
      setBtnTitle("add");
      return;
    } else {
      const cartRec = newRecArr.filter((item) => item.id === id);

      const recArr = cartRec.map((el) => el.num);
      const recQty = recArr.reduce((acc, sum) => acc + sum, 0);

      if (+newQty + +recQty > quantity) {
        alert("Recepient(s) cannot be more than total quantity in cart");
        return;
      }

      const newRec = cartRec.map((el, i) => {
        return {
          channel: el.channel,
          contact: contact && cartIndex === i ? contact : el.contact,
          id: el.id,
          num: el.num,
        };
      });

      newRec.push(data);

      const newRecepient = [];
      function mapData(arrayData) {
        arrayData.forEach((item, index) => {
          for (let i = 1; i <= item.num; i++) {
            newRecepient.push(item);
          }
        });
      }
      mapData(newRec);
      // const formattedRecepient = newRecepient.map((el, i) => {
      //   return {
      //     channel: el.channel,
      //     contact: el.contact,
      //     id: el.id,
      //   };
      // });

      const cartItem = cart.map((item) => {
        return {
          image: item.image,
          name: item.name,
          price: item.price,
          id: item.id,
          quantity: item.quantity,
          slug: item.slug,
          recipients: id === item.id ? newRecepient : item.recipients,
        };
      });

      setShowInput(false);
      setContact("");
      setWithExpiry("cart", cartItem, 24 * 60 * 60 * 1000);
    }
  }

  return (
    <Fragment>
      <Overlay closeModal={() => setShowEdit(false)} />
      <div className="w-full h-full overflow-scroll">
        <div className="w-[90%] lg:w-[60%]  p-8 absolute  flex flex-col space-y-6 left-5 lg:left-60 top-60 bg-white z-[60] rounded-2xl shadow-lg">
          <div className="flex items-center justify-between">
            <h1 className="text-lg lg:text-2xl font-bold text-black capitalize">
              edit gift card delivery
            </h1>
            <span
              onClick={() => setShowEdit(false)}
              className="text-lg lg:text-xl text-black font-bold cursor-pointer"
            >
              <AiOutlineClose />
            </span>
          </div>
          <div className="w-full flex items-end justify-end">
            <p className="text-sm font-bold">Qty Remaining: {quantity}</p>
          </div>
          {recepient.map((item, i) => {
            return (
              <div key={i} className="w-full flex flex-col gap-4">
                <div className="w-full flex flex-col gap-4">
                  <div className="w-full gap-2 lg:gap-4 flex items-center justify-between">
                    <div className="flex items-center gap-4">
                      <p className="lg:w-[100px]">{item.channel}:</p>
                      <p className="lg:w-[200px] text-base font-semibold">
                        {item.contact}
                      </p>
                      {false && (
                        <p className="text-base font-semibold">{item.num}</p>
                      )}
                    </div>
                    <div className="flex items-center gap-4">
                      <span
                        onClick={() => {
                          setShowInput(true);
                          setRecId(item.id);
                          setCartIndex(i);
                          setChannel(item.channel?.toLowerCase());
                        }}
                        className="text-base lg:text-xl text-black cursor-pointer"
                      >
                        <FiEdit2 />
                      </span>
                      <span
                        onClick={() => deleteHandler(item.id, i)}
                        className="text-lg lg:text-xl text-black font-bold cursor-pointer"
                      >
                        <AiOutlineClose />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          {showInput && (
            <div className="flex w-full items-center flex-wrap space-x-4">
              {false && <label className="label w-[50px] lg:w-[100px]"></label>}
              <div className="input-container w-[60%] lg:flex-1">
                <label className="label">Edit recepient</label>
                {channel === "sms" || channel === "whatsapp" ? (
                  <input
                    className="w-full input"
                    placeholder={`Enter recepient here`}
                    type="text"
                    maxlength={11}
                    onChange={PhoneValidator}
                    value={contact}
                  />
                ) : (
                  <input
                    className="w-full input"
                    placeholder={`Enter recepient here`}
                    type="email"
                    onChange={(e) => setContact(e.target.value)}
                    value={contact}
                  />
                )}
              </div>
              <div className="input-container w-[25%] lg:w-[150px]">
                <label className="label">quantity</label>
                <input
                  className="w-full input"
                  type="number"
                  min={1}
                  onChange={(e) => setQty(e.target.value)}
                  value={qty}
                />
              </div>
              <div w-full className="mt-6">
                <button
                  onClick={editHandler}
                  className="text-base font-bold text-[#23A455] capitalize hover:opacity-80 transition-all duration-300"
                >
                  update
                </button>
              </div>
            </div>
          )}
          {showText1 && (
            <p className="error-text">
              {validPhone ? "" : "Enter 10 digits Phone Number"}
            </p>
          )}
          <hr />
          {showAdd && (
            <div className="w-full flex gap-4 flex-wrap items-center">
              <div className="input-container w-[45%] lg:w-[150px]">
                <label className="label">select channel</label>
                <select
                  className="input capitalize"
                  onChange={(e) => {
                    setLabel(e.target.value);
                    setNewRec("");
                  }}
                >
                  <option value="email">email</option>
                  <option value="sms">SMS</option>
                  {false && <option value="whatsapp">whatsApp</option>}
                </select>
              </div>
              <div className="input-container w-[45%] lg:flex-1">
                <label className="label">Enter recepient</label>
                {label === "sms" || label === "whatsapp" ? (
                  <input
                    className="w-full input"
                    required
                    placeholder={`Enter recepient here`}
                    type="text"
                    maxlength={11}
                    onChange={(e) => {
                      setNewRec(e.target.value);
                      if (validateNumeric(e.target.value)) {
                        setNewRec(e.target.value);
                      } else {
                        setNewRec("");
                      }
                    }}
                    value={newRec}
                  />
                ) : (
                  <input
                    className="w-full input"
                    required
                    placeholder={`Enter recepient here`}
                    type="email"
                    onChange={(e) => setNewRec(e.target.value)}
                    value={newRec}
                  />
                )}
              </div>
              <div className="input-container w-full lg:w-[100px]">
                <label className="label">quantity</label>
                <input
                  className="w-full input"
                  required
                  type="number"
                  onChange={(e) => setNewQty(e.target.value)}
                  value={newQty}
                />
              </div>
            </div>
          )}
          <div className="action pt-2">
            <button disabled={!valid} onClick={addHandler} className="button">
              {btnTitle}
            </button>
            <button onClick={() => setShowEdit(false)} className="button">
              proceed
            </button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
