import React, { useState } from "react";
import APIINTEGRATION from "./APIINTEGRATION.js";
import APIIntegrationModal from "./APIIntegrationModal.js";
import Integrations from "./Integrations";
import Password from "./Password.js";
import WordpressIntegration from "./WordpressIntegration.js";
import WordpressPasssword from "./WordpressPasssword.js";
import { useEffect } from "react";
import { getIntegration } from "../../util/http.js";
import { getToken } from "../../util/util.js";

export default function IntegrationContainer() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const token = getToken("token");

  const [api, setApi] = useState("");
  const [wordPress, setWordpress] = useState("");

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getIntegration(token);
        setApi(response.data[0]);
        setWordpress(response.data[1]);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, [token]);

  return (
    <div className="w-full mt-16">
      <div className="w-full  flex flex-col space-y-8 p-6">
        <Integrations />
        <APIINTEGRATION
          api={api}
          wordPress={wordPress}
          setShow1={setShow2}
          setShow={setShow}
        />
        {show && (
          <APIIntegrationModal
            api={api}
            setApi={setApi}
            setShow1={setShow1}
            setShow={setShow}
          />
        )}
        {show2 && (
          <WordpressIntegration
            wordPress={wordPress}
            setWordpress={setWordpress}
            setShow1={setShow3}
            setShow={setShow2}
          />
        )}
        {show1 && <Password setShow={setShow1} />}
        {show3 && <WordpressPasssword setShow={setShow3} />}
      </div>
    </div>
  );
}
