import React from "react";
import { order } from "../../util/data";
import OrderItem from "./OrderItem";
import { Fragment } from "react";
import EmptyProduct from "../UI/EmptyProduct";
import cart from "../../images/shopping-cart 1.png";

export default function OrderList({ orders }) {
  return (
    <div className="w-full h-[900px] overflow-scroll flex flex-col space-y-4">
      {order.length > 0 ? (
        <Fragment>
          {orders.map((item, i) => (
            <OrderItem key={i} item={item} />
          ))}
        </Fragment>
      ) : (
        <div className="w-full h-[90vh] flex items-center justify-center">
          <EmptyProduct
            image={cart}
            text="You have placed no order yet"
            btnText="Continue Shopping"
          />
        </div>
      )}
    </div>
  );
}
