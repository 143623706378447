import React from "react";

import PurchaseList from "./PurchaseList";

export default function PurchaseInformation({
  setShow,
  order,
  resendCode,
  loading,
  setId,
}) {
  return (
    <div className="w-[96%] rounded-xl mx-auto h-[49rem] bg-white drop-shadow-lg p-2 lg:p-10 flex flex-col space-y-4">
      <h1 className="text-xl font-bold text-start text-[#1D1F1F] capitalize">
        purchase information
      </h1>
      <PurchaseList
        setId={setId}
        loading={loading}
        resendCode={resendCode}
        order={order}
        setShow={setShow}
      />
    </div>
  );
}
