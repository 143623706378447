import React from "react";
import { useContext } from "react";
import { CartContext } from "../../util/context";

export default function Details({ user }) {
  return (
    <div className="w-full ml-4 lg:w-1/2 flex gap-4 lg:gap-8  flex-col space-y-0 lg:space-y-4 p-6">
      <div className="w-full flex flex-col space-y-2">
        <h1 className="text-base capitalize font-normal text-start">email</h1>
        <p className="text-base font-semibold text-start">
          {user.email?.slice(0, 20)}
        </p>
      </div>

      <div className="w-full flex flex-col space-y-2">
        <h1 className="text-base capitalize font-normal text-start">Phone</h1>
        <p className="text-base capitalize font-semibold text-start">
          {user.phone_number}
        </p>
      </div>
    </div>
  );
}
