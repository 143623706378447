import React, { Fragment } from "react";
import Empty from "../UI/Empty";
import empty from "../../images/Business deal-cuate (1) 1.png";

export default function TransactionItem({ data }) {
  return (
    <Fragment>
      {data.length > 0 ? (
        <div className="flex overflow-scroll h-[675px]  p-4  flex-col space-y-4">
          {data.map((item) => {
            const transactionDate = new Date(item.service.created_at);
            const day = transactionDate.getDate();
            const month = +transactionDate.getMonth() + 1;
            const year = transactionDate.getFullYear();

            const hour = transactionDate.getHours();
            const minute = transactionDate.getMinutes();
            const seconds = transactionDate.getSeconds();
            return (
              <div
                key={item.id}
                className="w-full border-b-2 py-3 flex items-center justify-center"
              >
                <h1 className="order-item-text w-[25%] text-start">
                  {item.transaction_no}
                </h1>
                <div className="order-item-text w-[25%] text-start">
                  <h1
                    className={`${
                      item.type === "debit"
                        ? "text-[#FF3436]"
                        : "text-[#00AC45]"
                    } font-bold`}
                  >
                    {item.type}
                  </h1>
                </div>
                <h1 className="order-item-text w-[25%] text-start font-bold">
                  N{new Intl.NumberFormat().format(item.amount)}.00
                </h1>
                <h1 className="capitalize text-[0.5rem] lg:text-sm    text-[#5D5F5F] w-[25%] text-start">
                  {`${day.toString().padStart(2, 0)}/${month
                    .toString()
                    .padStart(2, 0)}/${year} ${hour
                    .toString()
                    .padStart(2, 0)}:${minute
                    .toString()
                    .padStart(2, 0)}:${seconds.toString().padStart(2, 0)}`}
                </h1>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="w-full h-[60vh] lg:h-[90vh] flex items-center justify-center">
          <Empty image={empty} text="no transactions to display" />
        </div>
      )}
    </Fragment>
  );
}
