import React from "react";

export default function TransactionTitle() {
  return (
    <div className="w-full p-4 flex items-center justify-center">
      <h1 className="order-text w-[25%] text-start">TRANSACTION ID</h1>
      <h1 className="order-text w-[25%] text-start">TYPE</h1>
      <h1 className="order-text w-[25%] text-start">AMOUNT</h1>
      <h1 className="order-text w-[25%] text-start">DATE</h1>
    </div>
  );
}
