import React, { Fragment } from "react";

import ProductDescription from "./ProductDescription";
import ProductImage from "./ProductImage";
import SimilarCard from "./SimilarCard";

export default function DetailCard({ product, setShow, setType }) {
  return (
    <Fragment>
      <div className="p-3 lg:p-6 w-[95%] lg:w-[90%] mx-auto bg-white mt-10 h-full lg:h-[600px] drop-shadow-xl rounded-xl">
        <div className="flex flex-col lg:flex-row lg:items-center gap-6 lg:justify-between">
          <ProductImage image={product.image_path} />
          <ProductDescription
            setType={setType}
            setShow={setShow}
            name={product.name}
            price={product.price}
            product={product}
            id={product.id}
            description={product.decription}
          />
        </div>
      </div>
      {false && <SimilarCard id={product.id} />}
    </Fragment>
  );
}
