import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import ProductDetail from "../../components/ProductDetail/ProductDetail";
import { getProduct, showProduct } from "../../util/http";
import { CartContext } from "../../util/context";
import { inactiveState } from "../../util/inactivity";
import { getToken } from "../../util/util";

export default function ProductDetailPage() {
  const token = getToken("token");
  const [product, setProduct] = useState("");
  const params = useParams();
  const slug = params.slug;

  let productDetail;

  const ctx = useContext(CartContext);
  const navigate = useNavigate();

  window.onload = function () {
    inactiveState(ctx, navigate);
  };

  useEffect(() => {
    if (!ctx.isLoggedIn) {
      navigate("/login");
    }
  }, [ctx]);

  useEffect(() => {
    async function fetchData() {
      const response = await showProduct(token, slug);

      const prodDetail = response.data;
      const similarCard = response.data;
      const similarProductDetail = similarCard?.related_products?.find(
        (item) => item.id === slug
      );
      if (prodDetail) {
        productDetail = prodDetail;
      } else if (similarProductDetail) {
        productDetail = similarProductDetail;
      }
      setProduct(productDetail);
    }
    fetchData();
  }, [token, setProduct, slug]);

  return (
    <Fragment>
      <ProductDetail product={product} />
    </Fragment>
  );
}
