import React, { Fragment, useEffect, useState } from "react";
import IntegrationPopup from "./IntegrationPopup";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import { MdContentCopy } from "react-icons/md";

import { CopyToClipboard } from "react-copy-to-clipboard";
import path from "../../images/Path.png";
import download from "../../images/download.png";
import { regenerateKey } from "../../util/http";
import { getToken } from "../../util/util";

export default function WordpressIntegration({
  setShow,
  setShow1,
  wordPress,
  setWordpress,
}) {
  const [icon, setIcon] = useState("eyes");
  const [icon1, setIcon1] = useState("eyes");
  const [copied, setCopied] = useState(false);
  const token = getToken("token");
  const [loading, setLoading] = useState(false);

  const [click, setClick] = useState(0);

  useEffect(() => {
    const localClick = localStorage.getItem("wordpress_clicks") || 0;
    setClick(+localClick);
  }, []);

  useEffect(() => {
    localStorage.setItem("wordpress_clicks", click);
  }, [click]);

  const [secret, setSecret] = useState(
    "*************************************************************"
  );
  const [publicKey, setPublic] = useState(
    "*************************************************************"
  );

  async function regenerateApiKey() {
    try {
      setLoading(true);
      const response = await regenerateKey(token, wordPress.id);
      alert("API Keys regeneration successful");
      localStorage.removeItem("wordpress_clicks");
      setClick(0);
      setLoading(false);
      setWordpress(response.data);
      setShow(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <IntegrationPopup>
      <div className="w-full flex px-6 flex-col space-y-6">
        <div className="w-full  flex items-center justify-between">
          <h1 className="text-[#1E1D1B] text-xl font-bold text-start">
            WordPress Integrations
          </h1>
          <span
            onClick={() => setShow(false)}
            className="text-xl text-black font-bold cursor-pointer"
          >
            <AiOutlineClose />
          </span>
        </div>
        <div className="w-full flex items-end justify-end">
          <button onClick={regenerateApiKey} className="btn">
            {loading ? "regenerating" : "regenerate keys"}
          </button>
        </div>
        <form className="w-full flex flex-col space-y-4">
          <div className="w-full gap-8 flex items-center relative justify-between">
            <label className="label">Live Secret Key</label>
            <div className="input relative flex items-center justify-between">
              <input
                className="flex-1 bg-transparent outline-none"
                type="text"
                disabled
                value={secret}
              />

              {click <= 1 && (
                <Fragment>
                  {icon === "eyes" ? (
                    <span
                      onClick={() => {
                        setClick((prev) => prev + 1);
                        setIcon("copy");
                        setSecret(wordPress.secret_key);
                      }}
                      className="text-base text-black cursor-pointer"
                    >
                      <AiOutlineEye />
                    </span>
                  ) : (
                    <CopyToClipboard
                      text={secret}
                      onCopy={() => {
                        setCopied(true);
                        setClick((prev) => prev + 1);
                        setSecret(
                          "*************************************************************"
                        );
                        setIcon("eyes");
                        setTimeout(() => setCopied(false), 1000);
                      }}
                    >
                      <span className="text-base text-black cursor-pointer">
                        <MdContentCopy />
                      </span>
                    </CopyToClipboard>
                  )}
                </Fragment>
              )}
              {copied && (
                <p className="absolute top-0 bg-white p-1 right-0">Copied!</p>
              )}
            </div>
          </div>
          <div className="w-full gap-8 flex items-center justify-between">
            <label className="label">Live Public Key</label>
            <div className="input flex items-center justify-between">
              <input
                className="flex-1 bg-transparent outline-none"
                type="text"
                disabled
                value={publicKey}
              />

              <Fragment>
                {icon1 === "eyes" ? (
                  <span
                    onClick={() => {
                      setIcon1("copy");
                      setPublic(wordPress.public_key);
                    }}
                    className="text-base text-black cursor-pointer"
                  >
                    <AiOutlineEye />
                  </span>
                ) : (
                  <CopyToClipboard
                    text={publicKey}
                    onCopy={() => {
                      setCopied(true);
                      setIcon1("eyes");
                      setTimeout(() => setCopied(false), 1000);
                    }}
                  >
                    <span className="text-base text-black cursor-pointer">
                      <MdContentCopy />
                    </span>
                  </CopyToClipboard>
                )}
              </Fragment>
            </div>
          </div>
          <a
            href="https://sosogamescodes.com/files/uploads/2023/06/Sosogames-Marchant-Connector.zip"
            target="_blank"
            className="w-full gap-8 flex items-center justify-between"
            download="wordpress pluggins"
          >
            <label className="label">download pluggin</label>
            <div className="flex-1 ">
              <div className="bg-[#F8F8F8] cursor-pointer p-1 lg:w-[250px] h-[56px] flex lg:gap-4 items-center">
                <div className="w-[20px] lg:w-[31px]">
                  <img
                    className="img h-[20px] lg:h-[30px]"
                    alt="path"
                    src={path}
                  />
                </div>
                <div className="flex-1 flex flex-col space-y-2">
                  <h1 className="text-[0.5rem] lg:text-base font-medium text-center">
                    Wordpress Plugin
                  </h1>
                  <p className="text-[0.5rem] lg:text-sm font-normal text-center">
                    1.2mb
                  </p>
                </div>
                <div className="w-[20px] lg:w-[27px]">
                  <img
                    className="img h-[20px] lg:h-[30px]"
                    alt="path"
                    src={download}
                  />
                </div>
              </div>
            </div>
          </a>
          <div className="w-full flex items-end justify-end">
            <button
              onClick={() => setShow(false)}
              type="button"
              className="bg-[#23A455]  hover:opacity-40 transition-all duration-300  hover:shadow-xl capitalize rounded-lg text-white text-base font-semibold cursor-pointer py-3 px-6"
            >
              done
            </button>
          </div>
        </form>
      </div>
    </IntegrationPopup>
  );
}
