import React, { Fragment, useState } from "react";
import { MdAdd } from "react-icons/md";
import { AiOutlineMinus } from "react-icons/ai";

export default function CartBtn({
  addFn,
  removeFn,
  quantity,
  available,
  qty,
  setQty,
  isError,
}) {
  return (
    <Fragment>
      <button
        onClick={() => removeFn()}
        className="text-xs lg:text-lg opacity-50 cursor-pointer text-black font-medium"
      >
        <AiOutlineMinus />
      </button>
      <input
        className="w-[30px] border-none text-center mx-auto p-1 outline-none text-base"
        type="text"
        onChange={(e) => {
          setQty(+e.target.value);
        }}
        onKeyUp={() => {
          addFn(true);
          setQty((prev) => prev - 1);
        }}
        value={+qty}
      />
      {false && (
        <span className="text-xs lg:text-lg text-black font-medium">
          {quantity}
        </span>
      )}
      <button
        onClick={() => addFn(false)}
        className="text-xs lg:text-lg cursor-pointer text-black font-medium"
      >
        <MdAdd />
      </button>
    </Fragment>
  );
}
