import React, { Fragment, useContext, useEffect } from "react";
import Login from "../components/auth/Login";
import Wallet from "../components/Wallet/Wallet";
import { CartContext } from "../util/context";
import { useNavigate } from "react-router-dom";
import { inactiveState } from "../util/inactivity";

export default function WalletPage() {
  const ctx = useContext(CartContext);
  const navigate = useNavigate();

  window.onload = function () {
    inactiveState(ctx, navigate);
  };

  useEffect(() => {
    if (!ctx.isLoggedIn) {
      navigate("/login");
    }
  }, [ctx]);
  return <Wallet />;
}
