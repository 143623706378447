import React, { useState, useEffect, useContext } from "react";

import { AiOutlineCheck } from "react-icons/ai";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { CartContext } from "../../util/context";

export default function Popup({ setShow, text }) {
  const [quantity, setQuantity] = useState(0);

  const ctx = useContext(CartContext);

  useEffect(() => {
    const quantityArr = ctx?.items?.map((item) => item?.quantity);
    const totalQuantity = quantityArr?.reduce((acc, sum) => acc + sum, 0);
    setQuantity(totalQuantity);
  }, [ctx.items]);
  return (
    <div className="flex flex-col z-50 space-y-4 w-full lg:w-[40%] fixed top-0 lg:top-4 right-0 lg:right-4 p-4 h-[120px] rounded-xl shadow-xl bg-[#00AC45]">
      <div className="w-full relative flex items-center justify-between">
        <span className="p-4  flex items-center bg-white rounded-full">
          <AiOutlineCheck />
        </span>
        <div className=" flex flex-col space-y-0">
          <span className="text-lg text-center font-bold text-white capitalize">
            {quantity} product {text ? text : "added"} to cart
          </span>
          <span className="text-sm lg:text-base font-normal text-center text-[#eee]">
            Your product has been {text ? text : "added"} to cart
          </span>
        </div>
        <span
          onClick={() => setShow(false)}
          className="text-lg  text-white font-bold cursor-pointer"
        >
          <AiOutlineClose />
        </span>
      </div>
      <div className="action">
        <Link
          className="text-[#F4ED48] font-bold lg:text-base text-sm capitalize"
          to="/product"
        >
          continue shopping
        </Link>
        <Link
          className="text-white font-bold lg:text-base text-sm capitalize"
          to="/product/cart"
        >
          view cart
        </Link>
      </div>
    </div>
  );
}
