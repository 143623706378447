import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

export default function DashboardChart({ chartData, filter }) {
  const daily = chartData?.daily;
  const monthly = chartData?.weekly;
  const yearly = chartData?.monthly;

  const [chart, setChart] = useState({
    options: {
      chart: {
        id: "apexchart-example",
      },
      xaxis: {
        categories: ["Mon", "Tue", "Wed", "Thur", "Fri", "Sat", "Sun"],
      },
      colors: ["#23A455"],
    },
    series: [
      {
        name: "series-1",
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
  });

  useEffect(() => {
    if (filter === "daily") {
      setChart({
        options: {
          chart: {
            id: "apexchart-example",
          },
          xaxis: {
            categories: daily?.label,
          },
          colors: ["#23A455"],
        },
        series: [
          {
            name: "purchases",
            data: daily?.sum_data,
          },
        ],
      });
    } else if (filter === "weekly") {
      setChart({
        options: {
          chart: {
            id: "apexchart-example",
          },
          xaxis: {
            categories: monthly?.label,
          },
          colors: ["#23A455"],
        },
        series: [
          {
            name: "purchases",
            data: monthly?.sum_data,
          },
        ],
      });
    } else if (filter === "yearly") {
      setChart({
        options: {
          chart: {
            id: "apexchart-example",
          },
          xaxis: {
            categories: yearly?.label,
          },
          colors: ["#23A455"],
        },
        series: [
          {
            name: "purchases",
            data: yearly?.sum_data,
          },
        ],
      });
    }
  }, [daily, filter, monthly, yearly]);

  return (
    <div className=" relative w-[97%] mx-auto">
      <div className="absolute top-0 bg-white z-20 w-[4rem] h-[1.4rem] right-0"></div>
      <div className="w-full lg:hidden">
        <Chart
          options={chart.options}
          series={chart.series}
          type="bar"
          width={320}
          height={300}
        />
      </div>
      <div className="w-full hidden lg:block">
        <Chart
          options={chart.options}
          series={chart.series}
          type="bar"
          width="100%"
          height={270}
        />
      </div>
    </div>
  );
}
