import React from "react";

export default function Integrations() {
  return (
    <div className="w-full p-2 flex h-full py-6 bg-white shadow-lg rounded-xl flex-col space-y-4 items-center">
      <p className="text-xs lg:text-base w-[95%] mx-auto text-start font-normal">
        Our API integration provides a simple and straightforward way to add
        gift cards to your store and start selling them to your customers. With
        just a few lines of code, you can connect and begin listing a wide
        variety of gift cards for sale and sell directly to your customers with
        100% white label.(you can sell gift cards on your website or app without
        any branding from our company.)
        <br />
        <br />
        To get started, you will need to activate your desired channel. You can
        choose to activate our API or our WordPress plugin. Once you have
        activated your desired channel, you will be able to obtain your secret
        and public key (a unique identifier that will allow you to access our
        API.)
        <br />
        <br />
        Once you have your key, you can start using our API to list gift cards
        for sale at your desired price.
        <br />
        <br />
        <b>
          Please Note: Copy your API keys after generating to keep it safe and
          always ensure you have funds in your wallet.
        </b>
      </p>
    </div>
  );
}
