import React, { useState, useContext } from "react";
import Background from "../UI/Background";
import Card from "../UI/Card";
import VerifyBVN from "../UI/VerifyBVN";
import WelcomeBack from "../UI/WelcomeBack";
import Notification from "../UI/Notification";
import ResendCodePopup from "../UI/ResendCodePopup";
import { resendSignupToken } from "../../util/http";
import LoadingBar from "react-top-loading-bar";
import { CartContext } from "../../util/context";

export default function Screen3({ setScreen }) {
  const [countDown, setCountdown] = useState(60);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState({
    status: "",
    text: "",
  });

  async function resendToken(e) {
    e.preventDefault();
    const email = localStorage.getItem("register_email");
    const data = {
      email: email,
      type: "VERIFY BVN",
    };
    try {
      setLoad(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      await resendSignupToken(data);
      // setCountdown(60);
      setProgress(80);
      setProgress(100);
      setLoad(false);
      setMessage({
        status: "success",
        text: "You will receive a call shortly",
      });
      setShow(true);
      setTimeout(() => setShow(false), 10000);
      setShow1(false);
    } catch (error) {
      setProgress(100);
      setLoad(false);
      setMessage({
        status: "error",
        text: error.response.data.message || "something went wrong",
      });
      setShow(true);
      setTimeout(() => setShow(false), 10000);
    }
  }
  return (
    <Background>
      <LoadingBar
        color="#17343D"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Card>
        <WelcomeBack />
        <div className="auth-container">
          <div className="flex w-full flex-col space-y-4">
            <div className="flex flex-col space-y-2">
              <h1 className="h1">Verify BVN Phone Number</h1>
              <p className="p">
                Kindly check your phone number associated to BVN for a six digit
                OTP.
              </p>
            </div>
            <VerifyBVN
              countDown={countDown}
              setCountdown={setCountdown}
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              setShow1={setShow}
              setScreen={setScreen}
              setShow2={setShow1}
              onClick={resendToken}
              load={load}
              progress={progress}
              setProgress={setProgress}
            />
          </div>
        </div>
      </Card>
      {show && <Notification text={message.text} status={message.status} />}
      {show1 && (
        <ResendCodePopup
          loading={loading}
          setLoading={setLoading}
          message={message}
          setMessage={setMessage}
          setShow={setShow}
          setShow1={setShow1}
        />
      )}
    </Background>
  );
}
