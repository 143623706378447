import React, { useContext } from "react";
import Success from "../../components/success/Success";
import { CartContext } from "../../util/context";
import { useNavigate } from "react-router-dom";
import { inactiveState } from "../../util/inactivity";

export default function SuccessPage() {
  const ctx = useContext(CartContext);
  const navigate = useNavigate();

  window.onload = function () {
    inactiveState(ctx, navigate);
  };

  return <Success />;
}
