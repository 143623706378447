import React, { Fragment } from "react";
import ProductItem from "./ProductItem";

export default function ProductList({ product, setShowPopup }) {
  return (
    <Fragment>
      <div className="w-full p-3 lg:p-6 flex overflow-scroll items-center gap-0 lg:gap-20 flex-wrap justify-center">
        {product && (
          <Fragment>
            {product.map((card, i) => (
              <ProductItem setShowPopup={setShowPopup} key={i} item={card} />
            ))}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}
