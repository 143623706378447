import React, { useState, useContext, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import LoadingBar from "react-top-loading-bar";

import Notification from "../UI/Notification";
import Card from "../UI/Card";
import WelcomeBack from "../UI/WelcomeBack";
import Background from "../UI/Background";
import PasswordValidation from "../UI/PasswordValidation";

import { signup } from "../../util/http";
import { CartContext } from "../../util/context";
import { AiFillEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { setWithExpiry } from "../../util/util";

export default function Register() {
  const navigate = useNavigate();

  const [password, setPassword] = useState("");

  const signupSchema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    phone_number: yup.number().required(),
    email: yup.string().email().required(),
    // password: yup.string().min(6).max(20).required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signupSchema),
  });
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(true);

  const [isNumber, setIsNumber] = useState(false);
  const [isUppercase, setIsUppercase] = useState(false);
  const [isSymbol, setIsSymbol] = useState(false);
  const [isEightChar, setIsEightChar] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState({
    status: "",
    text: "",
  });
  const ctx = useContext(CartContext);

  function toggleHandler() {
    if (type === "password") {
      setType("text");
      setIcon(false);
    } else {
      setType("password");
      setIcon(true);
    }
  }

  function passwordHandler(e) {
    setShowValidation(true);
    setPassword(e.target.value);
    // password length
    if (e.target.value.length >= 8) {
      setIsEightChar(true);
    } else {
      setIsEightChar(false);
    }

    if (e.target.value.match(/[0-9]/g)) {
      setIsNumber(true);
    } else {
      setIsNumber(false);
    }

    if (e.target.value.match(/[a-z]/g) && e.target.value.match(/[A-Z]/g)) {
      setIsUppercase(true);
    } else {
      setIsUppercase(false);
    }

    if (
      e.target.value.match(/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/)
    ) {
      setIsSymbol(true);
    } else {
      setIsSymbol(false);
    }
  }

  async function registerHandler(formData) {
    const data = { ...formData, password };

    const newData = {
      phone_number: String(data.phone_number),
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      password: data.password,
    };

    localStorage.setItem("register_email", data.email);

    try {
      setProgress(20);
      setProgress(40);
      setProgress(60);
      setLoading(true);
      const response = await signup(newData);
      setLoading(false);
      ctx.login(response.data.token);
      setWithExpiry("token", response.data.token, 86400000);
      setProgress(80);
      setProgress(100);
      setMessage({
        status: "success",
        text: "Sign up successful",
      });
      setShow(true);
      navigate("/almost-there");
      setTimeout(() => {
        setShow(false);
      }, 10000);
    } catch (error) {
      setProgress(100);
      setLoading(false);
      setMessage({
        status: "error",
        text: error.response.data.message || "something went wrong",
      });
      setShow(true);
      setTimeout(() => setShow(false), 10000);
    }
  }

  return (
    <Fragment>
      <LoadingBar
        color="#23A455"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Background>
        <Card>
          <WelcomeBack />
          <div className="auth-container">
            <div className="flex py-6 w-full flex-col space-y-6">
              <h1 className="h1  text-[#57585A]">create Account</h1>
              <form onSubmit={handleSubmit(registerHandler)} className="form">
                <div className="flex w-full items-center flex-wrap gap-4 justify-center">
                  <div className="input-container w-full lg:w-1/2">
                    <label className="label  text-[#57585A]">first name</label>
                    <input
                      {...register("first_name")}
                      type="text"
                      placeholder="Enter text here"
                      className="input"
                    />
                    <p className="error-text">
                      {errors.first_name ? errors.first_name.message : ""}
                    </p>
                  </div>
                  <div className="input-container w-full lg:w-1/2">
                    <label className="label  text-[#57585A]">last name</label>
                    <input
                      {...register("last_name")}
                      type="text"
                      placeholder="Enter text here"
                      className="input"
                    />
                    <p className="error-text">
                      {errors.last_name ? errors.last_name.message : ""}
                    </p>
                  </div>
                </div>
                <div className="flex w-full items-center flex-wrap gap-4 justify-center">
                  <div className="input-container w-full lg:w-1/2">
                    <label className="label  text-[#57585A]">
                      email address
                    </label>
                    <input
                      {...register("email")}
                      type="email"
                      placeholder="Johndoe@gmail.com"
                      className="input"
                    />
                    <p className="error-text">
                      {errors.email ? errors.email.message : ""}
                    </p>
                  </div>
                  <div className="input-container w-full lg:w-1/2">
                    <label className="label  text-[#57585A]">
                      phone number
                    </label>
                    <input
                      {...register("phone_number")}
                      type="tel"
                      placeholder="81 0000 0000"
                      className="input"
                    />
                    <p className="error-text">
                      {errors.phone_number
                        ? "Enter phone number in this format 08112345678"
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="input-container w-full">
                  <label className="label text-[#57585A]">password</label>
                  <div className="flex w-full bg-white items-center justify-center rounded-xl border-2 pr-2">
                    <input
                      onChange={passwordHandler}
                      type={type}
                      placeholder="Enter Password"
                      className="py-2 flex-1 px-4 focus:bg-white  outline-none rounded-xl"
                      onKeyUp={() => {
                        if (
                          isUppercase &&
                          isNumber &&
                          isSymbol &&
                          isEightChar
                        ) {
                          setFormIsValid(true);
                        } else {
                          setFormIsValid(false);
                        }
                      }}
                    />
                    <span
                      onClick={toggleHandler}
                      className="text-base cursor-pointer"
                    >
                      {icon ? <AiFillEye /> : <AiOutlineEyeInvisible />}
                    </span>
                  </div>
                </div>

                {showValidation && (
                  <PasswordValidation
                    isEightChar={isEightChar}
                    isNumber={isNumber}
                    isSymbol={isSymbol}
                    isUppercase={isUppercase}
                  />
                )}
                <div className="action">
                  <button disabled={!formIsValid} className="button">
                    {loading ? "loading..." : "sign up"}
                  </button>
                </div>
              </form>
              <p className="p text-base">
                already have an account?{" "}
                <Link className="font-bold text-[#23A455]" to="/login">
                  sign in
                </Link>
              </p>
            </div>
          </div>
        </Card>
        {show && <Notification text={message.text} status={message.status} />}
      </Background>
    </Fragment>
  );
}
