import React from "react";
import { Link } from "react-router-dom";
import { BsArrowLeft } from "react-icons/bs";

// import arrow from "../../images/arrow-left.png";

export default function ProductDetailHeader({ text, path }) {
  return (
    <div className="w-full bg-white  mt-[4.5rem] px-4 lg:px-[2rem] py-10 flex items-center justify-between">
      <Link
        className="flex w-[20px] items-center gap-2 capitalize font-bold text-black text-sm lg:text-[1.25rem] cursor-pointer"
        to={`/${path ? path : "product"}`}
      >
        <span className="text-base text-black font-bold">
          <BsArrowLeft />
        </span>
        back
      </Link>
      <p className="flex items-center gap-1 text-xs lg:text-[0.875rem] capitalize">
        {text ? `product > ${text}` : ""}
      </p>
    </div>
  );
}
