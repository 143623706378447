import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

export default function Virtual2() {
  const navigate = useNavigate();
  return (
    <div className="flex drop-shadow-xl w-[33%] h-[170px] p-4 flex-col gap-[10px] rounded-xl bg-white">
      <h1 className="text-[1.25rem] font-bold text-center text-[#120A00] capitalize">
        Virtual Account Details
      </h1>
      <p className="text-[#828282] text-center w-[90%] mx-auto leading-6 text-[0.75rem] font-normal">
        Please complete your onboarding process to generate your virtual account
      </p>

      <div className="w-[70%] mx-auto flex items-center justify-center">
        {/* !! TODO Change this later please */}
        <button
        //  style={{
        //     pointerEvents: "none",

        //  }}
          onClick={() => navigate("/onboarding")}
        // onClick={()=> toast.error('This service is currently unavailable!')}
          className="bg-[#23A455] animate-pulse hover:opacity-40 transition-all duration-300  hover:shadow-xl capitalize rounded-lg text-white text-base font-semibold cursor-pointer py-2 px-4"
        >
          get started
        </button>
      </div>
    </div>
  );
}
