import { createContext, useEffect, useState } from "react";
import { getToken, setWithExpiry } from "./util";

export const CartContext = createContext({
  items: [],
  getProductQuantity: () => {},
  addOneToCart: () => {},
  removeOneFromCart: () => {},
  deleteFromCart: () => {},
  getTotalCost: () => {},
  token: "",
  login: (token) => {},
  logout: () => {},
  isLoggedIn: "",
});

export default function CartProvider({ children }) {
  const cart = getToken("cart") || [];
  const initialToken = getToken("token");
  const [cartProduct, setCartProduct] = useState(cart);
  const [token, setToken] = useState(initialToken);

  useEffect(() => {
    setWithExpiry("cart", cartProduct, 86400000);
  }, [cartProduct]);

  const isLoggedIn = !!token;

  function login(token) {
    setToken(token);
  }
  function logout() {
    setToken(null);
    localStorage.removeItem("token");
  }

  function getProductQuantity(id) {
    const quantity = cartProduct?.find(
      (product) => product.id === id
    )?.quantity;

    if (!quantity || quantity === undefined) {
      return 0;
    }

    return quantity;
  }

  async function addOneToCart(item, recepient, qty, noIncrement) {
    const quantity = getProductQuantity(item.id);
    if (quantity === 0) {
      setCartProduct([
        ...cartProduct,
        {
          id: item.id,
          quantity: 1,
          name: item.name,
          price: item.price,
          image: item.image_path,
          recipients: recepient ? recepient : [],
          slug: item.slug,
        },
      ]);
    } else {
      setCartProduct(
        cartProduct.map((product) => {
          return product.id === item.id
            ? {
                ...product,
                quantity: noIncrement ? qty : qty + 1,
                recipients: recepient ? recepient : [],
              }
            : product;
        })
      );
    }
  }

  function deleteFromCart(id) {
    setCartProduct((cartProducts) =>
      cartProducts.filter((curProduct) => {
        return curProduct.id !== id;
      })
    );
  }

  function removeOneFromCart(id) {
    const quantity = getProductQuantity(id);
    if (quantity === 1) {
      deleteFromCart(id);
    } else {
      setCartProduct(
        cartProduct.map((product) =>
          product.id === id
            ? { ...product, quantity: product.quantity - 1 }
            : product
        )
      );
    }
  }

  async function getTotalCost() {
    let totalCost = 0;
    cartProduct.map((cartItem) => {
      return (totalCost += cartItem?.price * cartItem?.quantity);
    });
    return totalCost;
  }

  const value = {
    items: cartProduct,
    addOneToCart,
    removeOneFromCart,
    deleteFromCart,
    getProductQuantity,
    getTotalCost,
    token,
    login,
    logout,
    isLoggedIn,
  };

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}
