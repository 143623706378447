import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../UI/Card";
import OnboardingTitle from "../UI/OnboardingTitle";
import WelcomeBack from "../UI/WelcomeBack";
import Background from "../UI/Background";
import Notification from "../UI/Notification";
import LoadingBar from "react-top-loading-bar";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  validateBvnLength,
  validateNumeric,
  validatePhoneLength,
} from "../../util/Validator";
import { virtualAccount } from "../../util/http";
import { Fragment } from "react";
import { getToken } from "../../util/util";

const years = [
  1900, 1901, 1902, 1903, 1904, 1905, 1906, 1907, 1908, 1909, 1910, 1911, 1912,
  1913, 1914, 1915, 1916, 1917, 1918, 1919, 1920, 1921, 1922, 1923, 1924, 1925,
  1926, 1927, 1928, 1929, 1930, 1931, 1932, 1933, 1934, 1935, 1936, 1937, 1938,
  1939, 1940, 1941, 1942, 1943, 1944, 1945, 1946, 1947, 1948, 1949, 1950, 1951,
  1952, 1953, 1954, 1955, 1956, 1957, 1958, 1959, 1960, 1961, 1962, 1963, 1964,
  1965, 1966, 1967, 1968, 1969, 1970, 1971, 1972, 1973, 1974, 1975, 1976, 1977,
  1978, 1979, 1980, 1981, 1982, 1983, 1984, 1985, 1986, 1987, 1988, 1989, 1990,
  1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003,
  2004, 2005,
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const monthIndex = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const days = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];

export default function Screen1({ setScreen }) {
  const navigate = useNavigate();
  const [bvn, setBvn] = useState("");
  const [countryCode, setCountryCode] = useState("234");
  const [phone, setPhone] = useState("");
  const [showText, setShowText] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [showText1, setShowText1] = useState(false);
  const [validBvn, setValidBvn] = useState(false);
  const [length, setLength] = useState(false);
  const [length1, setLength1] = useState(false);
  const [num, setNum] = useState(false);
  const [num1, setNum1] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [progress, setProgress] = useState(0);

  const token = getToken("token");

  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    status: "",
    text: "",
  });

  const bankDetailSchema = yup.object().shape({
    day: yup.string().required(),
    month: yup.string().required(),
    year: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(bankDetailSchema),
  });

  useEffect(() => {
    if (errors.day || errors.month || errors.year || !validBvn || !validPhone) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }
  }, [
    errors.phone,
    errors.day,
    errors.month,
    errors.year,
    bvn,
    length,
    num,
    length1,
    phone,
    num1,
    validBvn,
    validPhone,
  ]);

  function bvnValidator(e) {
    const val = e.target.value;
    if (validateBvnLength(val)) {
      setBvn(val);
      setLength(true);
    } else {
      setLength(false);
    }

    if (validateNumeric(val)) {
      setBvn(val);
      setNum(true);
    } else {
      setBvn("");
      setNum(false);
    }

    if (length && num) {
      setValidBvn(true);
      setShowText(false);
    } else {
      setValidBvn(false);
      setShowText(true);
    }
  }

  function PhoneValidator(e) {
    const val = e.target.value;
    if (validatePhoneLength(val)) {
      setPhone(val);
      setLength1(true);
    } else {
      setLength1(false);
    }

    if (validateNumeric(val)) {
      setPhone(val);
      setNum1(true);
    } else {
      setPhone("");
      setNum1(false);
    }

    if (length1 && num1) {
      setValidPhone(true);
      setShowText1(false);
    } else {
      setValidPhone(false);
      setShowText1(true);
    }
  }

  async function submitHandler(input) {
    const data = {
      date_of_birth: `${input.year}-${input.month.padStart(
        2,
        0
      )}-${input.day.padStart(2, 0)}`,
      phone_number: phone,
      bvn,
    };

    localStorage.setItem("bvn-data", JSON.stringify(data));

    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      await virtualAccount(token, data);
      setProgress(80);
      setProgress(100);
      setLoading(false);
      setMessage({
        status: "success",
        text: "Virtual account successfully created",
      });
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 10000);
      setScreen(2);
    } catch (error) {
      setProgress(100);
      setLoading(false);
      setMessage({
        status: "error",
        text: error.response.data.message || "something went wrong",
      });
      setShow(true);
      setTimeout(() => setShow(false), 10000);
    }
  }

  return (
    <Background>
      <LoadingBar
        color="#17343D"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Card>
        <WelcomeBack />
        <div className="auth-container">
          <div className="flex w-full flex-col space-y-4">
            <div className="flex w-full flex-col space-y-2">
              <OnboardingTitle
                title="Let’s Create a Virtual Account"
                text="Almost there, Please complete "
              />
              <div className="flex w-full flex-col space-y-8">
                <form onSubmit={handleSubmit(submitHandler)} className="form1">
                  <div className="w-full flex flex-col space-y-2">
                    <h1 className="text-[1.3rem] font-medium capitalize text-[#3F434A]">
                      banking details
                    </h1>

                    <p className="text-sm font-normal">
                      We need your banking details to create a wallet and
                      virtual account for you.{" "}
                    </p>
                  </div>
                  <div className="input-container">
                    <label className="label">
                      Bank Verification Number (BVN)
                    </label>
                    <input
                      onChange={bvnValidator}
                      value={bvn}
                      maxLength="11"
                      type="text"
                      className="input"
                      placeholder="XXX XXX XXXX"
                    />
                    {showText && (
                      <p className="error-text">
                        {validBvn ? "" : "Enter 11 digit BVN"}
                      </p>
                    )}
                  </div>
                  <div className="input-container">
                    <label className="label">Phone Number</label>
                    <div className="flex items-center justify-center rounded-xl border-2">
                      <select
                        onChange={(e) => setCountryCode(e.target.value)}
                        className="bg-transparent border-r-2  py-2 outline-none"
                      >
                        <option className="" value="234">
                          +234
                        </option>
                      </select>
                      <input
                        onChange={PhoneValidator}
                        value={phone}
                        maxLength="11"
                        type="tel"
                        className=" py-2 flex-1 px-4   outline-none rounded-xl"
                        placeholder="0810 000 0000"
                      />
                    </div>
                    {showText1 && (
                      <p className="error-text">
                        {validPhone ? "" : "Enter 11 digits Phone Number"}
                      </p>
                    )}
                  </div>
                  <div className="flex w-full flex-col space-y-4">
                    <label className="label">date of birth</label>
                    <div className="flex w-full items-center space-x-4 justify-between">
                      <select
                        {...register("day")}
                        className="input bg-transparent w-[33%]"
                      >
                        <option className="" value="">
                          Date
                        </option>
                        <Fragment>
                          {days.map((day, i) => (
                            <option
                              className="capitalize"
                              value={day.day}
                              key={i}
                            >
                              {day}
                            </option>
                          ))}
                        </Fragment>
                        )
                      </select>
                      <select
                        {...register("month")}
                        className="input capitalize bg-transparent w-[33%]"
                      >
                        <option className="uppercase" value="">
                          Month
                        </option>
                        <Fragment>
                          {months.map((month, i) => (
                            <option
                              className="capitalize"
                              value={monthIndex[i]}
                              key={i}
                            >
                              {month}
                            </option>
                          ))}
                        </Fragment>
                        )
                      </select>
                      <select
                        {...register("year")}
                        className="input  bg-transparent w-[33%]"
                      >
                        <option className="" value="">
                          Year
                        </option>
                        <Fragment>
                          {years.map((year, i) => {
                            return (
                              <option value={year} key={i}>
                                {year}
                              </option>
                            );
                          })}
                        </Fragment>
                        )
                      </select>
                    </div>
                    {errors.day || errors.month || errors.year ? (
                      <p className="error-text">invalid date format</p>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="action">
                    <button
                      type="button"
                      onClick={() => navigate("/")}
                      className="hover:opacity-80  transition-all duration-300 bg-transparent text-[#23A455] font-bold text-[0.6rem] lg:text-base capitalize py-3 px-12 text-center"
                    >
                      skip
                    </button>
                    <button
                      disabled={!formIsValid}
                      className="bg-[#23A455]  hover:opacity-80 transition-all duration-300 hover:shadow-xl capitalize rounded-lg text-white text-base font-semibold cursor-pointer py-3 px-12"
                    >
                      {loading ? "loading..." : "next"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Card>
      {show && <Notification text={message.text} status={message.status} />}
    </Background>
  );
}
