import React, { useState } from "react";
import Card from "../UI/Card";
import VerifyPasswordReset from "../UI/VerifyPasswordReset";
import LoadingBar from "react-top-loading-bar";
import WelcomeBack from "../UI/WelcomeBack";
import Notification from "../UI/Notification";
import Background from "../UI/Background";
import ResendCodePopup from "../UI/ResendCodePopup";

export default function PasswordResetToken() {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState({
    status: "",
    text: "",
  });
  return (
    <Background>
      <LoadingBar
        color="#17343D"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Card>
        <WelcomeBack />
        <div className="auth-container">
          <div className="flex flex-col space-y-10">
            <div className="flex flex-col space-y-4">
              <h1 className="h1 text-[#57585A]">almost there</h1>
              <p className="p">
                Please enter the OTP sent to your email to continue
              </p>
            </div>

            <VerifyPasswordReset
              loading={loading}
              setLoading={setLoading}
              message={message}
              setMessage={setMessage}
              setShow1={setShow}
              setShow2={setShow1}
              progress={progress}
              setProgress={setProgress}
            />
          </div>
        </div>
      </Card>
      {show && <Notification text={message.text} status={message.status} />}

      {show1 && (
        <ResendCodePopup
          loading={loading}
          setLoading={setLoading}
          message={message}
          setMessage={setMessage}
          setShow={setShow}
          setShow1={setShow1}
          type="PASSWORD RESET"
        />
      )}
    </Background>
  );
}
