import React, { useState } from "react";
import PasswordPopup from "./PasswordPopup";

import { AiOutlineClose } from "react-icons/ai";
import { RiEyeCloseLine } from "react-icons/ri";

export default function Password({ setShow }) {
  const [type, setType] = useState("password");

  function toggleHandler() {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  }
  return (
    <PasswordPopup>
      <div className="w-full flex px-6 flex-col space-y-6">
        <div className="w-full  flex items-center justify-between">
          <h1 className="text-[#1E1D1B] text-xl font-bold text-start">
            API Integrations
          </h1>
          <span
            onClick={() => setShow(false)}
            className="text-xl text-black font-bold cursor-pointer"
          >
            <AiOutlineClose />
          </span>
        </div>
        <form className="w-full flex flex-col space-y-4">
          <div className="input-container">
            <label className="label">enter password</label>
            <div className="input relative flex items-center justify-between">
              <input
                type={type}
                placeholder="*****************"
                className="flex-1  text-base bg-transparent outline-none"
              />
              <span onClick={toggleHandler} className="cursor-pointer">
                <RiEyeCloseLine />
              </span>
            </div>
          </div>
          <div className="w-full flex items-end justify-end">
            <button className="bg-[#23A455]  hover:opacity-40 transition-all duration-300  hover:shadow-xl capitalize rounded-lg text-white text-base font-semibold cursor-pointer py-3 px-6">
              done
            </button>
          </div>
        </form>
      </div>
    </PasswordPopup>
  );
}
