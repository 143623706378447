import React from "react";

import PaymentInformation from "./PaymentInformation";
import PurchaseInformation from "./PurchaseInformation";
import PurchaseOverview from "./PurchaseOverview";

export default function OverviewContainer({
  setShow,
  orderDetail,
  resendCode,
  loading,
  setId,
}) {
  return (
    <div className="w-full flex bg-white flex-col">
      <div className="w-full  gap-8 p-6 flex flex-col lg:flex-row lg:items-center lg:justify-between">
        <PurchaseOverview order={orderDetail} />
        <PaymentInformation order={orderDetail} />
      </div>
      <PurchaseInformation
        setId={setId}
        loading={loading}
        resendCode={resendCode}
        order={orderDetail.order_items}
        setShow={setShow}
      />
    </div>
  );
}
