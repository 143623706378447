import React, { Fragment, useState, useEffect } from "react";

import illustration from "../../images/Illustration.png";
import copy from "../../images/copy.png";

import { CopyToClipboard } from "react-copy-to-clipboard";
import MobileWalletBalance from "./MobileWalletBalance";
import { useNavigate } from "react-router-dom";
import CheckoutPopup from "../UI/CheckoutPopup";
import LoadingBar from "react-top-loading-bar";
import {toast} from 'react-hot-toast'

export default function WalletBalance({ wallet }) {
  const [copied, setCopied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const detail = wallet?.account;

 

  const balance = new Intl.NumberFormat().format(wallet.balance);

  return (
    <Fragment>
      <div className="hidden lg:flex w-full bg-white rounded-xl drop-shadow-xl lg:w-[97%] mx-auto lg:flex-1 items-center justify-center lg:h-[168px] lg:px-6">
        <div className="w-full lg:flex items-center gap-8 justify-between">
          <div className="w-[33%] gap-8 flex items-center p-4 h-[168px]">
            <div className="w-[100px] bg-[#00AC45] flex items-center justify-center  rounded-3xl bg-opacity-10 h-[88px]">
              <div className="w-[100px]">
                <img src={illustration} alt="illustration" className="img" />
              </div>
            </div>
            <div className="w-full relative flex flex-col space-y-2">
              <p className="text-[#828282] text-base font-normal capitalize">
                total wallet balance
              </p>
              <h1 className="balance">₦{wallet.balance ? balance : 0}.00</h1>
              <div className="w-full">
                <button
                  onClick={() => setShowModal(true)}
                  className="bg-[#23A455]  hover:opacity-40 transition-all duration-300  hover:shadow-xl capitalize rounded-lg text-white text-base font-semibold cursor-pointer py-2 px-4"
                >
                  fund wallet
                </button>
              </div>
            </div>
          </div>
          {wallet.account ? (
            <div className="flex w-[28%] mr-6 h-[170px] p-4 flex-col space-y-4 ">
              <h1 className="text-[1.25rem] font-bold text-start text-[#120A00] capitalize">
                Virtual Account Details
              </h1>
              <div className="w-full flex flex-col space-y-2">
                <div className="flex items-center justify-between w-full">
                  <p className="dash-t">bank</p>
                  <p className="dash-t flex items-center space-x-4">
                    {detail.bank.name}{" "}
                    <CopyToClipboard
                      text={detail.bank.name}
                      onCopy={() => {
                        setCopied(true);
                        setTimeout(() => setCopied(false), 1000);
                      }}
                    >
                      <span className="cursor-pointer w-[15px] ml-2 text-green-600">
                        <img className="img" src={copy} alt="copy" />
                      </span>
                    </CopyToClipboard>
                  </p>
                </div>
                <div className="flex items-center justify-between w-full">
                  <p className="dash-t">account no</p>
                  <p className="dash-t relative flex items-center space-x-4">
                    {detail.account_number}
                    <CopyToClipboard
                      text={detail.account_number}
                      onCopy={() => {
                        setCopied(true);
                        setTimeout(() => setCopied(false), 1000);
                      }}
                    >
                      <span className="w-[15px]  cursor-pointer ml-2 text-green-600">
                        <img className="img" src={copy} alt="copy" />
                      </span>
                    </CopyToClipboard>
                    {copied && (
                      <p className="absolute top-0 bg-white p-1 left-0">
                        Copied!
                      </p>
                    )}
                  </p>
                </div>
                <div className="flex items-center justify-between w-full">
                  <p className="dash-t">account name</p>
                  <p className="dash-t flex items-center space-x-4">
                    {detail.account_name}
                    <CopyToClipboard
                      text={detail.account_name}
                      onCopy={() => {
                        setCopied(true);
                        setTimeout(() => setCopied(false), 1000);
                      }}
                    >
                      <span className="w-[15px] cursor-pointer ml-2 text-green-600">
                        <img className="img" src={copy} alt="copy" />
                      </span>
                    </CopyToClipboard>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex  w-[33%] h-[170px] p-4 flex-col space-y-1  bg-white">
              <h1 className="text-[1.25rem] font-bold text-center text-[#120A00] capitalize">
                Virtual Account Details
              </h1>
              <p className="text-[#828282] text-center w-[90%] mx-auto leading-6 text-[0.75rem] font-normal">
                Please complete your onboarding process to generate your virtual
                account
              </p>
              <div className="w-[70%] mx-auto flex items-center justify-center">
                <button
                // // TODO: Remove later
                // style={{
                //   pointerEvents: 'none'
                // }}
                  onClick={() => navigate("/onboarding")}

               // onClick={()=> toast.error('This service is currently unavailable!')}
                  className="bg-[#23A455] animate-pulse hover:opacity-40 transition-all duration-300  hover:shadow-xl capitalize rounded-lg text-white text-base font-semibold cursor-pointer py-2 px-4"
                >
                  get started
                </button>
              </div>
            </div>
           )}
        </div>
      </div>
      <MobileWalletBalance setShowModal={setShowModal} wallet={wallet} />
      {showModal && <CheckoutPopup setShow={setShowModal} />}
    </Fragment>
  );
}
