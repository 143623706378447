import React, { useEffect, useState } from "react";
import CartItem from "./CartItem";
import { getToken } from "../../util/util";

export default function CartList({
  carts,
  setShow,
  setType,
  allRecepients,
  setAllRecepients,
  setShowPopup,
  showPopup,
  user,
}) {
  const [recepients, setRecepients] = useState([]);

  const cart = getToken("cart") || [];

  useEffect(() => {
    const recepientArr = [];
    cart.map((item) => item.recipients.map((el) => recepientArr.push(el)));
    setRecepients(recepientArr);
  }, [cart, recepients]);

  function getRecepient(id) {
    return recepients.filter((el) => el.id === id);
  }

  return (
    <div className="w-full pb-2 flex flex-col space-y-2">
      {cart?.map((item, i) => {
        return (
          <CartItem
            cart={cart}
            allRecepients={allRecepients}
            setAllRecepients={setAllRecepients}
            setShowPopup={setShowPopup}
            showPopup={showPopup}
            key={i}
            setShow={setShow}
            setType={setType}
            item={item}
            recepient={getRecepient}
            user={user}
          />
        );
      })}
    </div>
  );
}
