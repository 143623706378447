import React, { Fragment, useState } from "react";
import Overlay from "./Overlay";
import { useNavigate } from "react-router-dom";
import Cleave from "cleave.js/react";

export default function CheckoutPopup({ setShow }) {
  const [val, setVal] = useState("");
  const navigate = useNavigate();

  function amountHandler(e) {
    e.preventDefault();

    localStorage.setItem("fundingAmount", val);
    navigate("/fund-wallet");
    setShow(false);
  }
  return (
    <Fragment>
      <Overlay closeModal={() => setShow(false)} />
      <div className="w-[90%] flex items-center justify-center lg:w-[30%] p-4 bg-white z-[60] rounded-lg shadow-lg absolute top-[25%] left-5 lg:left-[30%]">
        <div className="w-full p-4">
          <form
            onSubmit={amountHandler}
            className="w-full flex flex-col space-y-4"
          >
            <div className="input-container">
              <label className="label">enter amount</label>
              <Cleave
                placeholder="Enter funding amount"
                className="input"
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: "thousand",
                }}
                onChange={(e) => setVal(Number(e.target.rawValue))}
              />
            </div>
            <div className="action">
              <button
                type="button"
                onClick={() => setShow(false)}
                className="button"
              >
                cancel
              </button>
              <button className="button">Fund Wallet</button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
