import React, { Fragment, useContext, useEffect } from "react";
import Login from "../components/auth/Login";
import Dashboard from "../components/dashboard/Dashboard";
import { CartContext } from "../util/context";
import { useNavigate } from "react-router-dom";
import { inactiveState } from "../util/inactivity";

export default function DashboardPage({ setShow, show }) {
  const ctx = useContext(CartContext);
  const navigate = useNavigate();

  window.onload = function () {
    inactiveState(ctx, navigate);
  };

  useEffect(() => {
    if (!ctx.isLoggedIn) {
      navigate("/login");
    }
  }, [ctx]);
  return (
    <Fragment>
      <Dashboard show={show} setShow={setShow} />
    </Fragment>
  );
}
