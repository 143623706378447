import React from "react";

import logo from "../../images/Sosogames_LOGO-1.png";
import { useNavigate } from "react-router-dom";

export default function WelcomeBack() {
  const navigate = useNavigate();
  return (
    <div className="hidden rounded-l-2xl lg:flex w-[30%] h-full p-6 bg-[#17343D] flex-col items-center justify-center space-y-8">
      <div onClick={() => navigate("/")} className="w-[60%] mx-auto">
        <img src={logo} className="img" alt="logo" />
      </div>
      <div className="flex flex-col space-y-4">
        <h1 className="text-2xl lg:text-3xl font-bold text-white  text-center capitalize">
          welcome back
        </h1>
        <p className="p text-xs text-white  mx-auto">
          Enter and authenticate your personal details to log back into your
          account on the SOSO Game merchant account.
        </p>
      </div>
    </div>
  );
}
