import React, { Fragment, useState } from "react";
import Overlay from "../UI/Overlay";

import { AiOutlineClose } from "react-icons/ai";
import { BsCloudUpload } from "react-icons/bs";
import { updateProfile } from "../../util/http";
import { useContext } from "react";
import { CartContext } from "../../util/context";
import { getToken, setWithExpiry } from "../../util/util";
import { Preview } from "mrcandie-react-preview";

export default function EditUser({ setShow, user }) {
  const [logo, setLogo] = useState("");
  const [previewImg, setPreviewImg] = useState("");
  const [firstName, setfirstName] = useState(
    user?.first_name ? user.first_name : ""
  );
  const [lastName, setlastName] = useState(
    user?.last_name ? user.last_name : ""
  );
  const [phone, setPhone] = useState(
    user.phone_number ? user.phone_number : ""
  );
  const [countryCode, setCountryCode] = useState("234");
  const [loading, setLoading] = useState(false);

  const token = getToken("token");

  const ctx = useContext(CartContext);
  const formattedPhone = phone;

  async function submitHandler(e) {
    e.preventDefault();

    const data = {
      first_name: firstName,
      last_name: lastName,
      phone_number: `${phone}`,
      profile_photo: logo,
    };

    if (!data) {
      setShow(false);
    }

    try {
      setLoading(true);
      const response = await updateProfile(token, data);
      alert("Update successful");

      setLoading(false);
      setShow(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <Fragment>
      <Overlay closeModal={() => setShow(false)} />

      <div className="w-[90%] lg:w-[468px] p-8 absolute  flex flex-col space-y-6 left-4 lg:left-[30%] top-[7.5rem] bg-white z-[60] rounded-2xl shadow-lg">
        <div className="flex items-center justify-between">
          <div className="flex flex-col space-y-2">
            <h1 className="text-xl font-bold text-black capitalize">
              user Details
            </h1>
            <p className="text-base capitalize text-[#404040] font-normal">
              edit your profile details using the form below
            </p>
          </div>
          <span
            onClick={() => setShow(false)}
            className="text-xl text-black font-bold cursor-pointer"
          >
            <AiOutlineClose />
          </span>
        </div>
        <form
          onSubmit={submitHandler}
          className="w-full flex flex-col space-y-4"
        >
          {false && (
            <div className="input-container w-full">
              <label className="label">upload logo</label>
              <label
                for="file"
                className="label cursor-pointer p-4 flex flex-col items-center space-y-2 w-full border border-dotted rounded-lg"
              >
                <span className="text-2xl">
                  <BsCloudUpload />
                </span>
                <p>
                  Drag and Drop or{" "}
                  <span className="text-[#F9AC3B]">Browse</span> to upload
                </p>
              </label>
              <input
                onChange={(e) => {
                  setLogo(e.target.files[0]);
                  setPreviewImg(URL.createObjectURL(e.target.files[0]));
                }}
                type="file"
                id="file"
                className="hidden"
              />
            </div>
          )}
          {previewImg && (
            <div className="w-full flex items-center justify-start h-[50px] rounded-xl ">
              <Preview
                file={logo}
                outerStyle={{
                  marginLeft: "8px",
                  borderRadius: "8px",
                  border: "1px solid #ccc",
                  width: "60px",
                }}
                imgStyle={{
                  borderRadius: "8px",
                  height: "50px",
                  width: "100%",
                }}
              />
            </div>
          )}
          <div className="input-container">
            <label className="label">enter first Name</label>
            <input
              onChange={(e) => setfirstName(e.target.value)}
              value={firstName}
              type="text"
              placeholder="Enter your first name"
              className="input"
            />
          </div>
          <div className="input-container">
            <label className="label">enter last Name</label>
            <input
              onChange={(e) => setlastName(e.target.value)}
              value={lastName}
              type="text"
              placeholder="Enter your last name"
              className="input"
            />
          </div>
          <div className="input-container">
            <label className="label">Phone Number</label>
            <div className="flex items-center justify-center rounded-xl border-2">
              {false && (
                <select
                  onChange={(e) => setCountryCode(e.target.value)}
                  className="bg-transparent border-r-2  py-2 outline-none "
                >
                  <option className="text-lg" value="234">
                    +234
                  </option>
                </select>
              )}
              <input
                onChange={(e) => setPhone(e.target.value)}
                type="tel"
                className=" py-2 flex-1 px-4 rounded-xl  outline-none"
                placeholder="XXX XXX XXXX"
                maxLength={13}
                value={formattedPhone}
              />
            </div>
          </div>
          <div className="w-full mt-20">
            <div className="action">
              <button
                type="button"
                onClick={() => setShow(false)}
                className="bg-transparent text-[#23A455] font-bold text-sm lg:text-base capitalize py-3 px-6 lg:px-10"
              >
                cancel
              </button>
              <button className="bg-[#23A455] hover:opacity-40 transition-all duration-300  hover:shadow-xl capitalize rounded-lg text-white text-base font-semibold cursor-pointer py-3 px-6 lg:px-10">
                {loading ? "loading..." : "Save Update"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}
