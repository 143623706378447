import React, { useState, useEffect } from "react";

import { useNavigate, Link } from "react-router-dom";

import Card from "../UI/Card";
import OnboardingTitle from "../UI/OnboardingTitle";
import WelcomeBack from "../UI/WelcomeBack";
import Background from "../UI/Background";
import Notification from "../UI/Notification";

import { AiFillDelete } from "react-icons/ai";
import { BsCloudUpload } from "react-icons/bs";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { updateProfile } from "../../util/http";
import LoadingBar from "react-top-loading-bar";
import { getToken } from "../../util/util";

export default function Screen2({ setScreen }) {
  const [formIsValid, setFormIsValid] = useState(false);
  const [image, setImage] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewImg, setPreviewImg] = useState("");
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState({
    status: "",
    text: "",
  });

  const token = getToken("token");

  const companyDetailSchema = yup.object().shape({
    name: yup.string().required(),
    phone: yup.string().min(10).max(10).required(),
    email: yup.string().email().required(),
    address: yup.string().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(companyDetailSchema),
  });

  useEffect(() => {
    if (errors.name || errors.phone || errors.email || errors.address) {
      setFormIsValid(false);
    } else {
      setFormIsValid(true);
    }
  }, [errors.name, errors.phone, errors.email, errors.address]);

  const navigate = useNavigate();

  async function submitHandler(inputs) {
    const data = { ...inputs, image };
    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const response = await updateProfile(token, data);
      setProgress(80);
      setProgress(100);
      setLoading(false);
      setMessage({
        status: "success",
        text: response.message || "company details submitted successfully",
      });
      setShow(true);
      navigate("/");
      setTimeout(() => setShow(false), 5000);
    } catch (error) {
      setProgress(100);
      setLoading(false);
      setShow(true);
      setMessage({
        status: "error",
        text: error.response.data.message || "something went wrong",
      });
      setTimeout(() => setShow(false), 2500);
    }
  }

  return (
    <Background>
      <LoadingBar
        color="#17343D"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Card>
        <WelcomeBack />
        <div className="auth-container">
          <div className="flex w-full flex-col space-y-2 py-2">
            <OnboardingTitle
              title="final step"
              text="almost there, please complete"
            />
            <form
              onSubmit={handleSubmit(submitHandler)}
              className="flex w-full lg:w-[70%] p-4 mx-auto flex-col space-y-4"
            >
              <div className="w-full flex items-center justify-between">
                <h1 className="text-xl font-bold capitalize text-[#3F434A]">
                  company details
                </h1>
                <Link
                  to="/"
                  onClick={() => setScreen(1)}
                  className="btn"
                  type="button"
                >
                  skip
                </Link>
              </div>
              <div className="w-full flex space-x-4 items-center justify-between">
                <div className="input-container w-1/2">
                  <label className="label">company name</label>
                  <input
                    {...register("name")}
                    type="text"
                    className="input w-full"
                    placeholder="Enter text here"
                  />
                  <p className="error-text">
                    {errors.name ? errors.name.message : ""}
                  </p>
                </div>
                <div className="input-container w-1/2">
                  <label className="label">address</label>
                  <input
                    {...register("address")}
                    type="text"
                    className="input w-full"
                    placeholder="Enter text here"
                  />
                  <p className="error-text">
                    {errors.address ? errors.address.message : ""}
                  </p>
                </div>
              </div>

              <div className="input-container">
                <label className="label">company email address</label>
                <input
                  {...register("email")}
                  type="email"
                  className="input"
                  placeholder="Enter text here"
                />
                <p className="error-text">
                  {errors.email ? errors.email.message : ""}
                </p>
              </div>

              <div className="input-container">
                <label className="label">company Phone Number</label>
                <div className="flex items-center justify-center rounded-xl border-2">
                  <select className="bg-transparent border-r-2  py-2 outline-none ">
                    <option className="text-lg" value="234">
                      +234
                    </option>
                  </select>
                  <input
                    {...register("phone")}
                    type="tel"
                    maxLength="10"
                    className=" py-2 flex-1 px-4  rounded-xl outline-none"
                    placeholder="XXX XXX XXXX"
                  />
                </div>
                <p className="error-text">
                  {errors.phone ? errors.phone.message : ""}
                </p>
              </div>
              <div className="input-container">
                <label className="label">upload logo</label>
                <label
                  for="file"
                  className="label cursor-pointer p-2 flex flex-col items-center space-y-2 w-full border border-dotted rounded-lg"
                >
                  <span className="text-2xl">
                    <BsCloudUpload />
                  </span>
                  <p>
                    Drag and Drop or{" "}
                    <span className="text-[#F9AC3B]">Browse</span> to upload
                  </p>
                </label>
                <input
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                  }}
                  type="file"
                  id="file"
                  className="hidden"
                />
              </div>

              {previewImg && (
                <div className="w-full flex items-center justify-start h-[50px] rounded-xl border-2">
                  <div
                    onClick={() => {
                      setImage("");
                      setPreviewImg("");
                    }}
                    className="w-[50px] cursor-pointer hover:opacity-80 transition-all duration-300 flex items-center justify-center h-full bg-[#E8E9EB]"
                  >
                    <span className="text-xl text-red-600">
                      <AiFillDelete />
                    </span>
                  </div>

                  <div className="ml-2 w-[30px]">
                    <img className="img h-[50px]" alt="logo" src={previewImg} />
                  </div>
                </div>
              )}
              <div className="action">
                <button
                  onClick={() => setScreen(1)}
                  className="btn"
                  type="button"
                >
                  previous
                </button>
                <button
                  disabled={!formIsValid}
                  className="bg-[#23A455]  hover:opacity-80 transition-all duration-300 hover:shadow-xl capitalize rounded-lg text-white text-base font-semibold cursor-pointer py-2 px-4"
                >
                  {loading ? "loading..." : "proceed"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Card>
      {show && <Notification text={message.text} status={message.status} />}
    </Background>
  );
}
