import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { resendSignupToken, verifySignup } from "../../util/http";
import ReusableVerify from "./ReusableVerify";
import { setWithExpiry } from "../../util/util";

export default function VerifyPasswordReset({
  message,
  setMessage,
  setShow1,
  setShow2,
  setLoading,
  loading,
  progress,
  setProgress,
}) {
  const [countDown, setCountdown] = useState(60);
  const navigate = useNavigate();

  const [valid, setValid] = useState(true);

  const timerId = useRef();

  useEffect(() => {
    const timer = setInterval(() => {
      if (countDown === 0) {
        setValid(true);
        clearInterval(timer);
        return;
      }
      setValid(false);
      setCountdown((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [setCountdown, countDown]);

  const [num1, setNum1] = useState("");
  const [num2, setNum2] = useState("");
  const [num3, setNum3] = useState("");
  const [num4, setNum4] = useState("");
  const [num5, setNum5] = useState("");
  const [num6, setNum6] = useState("");

  const [show, setShow] = useState(false);

  function fn(fro, to) {
    const inputField = document.getElementById(fro);
    const len = inputField.value.length;
    const max = inputField.getAttribute("maxlength");
    if (+len === +max) {
      return document.getElementById(to).focus();
    }
  }

  function deleteInput(e, fro, to) {
    if (e.key === "Backspace") {
      document.getElementById(fro).value = "";
      document.getElementById(fro).blur();
      if (fro === "inp6") {
        setNum6("");
      }
      return document.getElementById(to).focus();
    }
  }

  async function verificationHandler(e) {
    e.preventDefault();
    const token = `${num1}${num2}${num3}${num4}${num5}${num6}`;
    const data = {
      token: +token,
      type: "PASSWORD RESET",
    };
    try {
      setLoading(true);
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const response = await verifySignup(data);
      setProgress(80);
      setProgress(100);
      setWithExpiry("token", response.data.token, 86400000);

      setLoading(false);
      setMessage({
        status: "success",
        text: response.message || "Proceed to enter your new password",
      });
      setShow(false);
      setShow1(true);
      setTimeout(() => setShow1(false), 5000);
      navigate("/password-reset");
    } catch (error) {
      setProgress(100);
      setLoading(false);
      setShow(true);
      setMessage({
        status: "error",
        text: error.response.data.message || "something went wrong",
      });
      setShow1(true);
      setTimeout(() => setShow1(false), 2500);
    }
  }

  async function resendToken(e) {
    e.preventDefault();
    const email = localStorage.getItem("reset-email");
    const data = {
      email: email,
      type: "PASSWORD RESET",
    };
    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      await resendSignupToken(data);
      // setCountdown(60);
      setProgress(80);
      setProgress(100);
      setLoading(false);
      // localStorage.removeItem("reset-email");
      setMessage({
        status: "success",
        text: "Your verification token has been sent to your email",
      });
      setShow1(true);
      setTimeout(() => setShow1(false), 5000);
      setShow(false);
    } catch (error) {
      setLoading(false);
      setProgress(100);
      setMessage({
        status: "error",
        text: error.response.data.message || "something went wrong",
      });
      setShow(true);
      setShow1(true);
      setTimeout(() => setShow1(false), 5000);
    }
  }

  return (
    <ReusableVerify
      message={message}
      setShow={setShow}
      setShow1={setShow1}
      loading={loading}
      onSubmit={verificationHandler}
      focusInput={fn}
      deleteInput={deleteInput}
      setCountdown={setCountdown}
      countDown={countDown}
      resendToken={resendToken}
      num1={num1}
      num2={num2}
      num3={num3}
      num4={num4}
      num5={num5}
      num6={num6}
      setNum1={setNum1}
      setNum2={setNum2}
      setNum3={setNum3}
      setNum4={setNum4}
      setNum5={setNum5}
      setNum6={setNum6}
      timerId={timerId}
      valid={valid}
    />
  );
}
