import React, { Fragment, useEffect, useState } from "react";
import Header from "../dashboard/Header";
import ProductDetailHeader from "../ProductDetail/ProductDetailHeader";
import OverviewContainer from "./OverviewContainer";
import OverviewPopup from "./OverviewPopup";
import { useNavigate, useParams } from "react-router-dom";
import { getOrderDetail, resendCode } from "../../util/http";
import Notification from "../UI/Notification";
import Spinner from "../UI/Spinner";

import LoadingBar from "react-top-loading-bar";
import { getToken } from "../../util/util";

export default function OverviewCenter({ setModal }) {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [orderDetail, setOrderDetail] = useState("");
  const [id, setId] = useState("");
  const [message, setMessage] = useState({
    status: "",
    text: "",
  });

  const [loading, setLoading] = useState(false);
  const token = getToken("token");
  const params = useParams();
  const orderId = params.id;

  useEffect(() => {
    async function fetchData() {
      try {
        setProgress(20);
        setProgress(40);
        setProgress(60);
        const response = await getOrderDetail(token, orderId);
        setProgress(80);
        setProgress(100);
        setOrderDetail(response.data);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, [token, orderId]);

  async function resendProductCode() {
    try {
      setProgress(20);
      setProgress(40);
      setProgress(60);
      await resendCode(token, orderId);
      setProgress(80);
      setProgress(100);
      setMessage({
        status: "success",
        text: "Product code successfully resent",
      });
      setShow1(true);
      setLoading(false);
      setTimeout(() => {
        setShow1(false);
        navigate("/");
      }, 3000);
    } catch (error) {
      setProgress(100);
      // console.log(error);
      setMessage({
        status: "error",
        text: error.response.data.message || "something went wrong",
      });
      setShow1(true);
      setTimeout(() => setShow1(false), 5000);
    }
  }

  return (
    <Fragment>
      <LoadingBar
        color="#23A455"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="w-full lg:flex-1 pb-10 h-full lg:h-[160vh] relative">
        <Header setModal={setModal} title="orders" />
        <div className="w-full bg-white h-full relative">
          <ProductDetailHeader path="orders" text="orders" />
          <OverviewContainer
            setId={setId}
            loading={loading}
            resendCode={resendProductCode}
            orderDetail={orderDetail}
            setShow={setShow}
          />
          {show && (
            <OverviewPopup
              id={id}
              orderDetail={orderDetail}
              setShow={setShow}
            />
          )}
        </div>
      </div>
      {show1 && <Notification text={message.text} status={message.status} />}
      {loading && <Spinner />}
    </Fragment>
  );
}
