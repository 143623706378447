import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Card from "../UI/Card";
import WelcomeBack from "../UI/WelcomeBack";
import Notification from "../UI/Notification";
import LoadingBar from "react-top-loading-bar";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Background from "../UI/Background";
import { forgotPassword, resendSignupToken } from "../../util/http";

export default function ForgotPassword() {
  const forgotPasswordSchema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
  });

  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState({
    status: "",
    text: "",
  });
  const [loading, setLoading] = useState(false);
  const [valid, setValid] = useState("");

  useEffect(() => {
    if (errors.email) {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [errors.email]);

  async function resetHandler(data) {
    localStorage.setItem("reset-email", data.email);
    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      await forgotPassword(data);
      setProgress(80);
      setProgress(100);
      setLoading(false);
      setMessage({
        status: "success",
        text: `we have sent a password reset token to your mail`,
      });

      setShow(true);
      setTimeout(() => setShow(false), 5000);
      navigate("/reset-password");
    } catch (error) {
      setLoading(false);
      setProgress(100);
      setMessage({
        status: "error",
        text: error.response.data.message || "something went wrong",
      });
      setShow(true);
      setTimeout(() => setShow(false), 5000);
    }
  }

  return (
    <Background>
      <LoadingBar
        color="#23A455"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Card>
        <WelcomeBack />
        <div className="auth-container">
          <div className="flex w-full flex-col space-y-4">
            <div className="flex flex-col space-y-2">
              <h1 className="h1">reset password</h1>
              <p className="p">
                Please enter the email address registered on your account.
              </p>
            </div>
            <form onSubmit={handleSubmit(resetHandler)} className="form">
              <div className="input-container w-full">
                <label className="label">email address</label>
                <input
                  {...register("email")}
                  type="email"
                  className="input w-full"
                  placeholder="johndoe@gmail.com"
                />
                <p className="error-text">
                  {errors.email ? errors.email.message : ""}
                </p>
              </div>
              <div className="action">
                <button disabled={!valid} className="button">
                  {loading ? "loading..." : "reset password"}
                </button>
              </div>
            </form>
            <div className="w-full mt-6">
              <p className="p text-base">
                remember your login details?{" "}
                <Link className="font-bold text-[#23A455]" to="/login">
                  sign in
                </Link>
              </p>
            </div>
          </div>
        </div>
      </Card>
      {show && <Notification text={message.text} status={message.status} />}
    </Background>
  );
}
