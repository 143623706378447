import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import CartProvider from "./util/context";
import {Toaster} from 'react-hot-toast'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <CartProvider>
      <App />
      <Toaster />
    </CartProvider>
  </BrowserRouter>
);
