import React, { Fragment, useState } from "react";
import Overlay from "../UI/Overlay";
import { AiOutlineClose } from "react-icons/ai";
import copy from "../../images/Vector.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { resendProductCode } from "../../util/http";
import Notification from "../UI/Notification";
import { useNavigate } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { getToken } from "../../util/util";

export default function OverviewPopup({ setShow, orderDetail, id }) {
  const [copied, setCopied] = useState(false);
  const token = getToken("token");

  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState({
    status: "",
    text: "",
  });

  const order = orderDetail?.order_items?.find((el) => el?.id === id);

  async function resendCode(id) {
    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      await resendProductCode(token, id);
      setLoading(false);
      setProgress(80);
      setProgress(100);
      setShowPopup(true);
      setMessage({
        status: "success",
        text: "Product code successfully resent",
      });
      setTimeout(() => {
        setShowPopup(false);
        navigate("/");
      }, 3000);
    } catch (error) {
      setProgress(100);
      setLoading(false);
      setMessage({
        status: "error",
        text: error.response.data.message || "something went wrong",
      });
      setShowPopup(true);
      setTimeout(() => setShowPopup(false), 5000);
    }
  }

  return (
    <Fragment>
      <LoadingBar
        color="#23A455"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <Overlay closeModal={() => setShow(false)} />
      <div className="w-full h-[300px]">
        <div className="w-[95%] lg:w-[50rem] p-8 absolute  flex flex-col space-y-6 left-2 lg:left-[20%] top-60 bg-white z-[60] rounded-2xl shadow-lg">
          <div className="flex items-center justify-between">
            <h1 className="text-lg lg:text-2xl font-bold text-black capitalize">
              Purchase Details
            </h1>
            <span
              onClick={() => setShow(false)}
              className="text-lg lg:text-xl text-black font-bold cursor-pointer"
            >
              <AiOutlineClose />
            </span>
          </div>
          <div className="w-full flex flex-col space-y-2">
            {order?.product_code_recipients?.map((item) => {
              return (
                <Fragment>
                  <div className="w-full  flex items-center justify-center ">
                    <div className="flex items-center bg-white gap-8">
                      <div className="lg:w-[30%] ">
                        <h1 className="text-[0.4rem] lg:text-[0.875rem] text-start font-bold text-[#1D1F1F]">
                          {order?.product?.name}
                        </h1>
                      </div>
                      <h1 className="text-[#121A26] text-start lg:w-[15%] text-[0.4rem] lg:text-[0.9375]">
                        {item.code}
                      </h1>
                      <div className="lg:flex-1 ">
                        <p className="text-[0.4rem] lg:text-sm text-start text-[#333]">
                          {item.contact}
                        </p>
                      </div>
                    </div>
                    <div className="w-[5%] relative flex">
                      <CopyToClipboard
                        text={item.contact}
                        onCopy={() => {
                          setCopied(true);
                          setTimeout(() => setCopied(false), 1000);
                        }}
                      >
                        <span className="w-[15px] cursor-pointer ml-2 text-green-600">
                          <img className="img" src={copy} alt="copy" />
                        </span>
                      </CopyToClipboard>
                    </div>
                    <button
                      onClick={() => resendCode(item.id)}
                      className="text-center text-[0.5rem] lg:text-base capitalize text-[#00AC45] font-bold w-[20%]"
                    >
                      resend code
                    </button>
                  </div>
                  {order?.product_code_recipients?.length > 1 && <hr />}
                </Fragment>
              );
            })}
            {copied && (
              <p className="absolute top-10 bg-white p-1 right-0">Copied!</p>
            )}
          </div>
        </div>
      </div>
      {showPopup && (
        <Notification text={message.text} status={message.status} />
      )}
    </Fragment>
  );
}
