import React from "react";
import { BiError } from "react-icons/bi";
import { AiFillCheckCircle } from "react-icons/ai";

export default function Notification({ text, status, setShow }) {
  return (
    <div
      className={`${
        status === "success" ? "bg-[#23A455]" : "bg-red-600"
      } p-4 absolute top-0 right-0  lg:h-[3rem] flex items-center justify-center w-full z-[100]`}
    >
      <div className="w-full flex items-center gap-4 justify-center">
        {status === "success" ? (
          <span className="text-2xl text-white">
            <AiFillCheckCircle />
          </span>
        ) : (
          <span className="text-2xl text-white">
            <BiError />
          </span>
        )}
        <p className="text-sm lg:text-base font-bold text-center text-white capitalize">
          {text}
        </p>
      </div>
    </div>
  );
}
