import React from "react";
import Header from "../dashboard/Header";
import SuccessContent from "./SuccessContent";

export default function SuccessCenter({ setModal }) {
  return (
    <div className="w-full lg:flex-1 h-full lg:h-[160vh] relative">
      <Header setModal={setModal} title="checkout" />
      <SuccessContent />
    </div>
  );
}
