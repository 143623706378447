import React, { Fragment, useState } from "react";

import unknown from "../../images/unknown.jpeg";

import { AiOutlineQuestion } from "react-icons/ai";
import EditProfile from "./EditProfile";

export default function CompanyDetails({ user }) {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  return (
    <Fragment>
      <div className="w-full lg:w-[90%] py-4 mx-auto  mt-10 px-6 lg:px-20">
        <div className="w-full flex flex-col space-y-2">
          <div className="w-full lg:w-[50%] flex items-center justify-between">
            <h1 className="text-[#3F434A] font-bold text-base lg:text-lg">
              Company Details
            </h1>
            <button onClick={() => setShow(true)} className="btn1">
              edit
            </button>{" "}
            <span
              onMouseOver={() => setShow1(true)}
              onMouseLeave={() => setShow1(false)}
              className="p-1 cursor-pointer flex items-center relative rounded-full bg-[#FDB933]"
            >
              <AiOutlineQuestion />
              {show1 && (
                <p className="bg-[#FEF7EC] text-xs top-8 cursor-pointer p-2 text-center w-[208px] right-[-20%] absolute font-normal">
                  This details will be used when sending out codes to your customer(s)
                </p>
              )}
            </span>
          </div>
          <div className="w-full mt-6">
            <div className="w-[60%]  flex gap-8 items-center">
              <div className="w-[100px] lg:w-[100px] h-[300px] p-4">
                <img className="img h-[68px]" alt="logo" src={unknown} />
              </div>
              <div className="w-full h-[300px] p-4  flex flex-col space-y-4">
                <div className="flex flex-col w-full">
                  <h1 className="text-base font-normal text-[#57585A]">
                    Company Name
                  </h1>
                  <p className="text-sm lg:text-base font-normal text-[#1E1D1B]">
                    {user.company_name?.slice(0, 20)}
                  </p>
                </div>

                <div className="flex flex-col w-full">
                  <h1 className="text-base font-normal text-[#57585A]">
                    Address
                  </h1>
                  <p className="text-sm lg:text-base font-normal text-[#1E1D1B]">
                    {user.company_address?.slice(0, 20)}
                  </p>
                </div>

                <div className="flex flex-col w-full">
                  <h1 className="text-base font-normal text-[#57585A]">
                    Company Email Address
                  </h1>
                  <p className="text-sm lg:text-base font-normal text-[#1E1D1B]">
                    {user.company_email?.slice(0, 20)}
                  </p>
                </div>

                <div className="flex flex-col w-full">
                  <h1 className="text-base font-normal text-[#57585A]">
                    Company Phone Number
                  </h1>
                  <p className="text-sm lg:text-base font-normal text-[#1E1D1B]">
                    {user.company_phone_number}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && <EditProfile user={user} setShow={setShow} />}
    </Fragment>
  );
}
