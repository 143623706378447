import React, { Fragment, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { IoMdArrowDropdown } from "react-icons/io";
import cart from "../../images/shopping-cart.png";
import bell from "../../images/Bell + Notification.png";

import userImg from "../../images/unknown.jpeg";
import { AiOutlineMenu } from "react-icons/ai";
import ProfilePopup from "../UI/ProfilePopup";
import EditUser from "../profile/EditUser";
import { CartContext } from "../../util/context";
import { getProfile } from "../../util/http";
import { getToken } from "../../util/util";

export default function Header({ title, setModal }) {
  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState("");
  const [quantity, setQuantity] = useState(0);

  const ctx = useContext(CartContext);

  useEffect(() => {
    const quantityArr = ctx?.items?.map((item) => item?.quantity);
    const totalQuantity = quantityArr?.reduce((acc, sum) => acc + sum, 0);
    setQuantity(totalQuantity);
  }, [ctx.items]);

  useEffect(() => {
    async function fetchData() {
      const token = getToken("token");
      try {
        const response = await getProfile(token);
        setData(response?.data);
      } catch (error) {}
    }
    fetchData();
  }, []);

  return (
    <Fragment>
      <header className="h-[72px] px-4 lg:px-0 drop-shadow-md z-40 border-[#e8e9eb] border-b-[1px] bg-white flex items-center justify-between w-full absolute top-0 left-0">
        <span
          onClick={() => setModal(true)}
          className="lg:hidden dashboard-icon"
        >
          <AiOutlineMenu />
        </span>
        <div className="hidden lg:block ml-6 cursor-poiner">
          <h1 className="text-xl font-bold text-start text-[#3F434A] capitalize cursor-pointer">
            {title ? title : "dashboard"}
          </h1>
        </div>
        <div className="flex lg:mr-6 items-center">
          <div className="hidden lg:flex pr-6 items-start space-x-8">
            <Link to="/product/cart" className="dashboard-icon relative">
              {quantity > 0 && (
                <span className="w-[1.5rem] h-[1.5rem] flex items-center justify-center text-sm rounded-full bg-green-400  text-white absolute top-[-60%] right-[-60%]">
                  {quantity}
                </span>
              )}
              <img className="img" src={cart} alt="cart" />
            </Link>
            <span className="w-[24px] h-[24px] dashboard-icon">
              <img className="img" src={bell} alt="bell" />
            </span>
          </div>
          <div className="flex lg:border-l-2 lg:pl-6 items-center gap-6">
            <span className="lg:hidden block w-[24px] h-[24px] dashboard-icon">
              <img className="img" src={bell} alt="bell" />
            </span>
            <Link to="/profile" className="w-[30px] lg:w-[50px] rounded-full">
              <img
                className="img h-[30px] lg:h-[50px] rounded-full"
                alt="user"
                src={userImg}
              />
            </Link>
            <Link
              to="/product/cart"
              className="lg:hidden dashboard-icon relative"
            >
              {quantity > 0 && (
                <span className="w-[1.5rem] h-[1.5rem] flex items-center justify-center text-sm rounded-full bg-green-400  text-white absolute top-[-60%] right-[-60%]">
                  {quantity}
                </span>
              )}
              <img className="img" src={cart} alt="cart" />
            </Link>

            <div
              onClick={() => setShow1(true)}
              className="hidden lg:flex cursor-pointer items-center space-x-2"
            >
              <h1 className="text-base capitalize font-normal text-start">
                {data?.first_name?.slice(0, 10)}
              </h1>
              <span className="text-base">
                <IoMdArrowDropdown />
              </span>
            </div>
          </div>
        </div>
      </header>
      {show1 && <ProfilePopup setShow={setShow} setShow1={setShow1} />}
      {show && <EditUser setShow={setShow} user={data} />}
    </Fragment>
  );
}
