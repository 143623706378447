import React from "react";
import IntegrationContent from "./IntegrationContent";
import IntegrationTitle from "./IntegrationTitle";

export default function APIINTEGRATION({ setShow, setShow1, wordPress, api }) {
  return (
    <div className="w-full flex flex-col bg-white space-y-6 h-[581px] rounded-xl p-4 shadow-lg">
      <h1 className="text-base font-bold text-[#1E1D1B] text-start capitalize">
        Channels
      </h1>
      <p className="text-sm font-normal text-[#1E1D1B] text-start capitalize">
        Kindly select your desired channel to connect with Sosogames API.
      </p>
      <IntegrationTitle />
      <IntegrationContent
        api={api}
        wordPress={wordPress}
        setShow1={setShow1}
        setShow={setShow}
      />
    </div>
  );
}
