import React from "react";
import ProductButtons from "./ProductButtons";

export default function ProductDescription({
  name,
  price,
  setShow,
  id,
  setType,
  description,
  product,
}) {
  return (
    <div className="h-[500px]  p-4  flex-1 flex flex-col space-y-6">
      <h1 className="text-base lg:text-[1.5rem] font-bold text-start text-[#595F69]">
        {name}
      </h1>
      <div className="flex flex-col space-y-4 w-full">
        <p className="text-[0.875rem] font-semibold text-[#595F69] text-start capitalize">
          Voucher Redeem Instructions:
        </p>
        <p className="product-text">
          1. Open a PlayStation Network (PSN) account (or use your existing PSN
          account).
        </p>
        <p className="product-text">
          2. Select the PlayStation®Store icon on the PS4 or PS5 system home
          screen.
        </p>
        <p className="product-text">
          3. On PlayStation®Store, select ‘Redeem Codes’ at the bottom of the
          menu.
        </p>
        <p className="product-text">4. Enter the code.</p>
        <p className="product-text">
          5. Once the code has been entered correctly, select ‘Continue’ on the
          dialog box.
        </p>
        <p className="product-text">
          6. Select ‘Confirm’ to accept the Trial Code Terms of Service.
        </p>
        <p className="product-text">
          7. Select ‘Continue’ to complete code redemption.
        </p>
      </div>

      <ProductButtons
        setType={setType}
        item={product}
        setShow={setShow}
        price={price}
        product={product}
      />
    </div>
  );
}
