import React from "react";
import ProfileDetails from "./ProfileDetails";

export default function ProfileContainer({ user }) {
  return (
    <div className="w-full mt-[4.5rem]">
      <div className="w-full p-6 flex flex-col space-y-4">
        <ProfileDetails user={user} />
      </div>
    </div>
  );
}
