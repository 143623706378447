import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { updateProfile } from "../../util/http";
import Background from "../UI/Background";
import Card from "../UI/Card";
import Notification from "../UI/Notification";
import PasswordValidation from "../UI/PasswordValidation";
import WelcomeBack from "../UI/WelcomeBack";
import { RxEyeClosed } from "react-icons/rx";

import { getToken } from "../../util/util";

export default function ResetPassword() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const token = getToken("token");

  const [message, setMessage] = useState({
    status: "",
    text: "",
  });
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const [formIsValid, setFormIsValid] = useState(false);

  const [isNumber, setIsNumber] = useState(false);
  const [isUppercase, setIsUppercase] = useState(false);
  const [isSymbol, setIsSymbol] = useState(false);
  const [isEightChar, setIsEightChar] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [type, setType] = useState("password");

  function toggleHandler() {
    if (type === "password") {
      setType("text");
    } else {
      setType("password");
    }
  }

  function passwordHandler(e) {
    setShowValidation(true);
    setPassword(e.target.value);
    // password length
    if (e.target.value.length >= 8) {
      setIsEightChar(true);
      // setFormIsValid(true);
    } else {
      setIsEightChar(false);
      // setFormIsValid(false);
    }

    if (e.target.value.match(/[0-9]/g)) {
      setIsNumber(true);
      // setFormIsValid(true);
    } else {
      setIsNumber(false);
      // setFormIsValid(false);
    }

    if (e.target.value.match(/[a-z]/g) && e.target.value.match(/[A-Z]/g)) {
      setIsUppercase(true);
      // setFormIsValid(true);
    } else {
      setIsUppercase(false);
      // setFormIsValid(false);
    }

    if (
      e.target.value.match(/[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/)
    ) {
      setIsSymbol(true);
      // setFormIsValid(true);
    } else {
      setIsSymbol(false);
      // setFormIsValid(false);
    }
  }

  async function resetHandler(e) {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage({
        status: "error",
        text: "passwords do not match",
      });
      setShow(true);
      setTimeout(() => setShow(false), 5000);
      return;
    }
    try {
      setLoading(true);
      await updateProfile(token, { password });

      setLoading(false);
      setMessage({
        status: "error",
        text: "password reset successfully...Proceed to login",
      });
      setShow(true);
      navigate("/login");
      setTimeout(() => setShow(false), 5000);
    } catch (error) {
      // console.log(error);
      setLoading(false);
      setMessage({
        status: "error",
        text: error.response.data.message || "something went wrong",
      });
      setShow(true);
      setTimeout(() => setShow(false), 5000);
    }
  }

  return (
    <Background>
      <Card>
        <WelcomeBack />
        <div className="auth-container">
          <div className="flex w-full flex-col space-y-4">
            <div className="flex flex-col space-y-2">
              <h1 className="h1">reset password</h1>
              <p className="p">Please enter your new password.</p>
            </div>
            <form onSubmit={resetHandler} className="form">
              <div className="input-container">
                <label className="label">new password</label>
                <div className="flex w-full bg-white items-center justify-center rounded-xl border-2 pr-2">
                  <input
                    onChange={passwordHandler}
                    className="py-2 flex-1 px-4 focus:bg-white  outline-none rounded-xl"
                    type={type}
                    placeholder="Enter new password"
                    onKeyUp={() => {
                      if (isUppercase && isNumber && isSymbol && isEightChar) {
                        setFormIsValid(true);
                      } else {
                        setFormIsValid(false);
                      }
                    }}
                  />
                  <span
                    onClick={toggleHandler}
                    className="text-lg cursor-pointer text-black"
                  >
                    <RxEyeClosed />
                  </span>
                </div>
              </div>
              <div className="input-container">
                <label className="label">confirm new password</label>
                <div className="flex w-full bg-white items-center justify-center rounded-xl border-2 pr-2">
                  <input
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    className="py-2 flex-1 px-4 focus:bg-white  outline-none rounded-xl"
                    type={type}
                    placeholder="confirm new password"
                  />
                  <span
                    onClick={toggleHandler}
                    className="text-lg cursor-pointer text-black"
                  >
                    <RxEyeClosed />
                  </span>
                </div>
              </div>

              {showValidation && (
                <PasswordValidation
                  isEightChar={isEightChar}
                  isNumber={isNumber}
                  isSymbol={isSymbol}
                  isUppercase={isUppercase}
                />
              )}
              <div className="action">
                <button disabled={!formIsValid} className="button">
                  {loading ? "loading..." : "submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Card>
      {show && <Notification text={message.text} status={message.status} />}
    </Background>
  );
}
