export function inactiveState(ctx, navigate) {
  let time;
  document.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onmousedown = resetTimer;
  document.ontouchstart = resetTimer;
  document.onclick = resetTimer;
  document.onkeydown = resetTimer;
  document.addEventListener("scroll", resetTimer, true);

  function logout() {
    navigate("/login");
    window.location.reload();
    ctx.logout();
  }
  function resetTimer() {
    clearTimeout(time);
    time = setTimeout(logout, 600000);
  }
}
