import React from "react";

export default function OnboardingTitle({ title, text }) {
  return (
    <div className="flex w-full flex-col space-y-2">
      <h1 className="h1">{title}</h1>
      <p className="p">{text}</p>
    </div>
  );
}
