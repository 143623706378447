import React from "react";
import { Link } from "react-router-dom";

export default function EmptyProduct({ image, text, btnText, path }) {
  return (
    <div className="w-full h-full bg-white flex items-center justify-center">
      <div className="flex w-[80%] h-[25.75rem] items-center justify-center bg-white p-6 rounded-lg shadow-lg lg:w-[40%] text-center mx-auto flex-col space-y-8">
        <div className="w-[3.8rem] lg:w-[7.875rem] mx-auto">
          <img
            className="img w-[3.8rem] lg:h-[5.2rem]"
            alt="success"
            src={image}
          />
        </div>
        <div className="w-full flex flex-col space-y-4">
          <h1 className="text-sm lg:text-xl text-[#1D1F1F]  font-bold text-center">
            {text}
          </h1>
          <Link to={path ? path : "/product"} className="button">
            {btnText}
          </Link>
        </div>
      </div>
    </div>
  );
}
