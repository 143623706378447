import React, { useState } from "react";
import DashboardSide from "../dashboard/DashboardSide";
import CartCenter from "./CartCenter";
import MobileMenu from "../dashboard/MobileMenu";
import { useContext } from "react";
import { CartContext } from "../../util/context";
import Spinner from "../UI/Spinner";
import { useEffect } from "react";

export default function Cart() {
  const [modal, setModal] = useState(false);
  const ctx = useContext(CartContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="h-full bg-white flex items-center justify-center">
      <DashboardSide />
      {modal && <MobileMenu setModal={setModal} />}
      <CartCenter setModal={setModal} />
    </div>
  );
}
