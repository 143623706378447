import React from "react";
import Header from "../dashboard/Header";
import ProfileContainer from "./ProfileContainer";
import ToggleProfile from "./ToggleProfile";
import EditUser from "./EditUser";

export default function ProfileCenter({ setModal, user, show, setShow }) {
  return (
    <div className="w-full pb-10 lg:flex-1 h-full lg:h-[160vh] relative">
      <Header setModal={setModal} title="profile" />
      <div className="w-full h-full bg-white relative">
        <ProfileContainer user={user} />
        <ToggleProfile user={user} />
        {show && <EditUser setShow={setShow} user={user} />}
      </div>
    </div>
  );
}
