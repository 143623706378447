import React, { useState } from "react";
import DashboardSide from "../dashboard/DashboardSide";
import ProductCenter from "./ProductCenter";
import MobileMenu from "../dashboard/MobileMenu";

export default function Product() {
  const [modal, setModal] = useState(false);
  return (
    <div className="h-full bg-[#f6f6f6] flex items-center justify-center">
      <DashboardSide />
      {modal && <MobileMenu setModal={setModal} />}
      <ProductCenter setModal={setModal} />
    </div>
  );
}
