import React, { Fragment } from "react";
import logo from "../../images/Sosogames_LOGO-1.png";
import Overlay from "./Overlay";
import { FaSpinner } from "react-icons/fa";
import classes from "./spinner.module.css";

export default function Spinner() {
  return (
    <Fragment>
      <Overlay />
      <div className={classes.spinner}>
        <span className="">
          <FaSpinner />
        </span>
      </div>
    </Fragment>
  );
}
