import React from "react";

export default function Empty({ text, image }) {
  return (
    <div className="w-full h-[300px] flex items-center   flex-col space-y-4">
      <div className="w-[100px] lg:w-[150px]">
        <img className="img" src={image} alt="empty" />
      </div>
      <h1 className="text-sm lg:text-base text-[#3F434A] font-bold text-center">
        {text}
      </h1>
    </div>
  );
}
