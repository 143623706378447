import React, { Fragment, useContext, useEffect, useState } from "react";
import Overlay from "../UI/Overlay";

import { AiOutlineClose } from "react-icons/ai";
import { CartContext } from "../../util/context";
import { validateNumeric, validatePhoneLength } from "../../util/Validator";

export default function Recepient({
  setShow,
  id,
  setAllRecepients,
  cartQty,
  item,
  user
}) {
  const [validBtn, setValidBtn] = useState(true);

  const [channel, setChannel] = useState("EMAIL");
  const [contact, setContact] = useState("");
  const [num, setNum] = useState(1);
  const [type, setType] = useState("email");
  const [recepient, setRecepient] = useState([]);
  const [length1, setLength1] = useState(false);
  const [num1, setNum1] = useState(false);
  const [validPhone, setValidPhone] = useState(false);
  const [showText1, setShowText1] = useState(false);

  useEffect(() => {
    if (channel === "SMS") {
      setType("tel");
    } else if (channel === "WHATSAPP") {
      setType("tel");
    } else {
      setType("email");
    }
  }, [channel]);

  const ctx = useContext(CartContext);
  const quantity = ctx.getProductQuantity(id);

  const filteredRecepient = recepient.filter((el) => el.id === id);
  const recQty = filteredRecepient.map((el) => el.num);
  const totalQty = recQty.reduce((acc, sum) => acc + sum, 0);

  useEffect(() => {
    if (+totalQty >= +quantity) {
      setValidBtn(false);
    } else {
      setValidBtn(true);
    }
  }, [quantity, totalQty]);

  function PhoneValidator(e) {
    if (channel === "SMS" || channel === "WHATSAPP") {
      const val = e.target.value;
      if (validatePhoneLength(val)) {
        setContact(val);
        setLength1(true);
      } else {
        setLength1(false);
      }

      if (validateNumeric(val)) {
        setContact(val);
        setNum1(true);
      } else {
        setContact("");
        setNum1(false);
      }

      if (length1 && num1) {
        setValidPhone(true);
        setShowText1(false);
      } else {
        setValidPhone(false);
        setShowText1(true);
      }
    } else {
      setContact(e.target.value);
    }
  }

  function recepientHandler() {
    if (!contact) {
      alert("recepient contact cannot be empty");
      return;
    }
    const recArr = recepient.map((el) => el.num);
    const totalNum = recArr.reduce((acc, sum) => acc + sum, 0);

    if (+totalNum + +num > +quantity) {
      alert("Recepient(s) cannot be more than quantity in cart");
      return;
    }

    const recepientData = {
      channel,
      contact,
      num: +num,
      id,
    };
    setRecepient((data) => [...data, recepientData]);
    setContact("");
    setNum(1);
    setChannel("EMAIL");
    setType("email");
  }

  let newRecepient = [];
  function mapData(arrayData) {
    arrayData.forEach((item, index) => {
      for (let i = 1; i <= item.num; i++) {
        newRecepient.push(item);
      }
    });
  }
  mapData(recepient);
  // const formattedRecepient = newRecepient.map((el, i) => {
  //   return {
  //     channel: el.channel,
  //     contact: el.contact,
  //     id: el.id,
  //   };
  // });

  const userRecepient = [
    {
      channel: "EMAIL",
      contact: user?.email,
      id,
      num: quantity - newRecepient.length,
    },
  ];

  // if (quantity < newRecepient.length) {
  //   userRecepient.forEach((item, index) => {
  //     for (let i = 1; i <= quantity - newRecepient.length; i++) {
  //       newRecepient.push(item);
  //     }
  //   });
  // }

  function saveRecepient() {
    if (+quantity > newRecepient.length) {
      const addedRecepient = [];
      userRecepient.forEach((item, index) => {
        for (let i = 1; i <= +quantity - newRecepient.length; i++) {
          addedRecepient.push(item);
        }
      });
      const finalRecepient = [...newRecepient, ...addedRecepient];

      ctx.addOneToCart(item, finalRecepient, cartQty, true);
      setAllRecepients(finalRecepient);
      setShow(false);
      return;
    }
    ctx.addOneToCart(item, newRecepient, cartQty, true);
    setAllRecepients(newRecepient);
    setShow(false);
  }

  function deleteHandler(i) {
    recepient.splice(i, 1);
  }

  return (
    <Fragment>
      <Overlay />
      <div className="w-full h-full overflow-scroll">
        <div className="w-[90%] lg:w-[60%] p-8 absolute  flex flex-col space-y-6 left-5 lg:left-60 top-60 bg-white z-[60] rounded-2xl shadow-lg">
          <div className="flex items-center justify-between">
            <h1 className="text-lg lg:text-2xl font-bold text-black capitalize">
              gift card delivery
            </h1>
            <span
              onClick={() => setShow(false)}
              className="text-lg lg:text-xl text-black font-bold cursor-pointer"
            >
              <AiOutlineClose />
            </span>
          </div>
          <div className="w-full flex items-end justify-end">
            <p className="text-sm font-bold">Qty Remaining: {quantity}</p>
          </div>

          {recepient.length > 0 && (
            <div className="w-full flex flex-col gap-4">
              <h1 className="text-xl text-start font-semibold capitalize">
                Recepients
              </h1>
              <hr />
              {recepient.map((item, i) => (
                <div className="w-full border-b-2 p-1 gap-4 lg:gap-0 flex items-center">
                  <p className="w-[200px] label">{item.channel}</p>
                  <p className="flex-1 text-base font-medium text-[#333]">
                    {item.contact}
                  </p>
                  <p className="w-[50px] font-bold text-base">{item.num}</p>
                  <span
                    onClick={() => deleteHandler(i)}
                    className="text-lg lg:text-lg text-red-600 font-bold cursor-pointer"
                  >
                    <AiOutlineClose />
                  </span>
                </div>
              ))}
            </div>
          )}
          <div className="w-full mt-10">
            <div className="w-full flex flex-col gap-8">
              <form className="w-full flex flex-wrap items-center gap-4">
                <div className="input-container w-[45%] lg:w-[150px]">
                  <label className="label">select channel</label>
                  <select
                    onChange={(e) => {
                      setChannel(e.target.value);
                      setContact("");
                    }}
                    className="input"
                  >
                    <option value="EMAIL">Email</option>
                    <option value="SMS">SMS</option>
                    {false && <option value="WHATSAPP">WhatsApp</option>}
                  </select>
                </div>
                <div className="input-container w-[45%] lg:flex-1">
                  <label className="label">Enter recepient</label>
                  <input
                    // onChange={(e) => setContact(e.target.value)}
                    onChange={PhoneValidator}
                    value={contact}
                    type={type}
                    className="input"
                    maxLength={channel === "EMAIL" ? 100 : 11}
                    placeholder={`Enter ${
                      channel === "EMAIL" ? "Email" : "Phone"
                    }`}
                  />
                </div>

                <div className="input-container w-[70%] lg:w-[100px]">
                  <label className="label">quantity</label>
                  <input
                    onChange={(e) => setNum(e.target.value)}
                    value={num}
                    type="number"
                    className="input"
                    min={1}
                  />
                </div>
                <div className="w-[20%] lg:w-[100px] mt-6">
                  <div className="w-full flex items-center justify-center ">
                    <button
                      disabled={!validBtn}
                      type="button"
                      onClick={recepientHandler}
                      className="btn"
                    >
                      add
                    </button>
                  </div>
                </div>
              </form>
              {showText1 && (
                <p className="error-text">
                  {validPhone ? "" : "Enter 10 digits Phone Number"}
                </p>
              )}

              <div className="w-full flex items-end justify-end rounded-lg">
                <div className="w-[50%] lg:w-[31%] flex rounded-lg">
                  <button
                    onClick={saveRecepient}
                    className="bg-[#23A455] flex-1 hover:opacity-40 transition-all duration-300  hover:shadow-xl capitalize rounded-lg text-white text-base font-semibold cursor-pointer py-2 lg:py-2 px-3 lg:px-6"
                  >
                    save recepient
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
