import React, { Fragment } from "react";

import empty from "../../images/Business deal-cuate (1) 1.png";
import Empty from "../UI/Empty";

export default function PurchaseItem({
  setShow,
  order,
  resendCode,
  loading,
  setId,
}) {
  const contact = order?.find((item) => item).product_code_recipients[0]
    ?.contact;

  return (
    <Fragment>
      {order?.length > 0 ? (
        <div className="w-full mt-10 h-full overflow-scroll flex flex-col space-y-2">
          {order.map((item, i) => {
            const recepients = item.product_code_recipients[0];

            return (
              <Fragment key={i}>
                <div className="flex w-full space-x-4 items-center justify-center">
                  <h1 className="order-item-text w-[10%]">{item.id}</h1>
                  <h1 className="order-item-text w-[10%] text-center">
                    {item.quantity}
                  </h1>
                  <h1
                    onClick={() => {
                      setId(item?.id);
                      setShow(true);
                    }}
                    className="order-item-text cursor-pointer w-[25%] text-center font-extrabold hover:bg-[#eee] hover:p-2 hover:rounded-lg hover:opacity-80 transition-all duration-300"
                  >
                    {recepients.code}
                  </h1>
                  <h1 className="order-text w-[10%] font-bold text-center">
                    ₦{new Intl.NumberFormat().format(item.price)}.00
                  </h1>
                  <h1 className="lg:block hidden order-text w-[10%] font-bold text-center">
                    ₦{new Intl.NumberFormat().format(item.amount)}.00
                  </h1>
                  <h1 className="text-[0.5rem] lg:text-base    text-[#5D5F5F] w-[30%] lg:w-[20%] font-bold text-center">
                    {contact}
                  </h1>
                  <div className="order-item-text w-[15%] cursor-pointer py-2 rounded-md">
                    <h1
                      onClick={() => {
                        setId(item?.id);
                        setShow(true);
                      }}
                      className="text-center text-[#00AC45] font-bold"
                    >
                      view
                    </h1>
                  </div>
                </div>
                <hr />
              </Fragment>
            );
          })}
        </div>
      ) : (
        <div className="w-full h-[90vh] flex items-center justify-center">
          <Empty image={empty} text="no purchase to display" />
        </div>
      )}
    </Fragment>
  );
}
