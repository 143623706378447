import React, { useState } from "react";
import Screen1 from "./Screen1";
import Screen2 from "./Screen2";
import Screen3 from "./Screen3";

export default function Onboarding() {
  const [screen, setScreen] = useState(1);

  let displayScreen;

  if (screen === 1) {
    displayScreen = <Screen1 setScreen={setScreen} />;
  } else if (screen === 2) {
    displayScreen = <Screen3 setScreen={setScreen} />;
  } else if (screen === 3) {
    displayScreen = <Screen2 setScreen={setScreen} />;
  }

  return <div className="">{displayScreen}</div>;
}
