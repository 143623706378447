import React, { Fragment } from "react";
import Overlay from "../UI/Overlay";

export default function IntegrationPopup({ children }) {
  return (
    <Fragment>
      <Overlay />
      <div className="w-[90%] flex items-center justify-center lg:w-[60%] p-4 h-[400px] bg-white z-[60] rounded-lg shadow-lg absolute top-[15%] left-5 lg:left-60">
        {children}
      </div>
    </Fragment>
  );
}
