import React from "react";
import { FiSearch } from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
import { AiOutlineMenu } from "react-icons/ai";
import filter from "../../images/Filter.png";
import { getProduct, searchProduct } from "../../util/http";
import { getToken } from "../../util/util";

export default function Filter({ setProduct }) {
  const token = getToken("token");
  async function searchHandler(e) {
    const product = await searchProduct(
      token,
      e.target.value.trim()?.toLowerCase()
    );
    setProduct(product?.data);
  }

  return (
    <div className="w-[95%] mx-auto lg:w-full p-6 mt-24 bg-white  flex items-center space-x-4">
      <div className="w-[70%] input flex items-center">
        <span className="w-[1rem]">
          <FiSearch />
        </span>

        <input
          className=" py-1 w-full px-4  outline-none"
          type="search"
          placeholder="Enter product name to search"
          onChange={searchHandler}
        />
        {false && (
          <span className="w-[1rem] cursor-pointer">
            <img className="img" src={filter} alt="filter" />
          </span>
        )}
      </div>
      {false && (
        <div className="w-[10%] flex items-center space-x-0">
          <span className="text-xl border-r-2 pr-4 cursor-pointer">
            <AiOutlineMenu />
          </span>
          <span className="text-xl text-[#23A455] pl-4 cursor-pointer">
            <RxDashboard />
          </span>
        </div>
      )}
    </div>
  );
}
