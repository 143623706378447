import React, { Fragment, useEffect, useState } from "react";
import TransactionItem from "./TransactionItem";
import TransactionTitle from "./TransactionTitle";
import WalletFilter from "./WalletFilter";
import { getToken } from "../../util/util";

export default function WalletTransaction({ wallet }) {
  const [totaltransaction, setTotaltransaction] = useState(0);
  const token = getToken("token");

  useEffect(() => {
    setTotaltransaction(wallet.data?.length);
  }, [wallet]);

  const [currentPage, setCurrentPage] = useState(1);
  const [transactionPerPage, settransactionPerPage] = useState(10);

  const lasttransactionIndex = currentPage * transactionPerPage;
  const firsttransactionIndex = lasttransactionIndex - transactionPerPage;

  const transactionData = wallet.data?.slice(
    firsttransactionIndex,
    lasttransactionIndex
  );

  return (
    <div className="w-[97%] bg-white  drop-shadow-xl flex h-[800px] flex-col gap-2 lg:gap-4 p-6 rounded-xl mx-auto  mt-8">
      <h1 className="text-base lg:text-xl font-bold capitalize text-[#3F434A]">
        recent transactions
      </h1>
      <TransactionTitle />
      {wallet.data && (
        <Fragment>
          <TransactionItem data={transactionData} />
          <WalletFilter
            totaltransaction={totaltransaction}
            transactionPerPage={transactionPerPage}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            data={wallet.data}
          />
        </Fragment>
      )}
    </div>
  );
}
