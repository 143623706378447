import React, { Fragment } from "react";
import logo from "../../images/Sosogames_LOGO-2.png";
import DashboardMenu from "./DashboardMenu";
import Overlay from "../UI/Overlay";

import { AiOutlineClose } from "react-icons/ai";

export default function MobileMenu({ setModal, setShowEdit }) {
  return (
    <Fragment>
      <Overlay closeModal={() => setModal(false)} />
      <div className="lg:hidden absolute top-0 bg-white z-[100] left-0 h-[100vh] w-[80%] shadow-lg">
        <span
          onClick={() => setModal(false)}
          className="text-2xl font-bold text-red-600 absolute right-4 top-6 cursor-pointer"
        >
          <AiOutlineClose />
        </span>
        <div className="mt-10 flex items-center justify-center">
          <div className="w-[60%] ">
            <img className="img" alt="logo" src={logo} />
          </div>
        </div>
        <DashboardMenu setModal={setModal} setShowEdit={setShowEdit} />
      </div>
    </Fragment>
  );
}
