import React, { useState, useEffect, useRef } from "react";
import { verifySignup } from "../../util/http";
import ReusableVerify from "./ReusableVerify";
import { getProfile } from "../../util/http";
import { useNavigate } from "react-router-dom";
import { setWithExpiry } from "../../util/util";

export default function VerifyBVN({
  setScreen,
  setMessage,
  setLoading,
  loading,
  countDown,
  setCountdown,
  setShow2,
  setShow1,
  setShow,
  onClick,
  load,
  setProgress,
}) {
  const [valid, setValid] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      if (countDown === 0) {
        setValid(true);
        clearInterval(timer);
        return;
      }
      setValid(false);
      setCountdown((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [setCountdown, countDown]);

  const [num1, setNum1] = useState("");
  const [num2, setNum2] = useState("");
  const [num3, setNum3] = useState("");
  const [num4, setNum4] = useState("");
  const [num5, setNum5] = useState("");
  const [num6, setNum6] = useState("");

  function fn(fro, to) {
    const inputField = document.getElementById(fro);
    const len = inputField.value.length;
    const max = inputField.getAttribute("maxlength");
    if (+len === +max) {
      return document.getElementById(to).focus();
    }
  }

  function deleteInput(e, fro, to) {
    if (e.key === "Backspace") {
      document.getElementById(fro).value = "";
      document.getElementById(fro).blur();
      if (fro === "inp6") {
        setNum6("");
      }
      return document.getElementById(to).focus();
    }
  }

  async function verificationHandler(e) {
    e.preventDefault();
    const token = `${num1}${num2}${num3}${num4}${num5}${num6}`;
    const data = {
      token: +token,
      type: "VERIFY BVN",
    };
    try {
      setLoading(true);
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const response = await verifySignup(data);
      const user = await getProfile(response.data.token);

      setProgress(80);
      setProgress(100);
      setWithExpiry("token", response.data.token, 900000);
      setLoading(false);
      setMessage({
        status: "success",
        text: "Code sent to your phone number",
      });
      setShow1(true);
      setTimeout(() => setShow1(false), 5000);
      if (user?.company_name || user?.company_email || user?.company_address) {
        navigate("/");
      } else {
        setScreen(3);
      }
    } catch (error) {
      setProgress(100);
      setLoading(false);
      setShow(true);
      setMessage({
        status: "error",
        text: error.response.data.message || "something went wrong",
      });
      setShow1(true);
      setTimeout(() => setShow1(false), 2500);
    }
  }

  return (
    <ReusableVerify
      onSubmit={verificationHandler}
      focusInput={fn}
      deleteInput={deleteInput}
      setScreen={setScreen}
      loading={loading}
      load={load}
      countDown={countDown}
      setCountdown={setCountdown}
      setShow1={setShow1}
      setShow2={setShow2}
      resendToken={onClick}
      num1={num1}
      num2={num2}
      num3={num3}
      num4={num4}
      num5={num5}
      num6={num6}
      setNum1={setNum1}
      setNum2={setNum2}
      setNum3={setNum3}
      setNum4={setNum4}
      setNum5={setNum5}
      setNum6={setNum6}
      // timerId={timerId}
      valid={valid}
      showVoice={true}
      voiceAuth={onClick}
    />
  );
}
