import React, { Fragment, useState, useContext } from "react";
import { carts } from "../../util/data";
import Header from "../dashboard/Header";
import ProductDetailHeader from "../ProductDetail/ProductDetailHeader";
import EmptyProduct from "../UI/EmptyProduct";
import CartContainer from "./CartContainer";

import cart from "../../images/shopping-cart 1.png";
import { CartContext } from "../../util/context";
import Popup from "../UI/Popup";
import Spinner from "../UI/Spinner";

export default function CartCenter({ setModal }) {
  const ctx = useContext(CartContext);
  const [show, setShow] = useState(false);
  const [type, setType] = useState("");

  return (
    <Fragment>
      <div className="w-full lg:flex-1 h-full pb-10 lg:h-[160vh] relative">
        <Header title="cart" setModal={setModal} />
        <>
          {ctx?.items ? (
            <>
              {ctx.items?.length > 0 ? (
                <div className="w-full bg-white  relative">
                  <ProductDetailHeader text="cart" />
                  <CartContainer
                    setType={setType}
                    setShow={setShow}
                    carts={ctx.items}
                  />
                  {show && <Popup text={type} setShow={setShow} />}
                </div>
              ) : (
                <>
                  {ctx.items && (
                    <div className="w-full h-[90vh] lg:h-[70%] flex items-center justify-center">
                      <EmptyProduct
                        image={cart}
                        text="Your shopping cart is empty"
                        btnText="Continue Shopping"
                      />
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <Spinner />
          )}
        </>
      </div>
    </Fragment>
  );
}
