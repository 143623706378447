import React, { useState, useEffect, useRef } from "react";
import { validateNumber } from "../../util/Validator";
import { Link } from "react-router-dom";
import { virtualAccount } from "../../util/http";
import { getToken } from "../../util/util";

export default function ReusableVerify({
  loading,
  countDown,
  setCountdown,
  resendToken,
  load,
  onSubmit,
  focusInput,
  deleteInput,
  setNum1,
  setNum2,
  setNum3,
  setNum4,
  setNum5,
  setNum6,
  num1,
  num2,
  num3,
  num4,
  num5,
  num6,
  timerId,
  valid,
  showVoice = false,
  voiceAuth,
  onClick,
}) {
  const minute = Math.floor(countDown / 60);
  let seconds = Math.floor(countDown % 60);
  const token = getToken("token");

  const [formIsValid, setFormIsValid] = useState(false);
  const [show, setShow] = useState(false);

  const [error1, setError1] = useState(true);
  const [error2, setError2] = useState(true);
  const [error3, setError3] = useState(true);
  const [error4, setError4] = useState(true);
  const [error5, setError5] = useState(true);
  const [error6, setError6] = useState(true);

  async function submitHandler(input) {
    const data = JSON.parse(localStorage.getItem("bvn-data"));

    try {
      await virtualAccount(token, data);
      alert("Code successfully resent");
    } catch (error) {
      alert(error?.response?.data?.message);
    }
  }

  useEffect(() => {
    if (!error1 && !error2 && !error3 && !error4 && !error5 && !error6) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [
    error1,
    error2,
    error3,
    error4,
    error5,
    error6,
    num1,
    num2,
    num3,
    num4,
    num5,
    num6,
  ]);

  return (
    <div className="flex flex-col space-y-10">
      <form onSubmit={onSubmit} className="flex flex-col space-y-6">
        <div className="flex w-[90%] mx-auto items-center justify-center gap-2 lg:gap-6">
          <input
            onChange={(e) => {
              if (validateNumber(e.target.value)) {
                setNum1(e.target.value);
                setError1(false);
              } else {
                setError1(true);
                setNum1("");
              }
            }}
            value={num1}
            type="text"
            onKeyUp={focusInput.bind(this, "inp1", "inp2")}
            onKeyDown={(e) => deleteInput(e, "inp1", "inp6")}
            id="inp1"
            maxLength="1"
            className={`small-input ${error1 ? "border-red-500" : ""}`}
          />
          <input
            onChange={(e) => {
              if (validateNumber(e.target.value)) {
                setNum2(e.target.value);
                setError2(false);
              } else {
                setError2(true);
                setNum2("");
              }
            }}
            value={num2}
            onKeyUp={focusInput.bind(this, "inp2", "inp3")}
            onKeyDown={(e) => deleteInput(e, "inp2", "inp1")}
            id="inp2"
            type="text"
            maxLength="1"
            className={`small-input ${error2 ? "border-red-500" : ""}`}
          />
          <input
            onChange={(e) => {
              if (validateNumber(e.target.value)) {
                setError3(false);
                setNum3(e.target.value);
              } else {
                setError3(true);
                setNum3("");
              }
            }}
            value={num3}
            onKeyUp={focusInput.bind(this, "inp3", "inp4")}
            onKeyDown={(e) => deleteInput(e, "inp3", "inp2")}
            id="inp3"
            type="text"
            maxLength="1"
            className={`small-input ${error3 ? "border-red-500" : ""}`}
          />
          <input
            onChange={(e) => {
              if (validateNumber(e.target.value)) {
                setError4(false);
                setNum4(e.target.value);
              } else {
                setError4(true);
                setNum4("");
              }
            }}
            value={num4}
            onKeyUp={focusInput.bind(this, "inp4", "inp5")}
            onKeyDown={(e) => deleteInput(e, "inp4", "inp3")}
            id="inp4"
            type="text"
            maxLength="1"
            className={`small-input ${error4 ? "border-red-500" : ""}`}
          />

          <input
            onChange={(e) => {
              if (validateNumber(e.target.value)) {
                setError5(false);
                setNum5(e.target.value);
              } else {
                setError5(true);
                setNum5("");
              }
            }}
            onKeyUp={focusInput.bind(this, "inp5", "inp6")}
            onKeyDown={(e) => deleteInput(e, "inp5", "inp4")}
            id="inp5"
            value={num5}
            maxLength="1"
            type="text"
            className={`small-input ${error5 ? "border-red-500" : ""}`}
          />
          <input
            onChange={(e) => {
              if (validateNumber(e.target.value)) {
                setError6(false);
                setNum6(e.target.value);
              } else {
                setError6(true);
                setNum6("");
              }
            }}
            value={num6}
            onKeyUp={focusInput.bind(this, "inp6", "inp6")}
            onKeyDown={(e) => deleteInput(e, "inp6", "inp5")}
            id="inp6"
            maxLength="1"
            type="text"
            className={`small-input ${error6 ? "border-red-500" : ""}`}
          />
        </div>
        {show && (
          <div className="w-full">
            <p className="text-[#FF3436] text-sm text-center font-normal">
              Invalid token inputed, please try again or request another token
            </p>
          </div>
        )}

        <div className="w-full">
          <p className="p">
            the verification code will expire in{" "}
            <span className="text-[#23A455] font-bold">
              0{minute}:{seconds.toString().padStart(2, 0)}
            </span>
          </p>
        </div>
        <div className="action w-[70%] mx-auto">
          <button
            disabled={!valid}
            type="button"
            onClick={showVoice ? submitHandler : resendToken}
            className="btn"
          >
            resend code
          </button>
          <button disabled={!formIsValid} className="button">
            {loading ? "loading..." : "submit"}
          </button>
        </div>
      </form>
      {showVoice && (
        <button onClick={voiceAuth} disabled={!valid} className="btn">
          Resend Code Via Voice
        </button>
      )}
      <div className="w-full mt-6">
        <p className="p text-base">
          already have an account?{" "}
          <Link className="font-bold text-[#23A455]" to="/login">
            sign in
          </Link>
        </p>
      </div>
    </div>
  );
}
