import React, { Fragment, useState } from "react";
import { initializeFundWallet } from "../../util/http";
import Spinner from "../UI/Spinner";
import Notification from "./../UI/Notification";
import FundwalletComponent from "./Fundwallet-component";

import { getToken } from "../../util/util";

export default function FundWalletContainer() {
  const [show1, setShow1] = useState(false);
  const token = getToken("token");
  const [paystack, setPaystack] = useState(false);
  const [squad, setSquad] = useState(false);
  const [flutterwave, setFlutterwave] = useState(false);
  const [service, setService] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    status: "",
    text: "",
  });

  const fundingAmount = localStorage.getItem("fundingAmount");
  const fundAmount = new Intl.NumberFormat().format(fundingAmount);

  async function paymentHandler() {
    if (+fundingAmount <= 0) {
      alert("cannot fund 0 value");
      return;
    }
    const data = {
      amount: fundingAmount,
      service,
      callback_url: "https://dashboard.sosogamescodes.com/success",
    };

    try {
      setLoading(true);
      const response = await initializeFundWallet(token, data);
      localStorage.setItem("transactionID", response.data.reference);

      window.location.href = response.data.authorization_url;
      setLoading(false);

      setMessage({
        status: "success",
        text: "account funding successfully initialized, you will be redirected to payment page now...",
      });
      setShow1(true);
      setTimeout(() => {
        setShow1(false);
      }, 3000);
    } catch (error) {
      // console.log(error);
      setLoading(false);
      setMessage({
        status: "error",
        text: error.response.data.message || "something went wrong",
      });
      setShow1(true);
      setTimeout(() => setShow1(false), 5000);
    }
  }
  return (
    <Fragment>
      <div className="w-[92%] mx-auto mt-6">
        <div className="w-full flex flex-col lg:flex-row  lg:items-center gap-4">
          <FundwalletComponent
            squad={squad}
            setSquad={setSquad}
            setPaystack={setPaystack}
            setService={setService}
            paystack={paystack}
            flutterwave={flutterwave}
            setFlutterwave={setFlutterwave}
          />
          <div className="flex-1 h-[17rem] drop-shadow-xl rounded-lg bg-white flex flex-col gap-8 lg:gap-24 py-8 px-4">
            <div className="w-full flex flex-col gap-4">
              <div className="w-full flex items-center justify-between">
                <p className="text-sm uppercase font-bold text-[#5D5F5F]">
                  SUBTOTAL
                </p>
                <p className="text-sm lg:text-xl font-bold text-black">
                  ₦{fundingAmount ? fundAmount : 0}.00
                </p>
              </div>
              <div className="w-full flex items-center justify-between">
                <p className="text-sm uppercase font-bold text-[#5D5F5F]">
                  TOTAL
                </p>
                <p className="text-sm lg:text-xl font-bold text-black">
                  ₦{fundingAmount ? fundAmount : 0}.00
                </p>
              </div>
            </div>
            <div className="w-full flex items-center justify-center">
              {/* <button
                onClick={paymentHandler}
                className="bg-[#23A455] flex-1 hover:opacity-40 transition-all duration-300  hover:shadow-xl  rounded-lg text-white text-base font-semibold cursor-pointer py-2 lg:py-2 px-3 lg:px-6;"
              >
                {loading ? "Loading..." : "Fund Wallet Now"}
              </button> */}
            </div>
          </div>
        </div>
      </div>

      {show1 && <Notification text={message.text} status={message.status} />}
      {loading && <Spinner />}
    </Fragment>
  );
}
