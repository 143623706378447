import React from "react";

export default function ProductImage({ image }) {
  return (
    <div className="w-full lg:w-[23rem]">
      <img
        className="img rounded-xl h-[25rem] lg:h-[32.5rem]"
        src={image}
        alt="product description"
      />
    </div>
  );
}
