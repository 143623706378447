import React from "react";

export default function Toggle({ text, text1, setEnableApi, isChecked }) {
  return (
    <div className="w-[20%] checkbox flex items-center gap-4">
      <label className="text-sm  lg:block hidden">{text}</label>
      <label className="relative cursor-pointer inline-block w-[40px] h-[20px] lg:w-[50px] lg:h-[34px]">
        <input
          onChange={(e) => setEnableApi(e)}
          type="checkbox"
          checked={isChecked}
          className="opacity-0 w-0 h-0"
        />
        <span className="absolute z-10 rounded-full before:rounded-full slider checked:bg-[#2196F3] top-0 left-0 right-0 bottom-0 bg-[#ccc] transition-all before:absolute before:h-[16px] lg:before:h-[26px] before:w-[16px] lg:before:w-[20px] before:left-[-2px] lg:before:left-[0px] before:bottom-[2px] lg:before:bottom-[4px] before:bg-white before:transition-all before:duration-300 duration-300"></span>
      </label>
      <label className="label lg:block hidden">{text1}</label>
    </div>
  );
}
