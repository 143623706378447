import React, { Fragment, useEffect, useState } from "react";
import { CartContext } from "../../util/context";
import CartList from "./CartList";
import CartStats from "./CartStats";
import CartTitle from "./CartTitle";
import { getProfile } from "../../util/http";
import { getToken } from "../../util/util";

export default function CartContainer({ carts, setShow, setType }) {
  const [showPopup, setShowPopup] = useState(false);
  const [allRecepients, setAllRecepients] = useState([]);
  const [user, setUser] = useState("");

  useEffect(() => {
    async function fetchData() {
      const token = getToken("token");
      try {
        const response = await getProfile(token);
        setUser(response?.data);
      } catch (error) {}
    }
    fetchData();
  }, []);

  return (
    <Fragment>
      <div className="w-[94%] lg:w-[92%] h-full overflow-scroll shadow-lg rounded-xl  mt-0 bg-white mx-auto p-2">
        <div className="w-full p-2 lg:p-4">
          <CartTitle />
          <CartList
            user={user}
            allRecepients={allRecepients}
            setAllRecepients={setAllRecepients}
            setType={setType}
            setShow={setShow}
            carts={carts}
            setShowPopup={setShowPopup}
            showPopup={showPopup}
          />
        </div>
      </div>
      <CartStats allRecepients={allRecepients} setShowPopup={setShowPopup} />
    </Fragment>
  );
}
