import React from "react";

import card from "../../images/for-cards 2.png";

export default function PaymentInformation({ order }) {
  const price = new Intl.NumberFormat().format(order.total_amount);

  return (
    <div className="w-full h-[15rem] bg-white rounded-xl flex lg:space-y-0 space-y-6 flex-col drop-shadow-lg  p-4">
      <h1 className="text-base lg:text-xl font-bold text-start text-[#1D1F1F]">
        Payment Information
      </h1>
      <div className="flex p-3 lg:p-8 items-center justify-between lg:gap-0 gap-4">
        <div className="flex h-[10rem] w-full flex-col space-y-6 lg:space-y-2">
          <p className="text-base lg:text-xl font-bold text start">
            Payment Method
          </p>
          <div className="w-full flex items-center gap-4">
            <div className="w-[28px]">
              <img className="img h-[16px]" alt="card" src={card} />
            </div>
            <p className="text-start font-normal text-sm lg:text-lg">
              Paid with{" "}
              <span className="capitalize">
                {order.payment_channel?.toLowerCase()}
              </span>
            </p>
          </div>
        </div>
        <div className="w-full h-[10rem] flex flex-col space-y-2">
          <p className="text-base lg:text-xl font-bold text start">
            Payment Details
          </p>

          <p className="text-start font-normal capitalize text-sm lg:text-lg">
            Items total: N{price}.00
          </p>
          <p className="text-start font-bold text-sm lg:text-lg">
            Total: N{price}.00
          </p>
        </div>
      </div>
    </div>
  );
}
