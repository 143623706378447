import React, { Fragment, useState } from "react";
import Overlay from "../UI/Overlay";
import Notification from "../UI/Notification";
import { AiOutlineClose } from "react-icons/ai";
import { BsCloudUpload } from "react-icons/bs";
import { updateProfile } from "../../util/http";
import { getToken, setWithExpiry } from "../../util/util";
import { Preview } from "mrcandie-react-preview";

export default function EditProfile({ setShow, user }) {
  const [name, setName] = useState(user.company_name);
  const [address, setAddress] = useState(user.company_address);
  const [email, setEmail] = useState(user.company_email);
  const [logo, setLogo] = useState("");
  const [phone, setPhone] = useState(
    user.company_phone_number ? user.company_phone_number : ""
  );

  const [message, setMessage] = useState({
    status: "",
    text: "",
  });
  const [loading, setLoading] = useState(false);
  const token = getToken("token");
  const [show1, setShow1] = useState(false);
  const [previewImg, setPreviewImg] = useState("");

  const formattedPhone = phone;

  async function submitHandler(e) {
    e.preventDefault();
    const data = {
      company_address: address,
      company_email: email,
      company_name: name,
      company_phone_number: `${phone}`,
      company_logo: logo,
    };

    if (!data) {
      setMessage({
        status: "error",
        text: "input fields cannot be empty",
      });
      setShow1(true);
      setTimeout(() => setShow1(false), 3000);
    }

    try {
      setLoading(true);
      const response = await updateProfile(token, data);
      setWithExpiry("user", response.data, 900000);
      setLoading(false);
      setMessage({
        status: "success",
        text: "Profile update successful",
      });
      setShow1(true);
      setTimeout(() => setShow1(false), 3000);
      setShow(false);
    } catch (error) {
      setLoading(false);
      setMessage({
        status: "error",
        text: error.response.data.message || "something went wrong",
      });
      setShow1(true);
      setTimeout(() => setShow1(false), 5000);
    }
  }

  return (
    <Fragment>
      <Overlay closeModal={() => setShow(false)} />
      <div className="w-full h-[700px]">
        <div className="w-[90%] lg:w-[468px] p-8 absolute  flex flex-col space-y-6 left-4 lg:left-[30%] top-10 bg-white z-[60] rounded-2xl shadow-lg">
          <div className="flex items-center justify-between">
            <div className="flex flex-col space-y-2">
              <h1 className="text-xl font-bold text-black capitalize">
                Company Details
              </h1>
              <p className="text-base capitalize text-[#404040] font-normal">
                edit your company details using the form below
              </p>
            </div>
            <span
              onClick={() => setShow(false)}
              className="text-xl text-black font-bold cursor-pointer"
            >
              <AiOutlineClose />
            </span>
          </div>
          <form
            onSubmit={submitHandler}
            className="w-full flex flex-col space-y-4"
          >
            {false && (
              <div className="input-container w-full">
                <label className="label">upload logo</label>
                <label
                  for="file"
                  className="label cursor-pointer p-4 flex flex-col items-center space-y-2 w-full border border-dotted rounded-lg"
                >
                  <span className="text-2xl">
                    <BsCloudUpload />
                  </span>
                  <p>
                    Drag and Drop or{" "}
                    <span className="text-[#F9AC3B]">Browse</span> to upload
                  </p>
                </label>
                <input
                  onChange={(e) => {
                    setLogo(e.target.files[0]);
                  }}
                  type="file"
                  id="file"
                  className="hidden"
                />
              </div>
            )}
            {previewImg && (
              <>
                <div className="w-full flex items-center justify-start h-[50px] rounded-xl ">
                  <Preview
                    file={logo}
                    outerStyle={{
                      marginLeft: "8px",
                      borderRadius: "8px",
                      border: "1px solid #ccc",
                      width: "60px",
                    }}
                    imgStyle={{
                      borderRadius: "8px",
                      height: "50px",
                      width: "100%",
                    }}
                  />
                </div>
              </>
            )}
            <div className="input-container">
              <label className="label">Company Name</label>
              <input
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                placeholder="Enter company name"
                className="input"
              />
            </div>

            <div className="input-container">
              <label className="label">address</label>
              <input
                onChange={(e) => setAddress(e.target.value)}
                value={address}
                type="text"
                placeholder="Enter company address"
                className="input"
              />
            </div>

            <div className="input-container">
              <label className="label">company email address</label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                placeholder="Enter company email"
                className="input"
              />
            </div>

            <div className="input-container">
              <label className="label">company Phone Number</label>
              <div className="flex items-center justify-center rounded-xl border-2">
                <input
                  onChange={(e) => setPhone(e.target.value)}
                  type="tel"
                  className=" py-2 flex-1 px-4   outline-none"
                  placeholder="XXX XXX XXXX"
                  maxLength={13}
                  value={formattedPhone}
                />
              </div>
            </div>
            <div className="w-full mt-20">
              <div className="action">
                <button
                  type="button"
                  onClick={() => setShow(false)}
                  className="bg-transparent text-[#23A455] font-bold text-sm lg:text-base capitalize py-3 px-6 lg:px-10"
                >
                  cancel
                </button>
                <button className="bg-[#23A455] hover:opacity-40 transition-all duration-300  hover:shadow-xl capitalize rounded-lg text-white text-base font-semibold cursor-pointer py-3 px-6 lg:px-10">
                  {loading ? "loading..." : "Save Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {show1 && <Notification text={message.text} status={message.status} />}
    </Fragment>
  );
}
