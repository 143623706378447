import React from "react";
import Purchases from "./Purchases";
import Transactions from "./Trasactions";

export default function DashboardVisual({ dashboard, chartData }) {
  return (
    <div className="w-full mt-0 p-6 flex flex-col lg:flex-row lg:items-center gap-4 lg:justify-between">
      <Purchases chartData={chartData} />
      <Transactions dashboard={dashboard} />
    </div>
  );
}
