import React, { useState } from "react";

import illustration from "../../images/Illustration.png";
import naira from "../../images/Scan to pay-amico 1.png";
import copy from "../../images/copy.png";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Pagination } from "swiper";

import "swiper/css";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-hot-toast'

export default function MobileWalletBalance({ wallet, setShowModal }) {
  const [copied, setCopied] = useState(false);
  const navigate = useNavigate();

  const detail = wallet?.account;

  const balance = new Intl.NumberFormat().format(wallet.balance);
  return (
    <div className="w-[95%] mx-auto h-full lg:hidden">
      <Swiper
        slidesPerView={1}
        pagination={true}
        modules={[Pagination]}
        spaceBetween={30}
        className="mySwiper w-full"
      >
        <div className="w-full h-full flex items-center justify-center">
          <SwiperSlide className="w-full bg-white h-full shadow-lg rounded-xl">
            <div className="w-full gap-4 flex items-center p-4">
              <div className="w-[120px] bg-[#00AC45] flex items-center justify-center  rounded-3xl bg-opacity-10 h-[135px]">
                <div className="w-[120px]">
                  <img src={illustration} alt="illustration" className="img" />
                </div>
              </div>
              <div className="w-full h-[135px] relative flex flex-col space-y-2">
                <p className="text-[#828282] text-base font-normal capitalize">
                  total wallet balance
                </p>
                <h1 className="balance">₦{balance}.00</h1>
                <div className="w-full absolute bottom-6 left-0 flex item-start justify-start">
                  <button
                    onClick={() => setShowModal(true)}
                    className="bg-[#23A455]  hover:opacity-40 transition-all duration-300  hover:shadow-xl capitalize rounded-lg text-white text-base font-semibold cursor-pointer py-1 px-4"
                  >
                    fund wallet
                  </button>
                </div>
              </div>
            </div>
          </SwiperSlide>

          {detail ? (
            <SwiperSlide className="w-full h-[300px]">
              <div className="flex shadow-lg  p-4 flex-col space-y-4 rounded-xl bg-white">
                <h1 className="text-2xl font-bold text-center text-[#120A00] capitalize">
                  Virtual Account Details
                </h1>
                <div className="w-full flex flex-col space-y-2">
                  <div className="flex items-center justify-between w-full">
                    <p className="dash-t">bank</p>
                    <p className="dash-t flex items-center space-x-4">
                      {detail.bank.name}
                      <CopyToClipboard
                        text={detail.bank.name}
                        onCopy={() => {
                          setCopied(true);
                          setTimeout(() => setCopied(false), 1000);
                        }}
                      >
                        <span className="cursor-pointer w-[15px] ml-2 text-green-600">
                          <img className="img" src={copy} alt="copy" />
                        </span>
                      </CopyToClipboard>
                    </p>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <p className="dash-t">account no</p>
                    <p className="dash-t relative flex items-center space-x-4">
                      {detail.account_number}
                      <CopyToClipboard
                        text={detail.account_number}
                        onCopy={() => {
                          setCopied(true);
                          setTimeout(() => setCopied(false), 1000);
                        }}
                      >
                        <span className="w-[15px]  cursor-pointer ml-2 text-green-600">
                          <img className="img" src={copy} alt="copy" />
                        </span>
                      </CopyToClipboard>
                      {copied && (
                        <p className="absolute top-0 bg-white p-1 left-0">
                          Copied!
                        </p>
                      )}
                    </p>
                  </div>
                  <div className="flex items-center justify-between w-full">
                    <p className="dash-t">account name</p>
                    <p className="dash-t flex items-center space-x-4">
                      {detail.account_name}
                      <CopyToClipboard
                        text={detail.account_name}
                        onCopy={() => {
                          setCopied(true);
                          setTimeout(() => setCopied(false), 1000);
                        }}
                      >
                        <span className="w-[15px] cursor-pointer ml-2 text-green-600">
                          <img className="img" src={copy} alt="copy" />
                        </span>
                      </CopyToClipboard>
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ) : (
            <SwiperSlide className="w-full h-[300px]">
              <div className="flex shadow-lg  p-4 flex-col space-y-2 rounded-xl bg-white">
                <h1 className="text-2xl font-bold text-center text-[#120A00] capitalize">
                  Virtual Account Details
                </h1>
                <p className="text-[#828282] text-center w-[90%] mx-auto leading-6 text-[0.75rem] font-normal">
                  Please complete your onboarding process to generate your
                  virtual account
                </p>
                <div className="w-[70%] mx-auto flex items-center justify-center">
                  <button
                    // style={{
                    //   pointerEvents:'none'
                    // }}
                      onClick={() => navigate("/onboarding")}

                   // onClick={() => toast.error('This service is currently unavailable!')}
                    className="bg-[#23A455]  hover:opacity-40 transition-all duration-300  hover:shadow-xl capitalize rounded-lg text-white text-base font-semibold cursor-pointer py-2 px-4"
                  >
                    get started
                  </button>
                </div>
              </div>
            </SwiperSlide>
          )}
        </div>
      </Swiper>
    </div>
  );
}
