import React from "react";
import PurchaseItem from "./PruchaseItem";

export default function PurchaseList({ data }) {
  return (
    <div className="w-full h-[45vh] overflow-scroll mt-6 flex flex-col space-y-8">
      <div className="flex items-center space-x-4 justify-center">
        <h1 className="order-text w-[20%]">ORDER ID</h1>
        <h1 className="order-text w-[20%]">DATE</h1>
        <h1 className="order-text w-[20%] text-center">AMOUNT</h1>
        <h1 className="order-text w-[20%] text-center">STATUS</h1>
        <h1 className="order-text w-[20%] text-center">ACTION</h1>
      </div>
      <PurchaseItem data={data} />
    </div>
  );
}
