import React from "react";
import { Link } from "react-router-dom";

import empty from "../../images/Illustration.png";
import Empty from "../UI/Empty";
import less from "../../images/less.png";

export default function Transactions({ dashboard }) {
  const walletTransactions = dashboard.wallet_transactions;

  return (
    <div className="flex items-center bg-white rounded-2xl py-2 justify-center w-full lg:w-[41%] h-[400px] drop-shadow-xl">
      <div className="flex w-full flex-col space-y-4">
        <div className="w-full h-[60px] px-6 py-4 flex items-center justify-between">
          <h1 className="text-[1.125rem] mt-[-1rem] lg:text-xl  font-bold capitalize text-[#3F434A] ">
            recent transactions
          </h1>
          {false && (
            <Link
              className="text-base capitalize text-[#627E84] flex items-center space-x-8"
              to="/wallet"
            >
              view all{" "}
              <span className="w-[8px] ml-2 font-bold">
                <img className="img" src={less} alt="" />
              </span>
            </Link>
          )}
        </div>
        {walletTransactions?.length > 0 ? (
          <div className="flex w-full px-2  h-[260px] overflow-scroll flex-col space-y-2">
            {walletTransactions.map((item, i) => (
              <div
                key={i}
                className="w-full px-4 cursor-pointer hover:opacity-80 transition-all duration-300 flex items-center space-x-2"
              >
                <div className="w-[50%] flex items-center justify-between space-x-4">
                  <div className="w-[30px] flex item-center justify-center h-[30px] lg:h-[50px] rounded-full bg-green-400 lg:w-[50px]">
                    <span className="flex items-center justify-center text-center text-base font-bold">
                      {item.service_title[0]}
                    </span>
                  </div>
                  <div className="flex flex-col flex-1">
                    <p className="text-[0.6rem] lg:text-base text-[#3F434A] capitalize font-bold">
                      {item.service_title}
                    </p>
                    <p className="text-sm lg:text-[0.5rem]  text-[#3F434A] capitalize font-normal">
                      {item.date}
                    </p>
                  </div>
                </div>
                <div className="flex-1 flex items-center justify-between">
                  <h1
                    className={`text-sm lg:text-base ${
                      item.type === "credit" ? "text-green-500" : "text-red-500"
                    }  font-semibold capitalize`}
                  >
                    {item.type}
                  </h1>
                  <h1
                    className={`text-xs lg:text-base ${
                      item.type === "credit" ? "text-green-500" : "text-red-500"
                    }  font-bold`}
                  >
                    ₦{new Intl.NumberFormat().format(item.amount)}
                  </h1>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Empty image={empty} text="No purchase to display" />
        )}
      </div>
    </div>
  );
}
