import React, { useEffect, useState } from "react";
import DashboardSide from "./DashboardSide";
import MobileMenu from "./MobileMenu";
import EmptyProduct from "../UI/EmptyProduct";
import success from "../../images/Successful purchase-cuate 1.png";
import { verifyTransaction } from "../../util/http";

import { getToken } from "../../util/util";

export default function FundingSuccess() {
  const [modal, setModal] = useState(false);
  const transactionID = localStorage.getItem("transactionID");
  const token = getToken("token");

  useEffect(() => {
    async function fetchData() {
      try {
        await verifyTransaction(token, transactionID);
        localStorage.removeItem("transactionID");
      } catch (error) {
        // console.log(error);
        localStorage.removeItem("transactionID");
      }
    }
    fetchData();
  }, [token, transactionID]);

  return (
    <div className="h-full bg-[#f6f6f6] flex items-center justify-center">
      <DashboardSide />
      {modal && <MobileMenu setModal={setModal} />}
      <div className="w-full bg-[#f6f6f6] lg:flex-1 h-full lg:h-[160vh] relative">
        <div className="w-full h-[100vh] lg:h-[70%] flex items-center justify-center">
          <EmptyProduct
            image={success}
            text="Successful"
            btnText="go to wallet"
            path="/wallet"
          />
        </div>
      </div>
    </div>
  );
}
