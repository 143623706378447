import React, { useContext, useEffect } from "react";
import Order from "../components/order-history.js/Order";
import { CartContext } from "../util/context";
import { useNavigate } from "react-router-dom";
import { inactiveState } from "../util/inactivity";

export default function OrderPage() {
  const ctx = useContext(CartContext);
  const navigate = useNavigate();

  window.onload = function () {
    inactiveState(ctx, navigate);
  };

  useEffect(() => {
    if (!ctx.isLoggedIn) {
      navigate("/login");
    }
  }, [ctx]);
  return <Order />;
}
