import React, { Fragment, useEffect, useState } from "react";
import DashboardBalance from "./DashboardBalance";
import DashboardPurchases from "./DashboardPurchases";
import DashboardVisual from "./DashboardVisual";
import Header from "./Header";
import { getDashboard, getProfile, getWallet } from "../../util/http";
import EditUser from "../profile/EditUser";
import LoadingBar from "react-top-loading-bar";

import { getToken } from "../../util/util";

export default function DashboardCenter({
  setModal,
  setShow,
  show,
  showEdit,
  setShowEdit,
}) {
  const [progress, setProgress] = useState(0);
  const token = getToken("token");
  const [dashboard, setDashboard] = useState("");

  useEffect(() => {
    async function fetchData() {
      setProgress(20);
      setProgress(40);
      setProgress(60);
      const dashboardData = await getDashboard(token);
      setProgress(80);
      setProgress(100);
      setDashboard(dashboardData.data);
      // setWallet(response);
    }
    fetchData();
  }, []);

  return (
    <Fragment>
      <LoadingBar
        color="#23A455"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      <div className="w-full bg-[#f6f6f6] lg:flex-1 h-full lg:h-[160vh] relative">
        <Header show={show} setShow={setShow} setModal={setModal} />
        <DashboardBalance dashboard={dashboard} />
        <DashboardVisual dashboard={dashboard} chartData={dashboard.charts} />
        <DashboardPurchases data={dashboard.recent_orders} />
      </div>
    </Fragment>
  );
}
