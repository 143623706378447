import React, { Fragment, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Overlay from "./Overlay";

import { AiOutlineLogout } from "react-icons/ai";
import { AiOutlineEdit } from "react-icons/ai";
import { CartContext } from "../../util/context";

export default function ProfilePopup({ setShow1, setShow }) {
  const navigate = useNavigate();
  const auth = useContext(CartContext);

  return (
    <Fragment>
      <Overlay closeModal={() => setShow1(false)} />
      <div className="w-[60%] flex items-center justify-center lg:w-[20%] p-4  bg-white z-[100]  shadow-lg absolute top-[4.5rem] lg:right-2">
        <div className="w-full flex flex-col">
          <div
            onClick={() => {
              setShow(true);
              setShow1(false);
            }}
            className="flex items-center gap-4 p-4 cursor-pointer hover:opacity-80 transition-all duration-300"
          >
            <span className="icon">
              <AiOutlineEdit />
            </span>
            <span className="text-base font-bold capitalize text-black">
              edit profile
            </span>
          </div>
          <div
            onClick={() => {
              auth.logout();
              localStorage.removeItem("token");
              navigate("/login");
            }}
            className="flex items-center gap-4 p-4 cursor-pointer hover:opacity-80 transition-all duration-300"
          >
            <span className="icon">
              <AiOutlineLogout />
            </span>
            <span className="text-base font-bold capitalize text-black">
              logout
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
