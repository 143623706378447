export default function Pagination({ meta, setPage, page }) {
  let pages = [];

  for (let i = 1; i <= Math.ceil(meta?.total / meta?.per_page); i++) {
    pages.push(i);
  }

  return (
    <div className="w-full px-8 py-12 flex items-center justify-between">
      <div className="flex items-center space-x-4">
        {meta?.current_page && (
          <h1 className="hidden lg:block capitalize text-base text-[#57585A]">
            showing {meta ? meta?.current_page : ""} -{" "}
            {meta?.per_page * meta?.current_page} of {meta?.total}
          </h1>
        )}
      </div>
      <div className="flex items-center space-x-4">
        <div className="flex items-center gap-2">
          {pages?.map((item, i) => (
            <button
              key={i}
              onClick={() => {
                setPage(i + 1);
              }}
              className={i + 1 === page ? "page-active" : "page-inactive"}
            >
              {item}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
