import React from "react";
import PurchaseItem from "./PurchaseItem";

export default function PurchaseList({
  setShow,
  order,
  resendCode,
  loading,
  setId,
}) {
  return (
    <div className="w-full h-full overflow-scroll p-1 lg:p-4 flex flex-col space-y-4">
      <div className="flex items-center space-x-4 justify-center">
        <h1 className="order-text w-[10%]">PRODUCT</h1>
        <h1 className="order-text w-[10%] text-center">QUANTITY</h1>

        <h1 className="order-text w-[25%] text-center">CODE</h1>
        <h1 className="hidden lg:block order-text w-[10%] text-center">UNIT</h1>
        <h1 className="order-text w-[10%] text-center">TOTAL</h1>
        <h1 className="w-[30%] order-text lg:w-[20%] text-center">SENT TO</h1>
        <h1 className="order-text w-[15%] text-center">ACTION</h1>
      </div>

      <PurchaseItem
        resendCode={resendCode}
        order={order}
        setShow={setShow}
        setId={setId}
        // item={data}
        loading={loading}
      />
    </div>
  );
}
