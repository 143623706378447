import React, { Fragment, useState } from "react";
import { resendSignupToken } from "../../util/http";
import Overlay from "./Overlay";

export default function ResendCodePopup({
  setLoading,
  setMessage,
  setShow,
  setShow1,
  loading,
  type,
}) {
  const [email, setEmail] = useState("");
  async function resendToken(e) {
    e.preventDefault();
    const data = {
      email: email,
      type: type ? type : "REGISTER",
    };
    try {
      setLoading(true);
      await resendSignupToken(data);
      setLoading(false);
      setMessage({
        status: "success",
        text: "Your verification token has been sent to your email",
      });
      setShow(true);
      setTimeout(() => setShow(false), 5000);
      setShow1(false);
    } catch (error) {
      setLoading(false);
      setMessage({
        status: "error",
        text: error.response.data.message || "something went wrong",
      });
      setShow(true);
      setTimeout(() => setShow(false), 5000);
    }
  }
  return (
    <Fragment>
      <Overlay />
      <div className="w-[90%] flex items-center justify-center lg:w-[40%] p-4 h-[400px] bg-white z-[60] rounded-lg shadow-lg absolute top-[22%] left-5 lg:left-[30%]">
        <div className="p-4 w-full">
          <form
            onSubmit={resendToken}
            className="flex flex-col space-y-4 w-[70%] mx-auto"
          >
            <h1 className="text-xl text-center font-bold text-[#333] capitalize">
              enter your registered email address
            </h1>
            <div className="input-container w-full">
              <label className="label">email address</label>
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                className="input"
                type="email"
                placeholder="Enter your registered email address"
              />
            </div>
            <div className="action">
              <button
                onClick={() => setShow1(false)}
                className="button"
                type="button"
              >
                cancel
              </button>
              <button className="button">
                {loading ? "loading..." : "resend code"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
}
