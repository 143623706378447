import React from "react";
import Details from "./Details";
import User from "./User";

export default function ProfileDetails({ user }) {
  return (
    <div className="w-full lg:w-[94%] mx-auto rounded-lg shadow-sm bg-white p-4 flex lg:flex-row flex-col lg:items-center lg:justify-between">
      <User user={user} />
      <Details user={user} />
    </div>
  );
}
