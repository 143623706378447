import React, { useEffect, useState } from "react";
import Header from "../dashboard/Header";
import ProductDetailHeader from "../ProductDetail/ProductDetailHeader";
import CheckoutContainer from "./CheckoutContainer";
import { getWallet } from "../../util/http";
import { getToken } from "../../util/util";

export default function CheckoutCenter({ setModal }) {
  const token = getToken("token");
  const [balance, setBalance] = useState("");
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getWallet(token);
        setBalance(response.balance);
      } catch (error) {
        // console.log(error);
      }
    }
    fetchData();
  }, [token]);

  return (
    <div className="w-full pb-10 lg:flex-1 h-[100vh] lg:h-[160vh] relative">
      <Header title="checkout" setModal={setModal} />
      <ProductDetailHeader text="checkout" />
      <CheckoutContainer balance={balance} />
    </div>
  );
}
