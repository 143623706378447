import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import naira from "../../images/Scan to pay-amico 1.png";
import illustration from "../../images/Illustration.png";
import CheckoutPopup from "../UI/CheckoutPopup";

export default function TotalOrder({ dashboard, showModal, setShowModal }) {
  const navigate = useNavigate();

  const balance = new Intl.NumberFormat(
    'en-NG' ,
    { style: 'currency', currency: 'NGN' }
  ).format(dashboard?.wallet_balance);
  const totalOrder = new Intl.NumberFormat(
      'en-NG' ,
      { style: 'currency', currency: 'NGN' }

  ).format(dashboard?.total_order);
  

  return (
    <Fragment>
      <div
        onClick={() => navigate("/orders")}
        className="w-[33%] gap-4 flex items-center p-4 h-[168px] drop-shadow-xl rounded-xl bg-white"
      >
        <div className="w-[88px] bg-[#00AC45] flex items-center justify-center p-4 rounded-3xl bg-opacity-10 h-[88px]">
          <div className="w-[70px]">
            <img className="img" alt="naira" src={naira} />
          </div>
        </div>
        <div className="flex flex-col space-y-2 flex-1">
          <h1 className="text-[0.94rem] capitalize font-medium text-[#8A9099]">
            total orders
          </h1>
          <h1 className="balance">
            {dashboard?.total_order ? totalOrder : 0}
          </h1>
        </div>
      </div>

      <div
        onClick={() => navigate("/wallet")}
        className="w-[33%] gap-4 flex items-center p-4 h-[168px] shadow-lg rounded-xl bg-white"
      >
        <div className="w-[88px] bg-[#00AC45] flex items-center justify-center  rounded-3xl bg-opacity-10 h-[88px]">
          <div className="w-[100px]">
            <img src={illustration} alt="illustration" className="img" />
          </div>
        </div>
        <div className="w-full relative flex flex-col space-y-2">
          <p className="text-[#828282] text-[0.94rem] font-normal capitalize">
            total wallet balance
          </p>
          <h1 className="balance">
            {dashboard?.wallet_balance ? balance : 0}
          </h1>
          <div className="w-full absolute bottom-[-55%] right-0 flex items-end justify-end">
            <button
              onClick={() => setShowModal(true)}
              className="bg-[#23A455]  hover:opacity-40 transition-all duration-300  hover:shadow-xl capitalize rounded-lg text-white text-base font-semibold cursor-pointer py-2 px-4"
            >
              fund wallet
            </button>
          </div>
        </div>
      </div>
      {showModal && <CheckoutPopup setShow={setShowModal} />}
    </Fragment>
  );
}
